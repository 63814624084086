const floorplan = {
    floorplan: "Plano de Planta",
    add_floorplan: "Agregar Plano de Planta",
    upload_floorplan: "Subir Plano de Planta",
    remove_floorplan: "Eliminar Plano de Planta",
    search_floorplan: "Buscar Plano de Planta",
    add_door_input: "Agregar puertas / entradas",
    add_selected: "Agregar todos los seleccionados",
    live_view: "Vista en vivo",
    delete_image_msg: "¿Estás seguro de que quieres eliminar la imagen del plano de planta?",
    last_activity: "Última Actividad",
    custom: "Personalizado",
    form: {
      available_allusers: "Disponible para todos los usuarios",
      ask_password_every: "Pedir contraseña cada",
      small_icons: "Iconos pequeños",
      doors: "Puertas",
      inputs: "Entradas",
    },
    modal: {
      title: "Tiempo Personalizado",
      minutes: "Minutos",
      hours: "Horas",
      description_password: "Para continuar, ingrese su contraseña.",
      password: "Contraseña",
      failed_attempts: "Intentions Erronées"
    }
  };
  
  export default floorplan;
  