import { useSelector } from "react-redux";

import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";

// routing
import AppRoutes from "./routes/AppRoutes";

// defaultTheme
import theme from "./themes";
// project imports
import NavigationScroll from "./layout/NavigationScroll";

// Api
import { postOauthToken } from "./api/api";
import { useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import user from "./interfaces/user.interface";
// const stripePromise = loadStripe("pk_test_2zeECGWMDvJcw7USm8CGS9mM00yZxRiVqO");
// console.log(stripePromise)

function App() {
  const stripePromise = useSelector((store: any) => store.stripePromise.stripePromise);
  const customization = useSelector((state: any) => state.customization);
  const user: user = useSelector((store: any) => store.user.user);
    
  const customTheme = theme(customization);

  const getOauthToken = async () => {
    try {
      await postOauthToken();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getOauthToken();
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={user?.darkmode ? customTheme.dark  : customTheme.light}>
          <Elements stripe={stripePromise}>
            <CssBaseline />
            <NavigationScroll>
              <AppRoutes/>
            </NavigationScroll>
          </Elements>
        </ThemeProvider>
      </LocalizationProvider>
    </StyledEngineProvider>
  );
}

export default App;
