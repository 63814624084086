const forgot_password = {
  log_into_account: "Login to your account",
  log_msg: "Enter your username and password",
  username: "Username",
  password: "Password",
  current_password: "Current password",
  new_password: "New password",
  confirm_password: "Confirm password",
  remember_me: "Remember Me",
  login: "Login",
  sign_in: "Sign In",
  click_msg: "By clicking Sign In, you agree to our",
  terms: "Terms and Conditions",
  and: "and",
  privacy: "Privacy Policy",
  account_msg: "Dont have an acount?",
  register_here: "Register here",
  forgot_password: "Forgot password?",
  enter_email: "Enter your email to reset your password",
  email: "Email",
  cancel: "Cancel",
  submit: "Submit",
  to_continue:
    "To con tinue, complete this verification step. We have sent a code to",
  enter_it: "Enter it below",
  hello: "Hello",
  finish_msg: "to finish set a new password",
  error: {
    required: "Required field",
    email_format: "Invalid email format",
    password_match: "Passwords does not match",
  },
};

export default forgot_password;
