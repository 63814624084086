const permissions = {
  ALL_ACCESS: "Tous les droits",
  NO_ACCESS: "Aucun accès",
  ADD_NEW: "Ajouter Nouveau",
  delete: "Supprimer",
  EXECUTE: "Exécuter",
  update_permission: "Mettre à jour les permissions",
  update: "Mettre à jour",
  create_permission: "Créer une permission",
  create: "Créer",
  header: "Liste des permissions",
  add_permission: "Ajouter une permission",
  search_permission: "Rechercher une permission",
  action: "Action",
  enable: "Activer",
  confirm_update_message:
    "Toute modification peut entraîner une interruption du fonctionnement de l'application. Voulez-vous vraiment continuer ?",
  confirm_modal_cancel: "Annuler",
  confirm_modal_confirm: "Mettre à jour",
  more: "Plus...",
  table: {
    name: "NOM",
    description: "DESCRIPTION",
    active: "ACTIF",
  },
  form: {
    description: "Description",
    label: "Étiquette",
    sectag: "Tag",
  },
  VIEW: "Voir",
  View: "Voir",
  ADD: "Ajouter",
  Add: "Ajouter",
  EDIT: "Modifier",
  Edit: "Modifier",
  DELETE: "Supprimer",
  Delete: "Supprimer",
  UPDATE: "Mettre à jour",
  Update: "Mettre à jour",
  SUBSCRIPTION: "Abonnement",
  Subscription: "Abonnement",
  SUBSCRIPTIONMANAGEMENT: "Gestion des abonnements",
  Subscriptionmanagement: "Gestion des abonnements",
  USERMANAGEMENT: "Gestion des utilisateurs",
  Usermanagement: "Gestion des utilisateurs",
  EMPLOYEEMANAGEMENT: "Gestion des employés",
  Employeemanagement: "Gestion des employés",
  LOCKDOWN: "Confinement",
  Lockdown: "Confinement",
  ACTIVATION: "Activation",
  Activation: "Activation",
  REPLACE: "Remplacer",
  Replace: "Remplacer",
  REMOVE: "Supprimer",
  Remove: "Supprimer",
  REFRESH: "Actualiser",
  Refresh: "Actualiser",
  RESTORE: "Restaurer",
  Restore: "Restaurer",
  RESTORETAMPER: "Restaurer l'intégrité",
  Restoretamper: "Restaurer l'intégrité",
  EDITBASIC: "Modifier les informations de base",
  Editbasic: "Modifier les informations de base",
  EDITADVANCED: "Modifier les informations avancées",
  Editadvanced: "Modifier les informations avancées",
  LOCK: "Verrouiller",
  Lock: "Verrouiller",
  ACCESS: "Accès",
  Access: "Accès",
  LOCKDOWNACCESS: "Accès en confinement",
  Lockdownaccess: "Accès en confinement",
  START: "Démarrer",
  Start: "Démarrer",
  STOP: "Arrêter",
  Stop: "Arrêter",
  NOTIFICATION: "Notification",
  Notification: "Notification",
  LOGIN: "Connexion",
  Login: "Connexion",
  CARD: "Carte",
  Card: "Carte",
  PROFILE: "Profil",
  Profile: "Profil",
  MANAGEMENT: "Gestion",
  Management: "Gestion",
  EMPLOYEE: "Employé",
  Employee: "Employé",
  Save: "Enregistrer",
  SAVE: "Enregistrer",
  Manager: "Gérer",
  MANAGER: "Gérer",
};

export default permissions;
