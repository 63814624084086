import React, { useEffect } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface props {
  password: string;
  confirmPassword: string;
  setDisable: (disable: boolean) => void;
}

const hasUpper = /[A-Z]/;
const hasLower = /[a-z]/;
const hasNumber = /\d/;
const hasCharacters = /[-!@#$%^&*()_]/;

const PsswdRequirementsList = ({
  password,
  confirmPassword,
  setDisable,
}: props) => {
  const { t } = useTranslation();

  useEffect(() => {
    setDisable(
      !(
        password.length >= 8 &&
        password.length <= 24 &&
        hasLower.test(password) &&
        hasUpper.test(password) &&
        hasNumber.test(password) &&
        hasCharacters.test(password) &&
        password === confirmPassword
      )
    );
  }, [password, confirmPassword]);

  const returnText = (
    text: string,
    validation: boolean,
    icon: boolean = true
  ) => (
    <Typography
      color={validation ? "green" : ""}
      fontSize="small"
      component="div"
    >
      {icon && (
        <CheckCircleIcon
          sx={{
            fontSize: 14,
            color: validation ? "success" : "gray",
            mb: -0.3,
          }}
        />
      )}{" "}
      {text}
    </Typography>
  );

  return (
    <>
      {returnText(
        `${t("dealers.validate.password_must_contain")}:`,
        false,
        false
      )}
      <Box pl={3} mb={2}>
        {returnText(
          t("dealers.validate.number_of_charachters"),
          password.length >= 8 && password.length <= 24
        )}
        {returnText(t("dealers.validate.lowercase"), hasLower.test(password))}
        {returnText(t("dealers.validate.uppercase"), hasUpper.test(password))}
        {returnText(t("dealers.validate.number"), hasNumber.test(password))}
        {returnText(
          `${t("dealers.validate.special_character")} -!@#$%^&*()_`,
          hasCharacters.test(password)
        )}
        {returnText(
          t("dealers.validate.password_match"),
          password != "" && (password === confirmPassword)
        )}
      </Box>
    </>
  );
};

export default PsswdRequirementsList;
