const cards = {
  cards: "Credentials",
  search_cards: "Search Credential / Display Name ",
  add_card: "Add Credential",
  add_mobile_pass: "Add Mobile Pass",
  update_card: "Update Credential",
  numbers: "Numbers",
  options: "Options",
  general_information: "General Information",
  assign_user: "Assign User",
  assigned_user: "Assigned User",
  assign_msg1: "Do you want to assign the Credential",
  assign_msg2: "to",
  assign_reader1: "Do you want to scan with reader",
  assign_reader2: "from site",
  unassign_user_msg1: "Do you want to unassign",
  unassign_user_msg2: "from the credential",
  delete_card_multiple_sites:
    "NOTE: This credential is associated with more than one site, it will be deleted from all of them.",
  readers: "Readers",
  custom_number: "Custom Number",
  scan_card: "Scan Credential",
  search_devices: "Search Devices",
  scan_status: "Scan Status",
  start_scan_msg:
    "A credential will be created, do you want to proceed with the scanning process?",
  registration_copied: "Registration code copied to clipboard",
  send_to: "Send to",
  send_another: "Send another",
  enrolled: "Enrolled",
  re_enroll: "Re-enroll",
  available: "Available",
  discover: "Discover",
  use_kprog: "Use KPROG",
  kprog: "KPROG",
  table: {
    card: "CREDENTIAL",
    format: "FORMAT",
    name: "NAME",
    state: "STATUS",
    activation_date: "ACTIVATION DATE",
    assigned: "ASSIGNED",
    stolen: "STOLEN",
    lost: "LOST",
    user_name: "NAME",
    department: "DEPARTMENT",
    reader: "READER",
    site: "SITE",
  },
  form: {
    individual: "Individual",
    group: "Group",
    display_name: "Display Name",
    facility_code: "Facility Code",
    card_code: "Credential Code",
    activation_date: "Activation Date",
    expiry_date: "Expiration Date",
    card_format: "Credential Format",
    card_number_hex: "Credential Number Hex",
    all: "All",
    assigned: "Assigned",
    unassigned: "Unassigned",
    used_for_access: "Used for Access",
    stolen: "Stolen",
    lost: "Lost",
    status: "Status",
    active: "Active",
    programming_card: "Programming Credential",
    registration_code: "Registration Code",
  },
  error: {
    invalid_email: "Invalid email format",
    mobilePassLicExced:
      "We're sorry, but there are currently no available licenses for Mobile Pass.",
  },
  pin_option: {
    not_used: "Not used",
    start_lockdown: "Start lockdown",
    stop_lockdLock: "Stop lockdown",
    lockdown_grant: "Lockdown grants access",
    area_secured: "Keep door secured (maintain lockdown)",
  },
};

export default cards;
