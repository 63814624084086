import * as actions from "../actions/sites-lockdown";
import { AnyAction } from "@reduxjs/toolkit";

let initialState = {
  lockdown: [],
};

export const sitesLockdownReducer = (
  state = initialState as { lockdown: any | undefined },
  action: AnyAction
) => {
  switch (action.type) {
    // Set reades list of controller
    case actions.SET_LOCKDOWN:
      return {
        ...state,
        lockdown: [...action.payload],
      };

    // Update reader list"
    case actions.UPDATE_LOCKDOWN:
      const updatedLockdown = state.lockdown.map((reader: any) => {
        return reader.lockdown === action.payload.serial_number
          ? action.payload
          : reader;
      });

      return {
        ...state,
        lockdown: updatedLockdown,
      };

    default:
      return state;
  }
};
