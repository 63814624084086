import { AnyAction } from "@reduxjs/toolkit";
import { SET_USER, REMOVE_USER, CHANGE_PROFILE } from "../actions/user";

// Initial state
const initialState = {
  user: undefined,
};

// Reducer
const UserReducer = (
  state = initialState as { user: any | undefined },
  action: AnyAction
) => {
  switch (action.type) {
    // Set User's data
    case SET_USER:
      const { user } = action;
      return {
        ...state,
        user: {
          ...user,
          currentProfile: user.user_profiles[0].dealer_org_id,
          profile_type: user.user_profiles[0].profile_type,
          selectedDealer:
            user.user_profiles[0].profile_type === "dealer"
              ? {
                  dealer: user.user_profiles[0].dealer_org_id,
                  name: user.user_profiles[0].company_org_name,
                  image: user.selectedDealer?.image,
                }
              : null,
        },
      };

    // Remove User's data
    case REMOVE_USER:
      return initialState;

    case CHANGE_PROFILE:
      const {
        permissions,
        role,
        role_name,
        dealer_org_id,
        profile_type,
        selectedOrg,
        selectedDealer,
      } = action;
      return {
        ...state,
        user: {
          ...state.user,
          permissions,
          role,
          role_name,
          currentProfile: dealer_org_id,
          profile_type: profile_type,
          selectedOrg: selectedOrg,
          selectedDealer: selectedDealer,
        },
      };

    default:
      return state;
  }
};

export default UserReducer;
