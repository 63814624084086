import React from "react";
import { useTranslation } from "react-i18next";

// Interfaces
import EnhancedTableProps from "../interfaces/EnhancedTableHead.interface";

// Material UI
import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
// Components
import Checkbox from "../../Checkbox/Checkbox";
import { grey } from "@mui/material/colors";
import { useTheme } from "@mui/system";

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    actions = false,
    selectable = false,
    hideMainCheck = false,
  } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {selectable && (
          <TableCell width={50} sx={{ minWidth: 50, padding: 0 }}>
            {!hideMainCheck && (
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
              />
            )}
          </TableCell>
        )}

        {headCells.map((headCell, index: number) => (
          <TableCell
            key={String(headCell.id)}
            width={headCell?.width || ""}
            align={"left"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ minWidth: headCell.id === "user_image" ? "25px" : "100px", backgroundColor: theme.palette.table.header }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(String(headCell.id))}
            >
              <strong>{t(headCell.label).toUpperCase()}</strong>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {actions && (
          <TableCell align={"right"} sortDirection={false} sx={{backgroundColor: theme.palette.table.header}}>
            <strong>ACTIONS</strong>
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
