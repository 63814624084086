const events = {
  general: {
    search: "Buscar Evento",
    refresh: "Refrescar",
    add: "Agregar",
    assign: "Asignar",
    details: "Detalles del evento",
    fetchError: "Error al obtener datos",
  },
  selectors: {
    selector1: {
      any: "Cualquiera",
      today: "Hoy",
      yesterday: "Ayer",
      last7Days: "Últimos 7 días",
      custom: "Personalizado",
      month: 'Ultimo mes',
      last60: 'Ultimos 60 dias',
      last180: 'Ultimos 180 dias',
    },
    selector2: {
      all: "Todo",
      access: "Acceso",
      security: "Seguridad",
      alarms: "Alarmas",
      troubles: "Problemas",
      lockdown: "Bloqueo",
      others: "Otros",
      cloud: "Nube",
    },
    selector3: {
      all: "Todo",
      organization: "Cuenta",
      site: "Sitio",
      controller: "Controlador",
      door: "Puerta",
      accessLevel: "Nivel de Acceso",
      card: "Tarjeta",
      user: "Usuario",
      schedule: "Horario",
      holiday: "Vacaciones",
      reader: "Lector",
    },
  },
  table: {
    date: "Fecha",
    description: "Descripción",
    object: "Objeto",
    detail: "Detalle",
    info: "Información",
    extraInfo: "Información extra",
    instigator: "Instigador",
    event: "Evento",
    type: "Tipo",
    id: "ID",
    displayName: "Nombre de visualización",
    module: "Módulo",
    sequenceNumber: "Número de secuencia",
  },
  cards: {
    mobilepass: "Mobile Pass no está soportado aún",
  },
};

export default events;
