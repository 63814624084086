const doors = {
  doors: "Puertas",
  search_door: "Puerta de búsqueda",
  search_event: "Buscar evento",
  configuration: "Configuración",
  events: "Eventos",
  notes: "Notas",
  basic: "Básico",
  advanced: "Avanzado",
  side_b: "Lado B",
  side_a: "Lado A",
  options: "Opciones",
  schedule: "Programa",
  timing: "Temporización",
  user_location: "Ubicación del usuario",
  update_user_location: "Actualizar ubicación de usuario en",
  timing_user_location: "Tiempo de ubicación del usuario activado",
  edit: "Editar",
  edit_basic: "Editar-Básico",
  edit_advanced: "Editar-avanzado",
  door_commands: "Comandos de puerta",
  legend_title: "Leyenda",
  restore_tamper_alarm: "Restaurar alarma de manipulación",
  no_tampered_msg: "La puerta no está manipulada.",
  lockdown_grant: "Acceso de concesión de bloqueo",
  no_lockdown_msg: "La puerta no está bloqueada.",
  total_active_doors: "Total de puertas activas",
  form: {
    serial: "De serie",
    door: "Puerta",
    active: "Activo",
    state: "Expresar",
    lock_status: "Estado de bloqueo",
    access_status: "Estado de acceso",
    grant_access: "Autorizará el acceso",
    disable_access: "Deshabilitar acceso",
    type_here: "Escriba aquí",
    name: "Nombre",
    controller_name: "Nombre del controlador",
    unlock_schedule: "Programa de desbloqueo",
    unlock_first: "Desbloquear en el primer acceso/primer hombre en entrar",
    relock_door_open: "Rebloquea en puerta abierta",
    relock_door_closed: "Rebloquea en puerta cerrada",
    left_open: "Se puede dejar abierto",
    double_swipe: "Doble golpe",
    access_granted: "Acceso permitido",
    doors_open: "Puertas abren",
    unlock_time: "Tiempo de desbloqueo (segundos)",
    extended_unlock: "Desbloqueo extendido (segundos)",
    open_prealarm: "Abrir prealarmas demasiado largas (segundos)",
    open_alarm: "Abrir alarmas demasiado largas (segundos)",
    rex: "REX",
    reader: "Lector",
    contact_input: "Entrada de contacto",
    lock: "Cerrar",
    cards: "Credenciales",
    pin: "y alfiler",
    keypad_code: "Código de teclado",
    door_open: "E incluso si la puerta está abierta",
    interlock_side: "Enclavamiento en este lado",
    two_man_rule: "Regla de dos hombres (chape)",
    pin_schedule: "Horario de pines",
    rex_schedule: "Horario REX",
    unlock_options: "Opciones de desbloqueo",
  },
  table: {
    serial: "DE SERIE",
    door: "PUERTA",
    active: "ACTIVO",
    state: "EXPRESAR",
    lock_status: "ESTADO DE BLOQUEO",
    access_status: "ESTADO DE ACCESO",
    grant_access: "AUTORIZARÁ EL ACCESO",
    disable_access: "DESHABILITAR ACCESO",
    date_time: "FECHA Y HORA",
    description: "DESCRIPCIÓN",
    object: "OBJETO",
    instigator: "INSTIGADOR",
  },
  legend: {
    closed: "Cerrado",
    pre_alarm: "Prealarma",
    alarm: "Alarma",
    locked: "Bloqueado",
    unlocked: "Desbloqueado",
    unlocked_access: "Desbloqueado para acceso",
    access_disabled: "Acceso deshabilitado",
    trouble: "Problema",
    open: "Abierto",
    alarm_forced: "En alarma forzada",
    locked_operator: "Bloqueado por el operador",
    unlocked_operator: "Desbloqueado por el operador",
    access_enabled: "Acceso habilitado",
    unknow: "Desconocido",
    door_tampered: "En alarma manipulada",
  },
};

export default doors;
