const general = {
  sites: "Sites",
  site: "Site",
  door: "Door",
  add_site: "Add Site",
  edit_site: "Edit Site",
  edit_site_access: "Edit Site Access",
  search_site: "Search Site",
  copy_organization: "Copy Account",
  delete_site_message: "Are you sure you want to delete {{site}}?",
  notifications: "Notifications",
  events: "Events",
  site_info: "Site Information",
  total_sites_assigned: "Total sites assigned",
  form: {
    name: "Name",
    address: "Address",
    country: "Country",
    city: "City",
    state: "State",
    zip_code: "Zipcode",
    timezone: "Timezone",
    contact_to: "Contact to",
    type_email: "Type email",
    send_notification: "Send notification",
    lockdown: "Lockdown (started, stopped)",
    alarm_forced: "Door in alarm forced",
    alarm_tampared: "Door in alarm tampered",
    tamper: "Tamper",
    battery_troubles: "Battery troubles",
    power_troubles: "Power troubles",
  },
  table: {
    name: "NAME",
    address: "ADDRESS",
    country: "COUNTRY",
    city: "CITY",
    state: "STATE",
    zipcode: "ZIPCODE",
    timezone: "TIMEZONE",
  },
  modal: {
    title: "Upgrade Subscription",
    how_many_doors: "How many doors licenses would you want to assign?",
    how_many_doors_add: "How many doors licenses would you like to add?",
    current_subscription: "Current Subscription",
    previous: "Previous",
    checkout: "Checkout",
    change_card: "Change Credential",
    invoice_details: "Invoice details",
    current: "Current",
    next_cycle: "Next Cycle",
    request_processed: "Your request has been processed",
    print_receipt: "Print Receipt",
    download_receipt: "Download Receipt",
    finish: "Finish",
    due_today: "Due Today",
    confirmation_description:
      "The payment cannot be modified after checking this window, are you sure you want to checkout?",
    total_amount: "Total amount",
    remove_confirmation_description:
      "When you click the 'confirm' button, the process of eliminating doors or mobile passes will be done inmediately.",
    remove_description:
      "How many doors or mobile passes would you like to remove?",
    remove: "Remove",
    confirmation: "Confirmation",
    delete_payment_description: "Are you sure to delete this credential",
    default_payment_description:
      "Are you sure you want to set this credential as default",
    door_added: "door has been added",
    available_door_license: "Available door licenses",
    confirm_door_activate:
      "Are you sure you want to assign {{doors}} door licenses?",
    not_enough_doors: "You don't have enough door licenses available",
    click_buy: "Click Buy to add the rest of the doors to your plan",
    buy_door: "Buy {{doors}} door licenses",
    buy_more_licenses: "Buy more licences",
  },
};

export default general;
