import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

// Material UI
import { Box } from "@mui/material";

// Project imports
// import image from '../../assets/images/media/patterns/fondo_CDVI.png';
// import logo from "../../assets/images/media/logos/CDVI_CLOUD_blanco.png";

const ClearLayout = () => {
  return (
    <Box
      padding={4}
      sx={{ display: "flex" }}
      style={{
        minHeight: "100vh",
        // backgroundImage: `url(${image})`,
        backgroundColor: "#eff2f5",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left top",
        width: "100vw",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      {/* <img
        src={logo}
        style={{ position: 'absolute', top: '2em', left: '2em', zIndex: 0 }}
      /> */}
      <Box
        zIndex={5}
        style={{
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default ClearLayout;
