const navigationMenu = {
  PERMISSION_OPTION: "Autorisations",
  MENU_OPTION: "Menu",
  USERS: "Utilisateurs",
  ROLES: "Les rôles",
  TESTINGTOOL_OPTION: "Outil de test",
  USER_MANAGEMENT: "Gestion des utilisateurs",
  SECURITY: "Sécurité",
  TESTINGTOOLA: "Outil de test du contrôleur",
  Dealer: "Marchand",
  DEALER: "Marchand",
  "Dealer Request": "Demande de concessionnaire",
  DEALER_OPTION: "Marchand",
  Trial: "Essai",
  Organization: "Compte",
  ORGANIZATION_OPTION: "Comptes",
  SITE_OPTION: "Placers",
  SITE_INFO: "Informations Placer",
  DOOR_OPTION: "Porte",
  USERORG_OPTION: "Utilisatrices",
  HOLIDAY_OPTION: "Vacances",
  SCHEDULE_OPTION: "Programme",
  ACCESSLEVEL_OPTION: "Niveau d'accès",
  GLOBALSETTINGS_OPTION: "Paramètres globaux",
  HOME: "Accueil",
  HOME_OPTION: "Accueil",
  CARD_OPTION: "Certificat",
  CONTROLLER_ADM: "Contrôleur",
  EVENT_OPTION: "Événements",
  ACCOUNT_INFO: "Info sur le compte",
  ORG_INFO_PROFILE: "Informations sur le compte",
  TASK_OPTION: "Tâches",
  DEALER_INFO: "Informations Revendeur",
  RESOURCES: "Ressources",
  DEALER_REQUESTS: "Demandes Concessionnaires",
  LOCKDOWN_OPTION: "Confinement",
  FLOOR_PLAN_OPTION: "Plan d'étage",
  OPTION_SET_PRICE: "Tarification",
  HARDWARE: "Matériel",
  SETTINGS: "Paramètres",
  Reports: "Rapports",
  Input: "Entrée",
  Output: "Sortie",
  firmware_manager: "Gestionnaire de micrologiciel",
  ORGUSERS_REPORT: "Rapport des utilisateurs",
  ORGCARDS_REPORT: "Rapport de certificates",
  Events: "Rapport d'événements",
  "CDVI TECH": "CDVI TECHNOLOGIE",
  "CDVI ADMIN": "CDVI ADMINISTRATION",
  NOTIFICATIONS: "Notifications",
  config: {
    open_settings: "Ouvrir les paramètres",
    profile: "Profil",
    dealer_info: "Informations sur le concessionnaire",
    logout: "Se déconnecter",
    change_password: "Changer le mot de passe",
    english: "English",
    spanish: "Español",
    french: "Français",
    switch_profile: "Changer de profil",
    language: "Langue",
  },
  switch: {
    welcome: "Bienvenue, {{name}}",
    please_select: "S'il vous plaît, sélectionnez",
    name: "Nom",
    last_login: "Dernière connexion",
    actions: "Actes",
    login: "S'identifier",
    active_profile: "Profil actif",
    as_dealer: "Comme marchand",
    as_organization: "Comme organisation",
    as_system: "Comme système",
  },
};

export default navigationMenu;
