const general = {
  new: "New",
  active: "Active",
  inactive: "Inactive",
  yes: "Yes",
  no: "No",
  day: "Day",
  days: "Days",
  hour: "Hour",
  minute: "Minute",
  second: "Second",
  the: "The",
  yearly: "Yearly",
  on: "On",
  and: "And",
  welcome: "Welcome",
  online: "Online",
  offline: "Offline",
  all_sites: "All sites",
  refresh: "Resfresh",
  success: "Success",
  error: "Error",
  time_out: "Time Out",
  kprog_error: "Kprog Error",
  delete_message: "Are you sure you want to delete",
  replacing: "Replacing",
  rebooting: "Rebooting",
  from: "From",
  to: "To",
  general: "General",
  confirmation: "Confirmation",
  filters: "Filters",
  results: "Results",
  loading: "Loading",
  features: "Features",
  coming_soon: "Coming soon",
  reason: "Reason",
  alerts: "Alerts",
  multisite: "Multi-Site",
  comments: "Comments",
  leave_message: "Are you sure you want to leave? Any unsaved changes will be lost.",
  date_time: "Date - Time",
  quantity: "Quantity",
  status: "Status",
  more_filters: "More filters",
  btn: {
    launch: "Launch",
    discontinue: "Discontinue",
    continue: "Continue",
    back: "Back",
    open: "Open",
    new: "New",
    select: "Select",
    selected: "Selected",
    view: "View",
    delete: "Delete",
    update: "Update",
    edit: "Edit",
    submit: "Submit",
    confirm: "Confirm",
    next: "Next",
    previous: "Previous",
    save: "Save",
    checkout: "Checkout",
    activate: "Activate",
    activated: "Activated",
    inactivate: "Deactivate",
    inactivated: "Deactivated",
    finish: "Finish",
    add_card: "Add credential",
    cancel: "Cancel",
    close: "Close",
    dismiss: "Dismiss",
    add_user: "Add user",
    edit_user: "Edit user",
    enable: "Enable",
    disable: "Disable",
    enable_access: "Enable access",
    disable_access: "Disable access",
    done: "Done",
    replace: "Replace",
    remove: "Remove",
    reset: "Reset to Normal State",
    firmware: "Firmware",
    lock: "Lock (override)",
    unlock: "Unlock (override)",
    grant_access: "Grant access",
    grant_extend: "Extended Access",
    actions: "Actions",
    enabled: "Enabled",
    disabled: "Disabled",
    access: "Access",
    unassigned: "Unassigned",
    assigned: "Assigned",
    register: "Register",
    change: "Change",
    refresh: "Refresh",
    restore: "Restore",
    resend_mail: "Resend email",
    send: "Send",
    skip: "Skip",
    test: "Test",
    list_view: "List View",
    chart_view: "Chart View",
    default: "Default",
    sign_in: "Sign In",
    go_back: "Go back",
    now: "Now",
    later: "Later",
    resend: "Resend",
    add: "Add",
    reset_position: "Reset position",
    ok: "OK",
    clear: "Clear",
    apply: "Apply",
  },
  form: {
    timezone: "Timezone",
  },
  month: {
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
  },
  day_name: {
    sunday: "Sunday",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
  },
  cardinal: {
    first: "First",
    second: "Second",
    third: "Third",
    fourth: "Fourth",
    last: "Last",
  },
};

export default general;
