const actions = [1, 2, 3, 4, 5, 6, 7, 8];

/**
 * Returns an array of the actions of a permission
 */
const actionArray = (permission: any[]) => {
  const action: any = [];
  permission.forEach((p) => {
    for (const i of actions) {
      action.push(p[`action${i}`]);
    }
  });
  return action;
};

export default actionArray;
