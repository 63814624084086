const permissions = {
  dealer: {
    permission: "a6e64692-1830-11ed-98fc-abd95cbf91aa",
    name: "dealer",
    description: "Dealer management",
  },
  user: {
    permission: "17ce9da4-1cb3-11ed-98fe-9f53de779468",
    name: "user",
    description: "User management",
  },
  role: {
    permission: "17ce9da5-1cb3-11ed-98fe-eb144f7b9882",
    name: "role",
    description: "Role management",
  },
  menu: {
    permission: "17ce9da6-1cb3-11ed-98fe-b7073fc9fac6",
    name: "menu",
    description: "Menu management",
  },
  permission: {
    permission: "dd2a2c46-1d92-11ed-98fe-335c140aa355",
    name: "permission",
    description: "Permission to access permission actions",
  },
  testingtool: {
    permission: "3352ea4a-23b5-11ed-98fe-afe4de1bee9c",
    name: "testingtool",
    description: "Tetsing tool for cdvi (API)",
  },
  organization: {
    permission: "3352ea4a-23b5-11ed-98fe-afe4de1bee9c",
    name: "organization",
    description: "Organization cdvi",
  },
};

export default permissions;
