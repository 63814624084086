const interlocks = {
  interlocks: "Interlocks",
  search: "Search Interlocks",
  add_interlocks: "Add Interlocks",
  label: "Label",
  schedule: "Schedule",
  name: "Name",
  edit: "Edit",
  delete: "Delete",
  add_group: "Add Interlock group",
  doors: "Doors",
  cancel: "Cancel",
  save: "Save",
  add_door: "Add Doors",
  controller: "Controller",
  side: "Side",
  group: "Interlock Group",
  actions: "Actions",
  door_name: "Door name",
  search_door_by_name: "Search door by name",
  side_a: "Side A",
  side_b: "Side B",
  add: "Add",
  add_selected: "Add Selected",
  selected: "Selected",
  remove: "Remove",
  edit_group: "Edit Interlock group",
  delete_interlock_confirmation: "Are you sure you want to remove this Interlock Group?",
}

export default interlocks;
