import HeadCell from "../../../../../components/MaterialTable/interfaces/HeadCell.interface";
import { dealerUsers } from "../../../interfaces/dealerUsers.interface";

const sitesHeadCell: HeadCell<any>[] = [
  {
    id: "name",
    type: "any",
    label: "sites.sites",
  },
];

export default sitesHeadCell;
