const firmware = {
  header: "Firmware",
  add_firmware: "Agregar firmware",
  edit_firmware: "Editar firmware",
  product_code: "Código de producto",
  version: "Versión",
  mandatory: "Obligatorio",
  launch: "Lanzamiento",
  launched: "Lanzado",
  not_launched: "No lanzado",
  notes: "Notas de lanzamiento",
  add_success_msg: "Firmware agregado exitosamente",
  update_success_msg: "Firmware actualizado exitosamente",
  delete_msg:
    "¿Estás seguro de que deseas eliminar el firmware seleccionado? {{firmware}}",
  firmware_deleted: "Firmware eliminado",
  update_all: "Actualizar todos los controladores",
  update_firmware: "Actualizar Firmware",
  upload_firmware: "Cargar Firmware",
  install_firmware: "Instalar Firmware",
  select_firmware_msg: "Selecciona el firmware a instalar y presiona Continuar",
  confirm_update_msg:
    "¿Estás seguro de que deseas instalar la versión del firmware {{version}} en el controlador {{controller}}?",
  confirm_update_r_msg:
    "¿Estás seguro de que deseas instalar la versión del firmware {{version}} en el lector {{reader}}?",
  confirm_updateR_msg:
    "¿Estás seguro de que deseas actualizar el firmware del lector {{reader}}",
  firmware_error_title:
    "Error de Firmware",
    table: {
    date: "Fecha",
    controller: "Controlador",
    reader: "Lector",
    release: "Nota de lanzamiento",
    product_code: "Código de producto",
    version: "Versión",
    status: "Estado",
    launched: "Lanzado",
    mandatory: "Obligatorio",
  },
};

export default firmware;
