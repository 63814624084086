const general = {
  controller: "Controlador",
  add_controller: "Agregar controlador",
  edit_controller: "Editar controlador",
  search_controller: "Búsqueda de Controlador",
  search_serial_number: "Buscar numero de serie",
  register_msg:
    "Conecte el primer controlador a la red, complete el número de serie y presione registrarse para continuar",
  serial_number: "Número de serial",
  new_serial_number: "Nuevo Número de serial",
  site: "Sitio",
  register: "Registro",
  setup: "Configuración",
  doors: "Puertas",
  doors_activation: "Activación de Puertas",
  finish: "Finalizar",
  wizard: "Asistente",
  run_wizard: "Ejecutar Asistente",
  reboot: "Reiniciar",
  controller_wizard: "Asistente de sitio",
  access_level: "Nivel de acceso",
  user_cards: "Usuario y Credencial",
  remove_controller_msg: "¿Quieres quitar el controlador?",
  enable_door: "¿Está seguro de que desea Habilitar la puerta {{door}}?",
  disable_door: "¿Está seguro de que desea desactivar la puerta?",
  confirm_selected_controller:
    "¿Está seguro de que desea registrar este controlador {{controller}} en el sitio {{site}}",
  confirm_delete_firmware_available:
    "¿Está seguro de que desea eliminar la versión de firmware {{version}}?",
  choose_controller_msg: "Elija un controlador y haga clic para registrarse",
  processing_controller: "Procesando el nuevo controlador en la red.",
  reboot_message: "Reiniciando controlador. Espere 60 segundos:",
  door_info_message:
    "Recuperando información de la puerta. Espere 60 segundos:",
  no_door_site_msg: "No hay puertas para mostrar",
  no_accesslvl_site_msg: "No hay niveles de acceso para mostrar",
  open_wizard_msg: "¿Desea abrir el asistente del sitio?",
  form: {
    enable_ip: "Habilitar configuración IP",
    dns: "DNS IP",
    use_dhcp: "Usar DHCP",
    subnet_mask: "Máscara de subred",
    ip_address: "Dirección IP",
    webport: "Puerto web",
    username: "Nombre de usuario",
    password: "Contraseña",
    name: "Nombre",
    address: "DIRECCIÓN",
    country: "País",
    city: "Ciudad",
    state: "Estado",
    zip_code: "Código postal",
    timezone: "Zona horaria",
    ip_format_error: "Formato incorrecto (xxx.xxx.xxx.xxx)",
    mac_address: "Dirección MAC",
    gateway: "Puerta",
    serial_number: "Número de serie",
    setup_error:
      "Hubo un error al configurar el controlador, inténtalo de nuevo",
    showTampered: "Mostrar opciones de alerta de sabotaje",
    tabControllerData: "Datos del Controlador",
    tabControllerTamper: "Sabotaje",
  },
  table: {
    name: "NOMBRE",
    address: "DIRECCIÓN",
    country: "PAÍS",
    city: "CIUDAD",
    state: "ESTADO",
    zipcode: "CÓDIGO POSTAL",
    timezone: "ZONA HORARIA",
  },
  register_steps: "Paso {{step}} / 6",
  register_steps_alt: "Paso {{step}} / 7",
  steps: {
    step1: "Controlador de ping",
    step2: "Recuperar código de registro",
    step3: "Asignar al sitio de la nube",
    step4: "Generando credenciales",
    step5: "Probando credenciales",
    step6: "¡Conectado!",
    step7: "Adjuntando al sitio",
  },
  online: "En línea",
  offline: "Desconectado",
  available_doors: "{{doors}} puertas disponibles",
  register_error: "Hubo un error en uno de los pasos, intente nuevamente",
  errors: {
    ping: "Hubo un error al hacer ping al controlador, verifique el número de serie",
    verification_code:
      "Hubo un error al recuperar el código de verificación, verifique la conexión del controlador",
    assign:
      "Hubo un error al asignar el controlador al sitio, intente nuevamente",
    timeout: "Tu sesión ha expirado por inactividad, intente nuevamente"
  },
  ping_successful: "Ping del controlador exitoso",
  ping_unsuccessful: "El ping del controlador no tuvo éxito",
  to_continue: "Para continuar",
  ping_successful_msg:
    "Haga clic en el siguiente enlace para obtener el código de verificación",
  verification_code: "Complete el código de verificación aquí",
  back: "Atrás",
  assign: "Asignar",
  ping_verify: "Ping a {{serial_number}} exitoso, el controlador está activo",
  ping_verify_fail:
    "No se recibió respuesta del controlador {{serial_number}}, el controlador está fuera de línea",
  tamperedTitle: "Controlador Vilentado / Alerta",
  buttonRestore: "Restaurar Alerta",
  tampered: "Violentado",
  alarmed: "En Alarma",
  tamperOptions: "Ajustes de violentacion",
  tamperedAlarm: "Alarma de violentacion",
  tamperedNormal: "Normal",
  tamperedEnableCheck: "Habilitar",
  tamperedAlarmCheck: "Alarma",
  tamperedRestoreSuccess:
    "La alarma del controlador se ha restaurado con exito",
  readers: "Lectores",
  readersTab: {
    editInterfaceMode: "Modo de edicion de interfaz",
    localBus: "Local Bus",
    reader1: "Lector 1",
    reader2: "Lector 2",
    cancel: "Cancelar",
    save: "Guardar",
    EditReaderModal: {
      editReader: "Editar Lector",
      editReaderTamperOptionsSuccess:
        "Opciones de violentacion actualizadas con éxito",
      displayName: "Nombre de lector",
      productCode: "Codigo de producto",
      port: "Puerto",
      serialNumber: "Numero de serie",
      firmware: "Firmware",
    },
  },
  sending: "Enviando",
  receiving: "Recibiendo",
  select_controller_replace: "Selecciona controlador a reemplazar",
  process_irreversible:
    "Este proceso es irreversible <br/> Puede cerrar esta ventana y regresar en cualquier momento.",
  press_done_msg:
    "El Reemplazo ha terminado, presione <strong>Listo</strong> para cerrar la ventana",
  confirm_wizard_closed:
    "¿Está seguro de que desea cerrar el Asistente del Sitio?",
  confirmation_door_msg:
    "¿Estás seguro de que quieres {{dooorAction}} la {{doorStatus}} puerta?",
  confirmation_reboot_msg:
    "¿Estás seguro de que quieres reiniciar el controlador {{controller}}?",
  replace_action: {
    validations: "Validaciones generales",
    gencreds: "Generando credenciales",
    testcreds: "Probando credenciales",
    connected: "Controlador conectado",
    ismain: "El controlador es el controlador principal de este sitio",
    modulerestartbegin: "El controlador se está reiniciando",
    modulerestartend: "El controlador está en línea",
    getrectableinfo: "Recopilación de información de entidades",
    getdoor: "Recopilación de información de la puerta",
    getdoorlock: "Recopilación de información de cerraduras de puertas",
    getinputlogic: "Recopilación de información lógica de entrada",
    getoutput: "Recopilación de información de salida",
    getreader: "Recopilación de información del lector",
    getmodtable: "Recopilación de información de los módulos",
    getipconn: "Recopilación de configuración IP",
    setrtc: "Establecer configuración de zona horaria",
    setsubcontroller: "Configuración de subcontroladores",
    setglobalsite: "Configuración del sitio global",
    setdesfireapp: "Configuración del maestro de ID de la aplicación Desfire",
    setreadermode:
      "Configuración de lectores en modo de programación automática",
    setholiday: "Establecer vacaciones",
    setschedule: "Establecer horarios",
    setaccesslevel: "Establecer niveles de acceso",
    setdoor: "Establecer puertas",
    setfactorydefault:
      "Establecer valores predeterminados de fábrica en el controlador anterior",
    removecontroller: "Eliminar el controlador antiguo del sitio",
    finished: "Reemplazo terminado",
  },
  view_syncing: "Ver proceso de sincronización",
  syncing: "Sincronización",
  synced: "sincronizado",
  enabling: "Habilitación",
  enabled: "activado",
  sync: {
    ctrl: "Configuración del controlador recopilada",
    door: "Puertas reunidas",
    output: "Productos recopilados",
    reader: " Lectores reunidos ",
    input: "Lógica de entrada reunida",
    doorlock: "Cerraduras reunidas",
    holiday: "Vacaciones reunidas",
    schedule: "Horarios reunidos",
    al: "Niveles de Acceso reunidos",
    tamper: " Tampers Cfg reunidos",
    timezone: "Establecer zona horaria",
    global_site: "Establecer sitio global",
    krypto: "Seguridad criptográfica habilitada",
    set_reader: "Configuración de lectores en modo de programación automática",
    lockdown: "Configuración de bloqueo",
    set_holiday: "Establecer vacaciones",
    set_schedule: "Establecer horarios",
    set_al: "Establecer niveles de acceso",
    set_user: "Establecer usuarios",
    set_card: "Establecer credenciales",
    synced: "Sincronizado",
  },
  synced_msg: {
    reader_synced: "Lectores",
    io_synced: "Salidas, entradas",
    al_synced: "Nivel de acceso",
    tz_synced: "Zona horaria",
    krypto: "Seguridad criptográfica",
    user_card_synced: "Usuarios y credenciales",
  },
  atrium_unavailable_msg:
    "No hay ningún controlador conectado en este momento, prueba cuando al menos uno de tus controladores esté en línea.",
  door_activation: "Activación de puerta",
};

export default general;
