import organization from "../i18n/organization/fr";

const constant = {
  // Oauth constants
  UAT_URL: "/app/",
  DEV_URL: "https://cdev.cdvicloud.com/app/",
  PROD_URL: "/app/",
  SOCKET_BASE_URL: "https://cdev.cdvicloud.com/",
  SOCKET_PROD_URL: "/",
  client_id: "qDHUDTygqdxz1V3csT8v0Wv9dkdMN3QA3FvHe1he9Z",
  client_secret: "2TwNsIEECJAybSZxCqOYpFRNNplreDltFrmUq4XQ",
  audience: "https://testcdvi.goutru.com",
  grant_type: "client_credentials",

  // EP constants
  oauthTokenEP: "oauth/token",
  oauthAuthenticateEP: "oauth/authenticate",
  oauthOTPEP: (process: string = "forgot") => `oauth/otp?process=${process}`,
  oauthOTPVerifyEP: "oauth/otp/verify",
  oauthOTPchangePasswordEP: "oauth/otp/changePassword",
  oauthActivateUserEP: "oauth/otp/activate-account",
  userChangeSessionEP: "oauth/change-profile",
  oauthOTPChangeEmailEP: "oauth/otp/changeEmail",
  statesEP: "shared/states",
  languagesEP: "shared/languages",
  zipcodeEP: "shared/validate-zipcode",
  discoveredOptionEP: "shared/optionByDiscovered",
  oauthOTPVerifyLoginEP: "oauth/otp/verifylogin",
  oauthVerifyPassword: "oauth/verify-password",

  // Users EP
  securityUserEP: "user",
  securityUserParamEP: (user: string) => `user/${user}`,
  userProfilesEP: (user: string) => `user/${user}/profiles`,
  userProfileUpdateEP: (user: string, dealerOrg: string) =>
    `user/${user}/profile/${dealerOrg}/role`,
  userDeleteProfileEP: (user: string, dealerOrg: string, profileType: string) =>
    `user/${user}/profile/${dealerOrg}?profileType=${profileType}`,
  userChangeNamesEP: "user/profile/names",
  userDashboardEP: "user/dashboard/summary",
  userDashboardEventsEP: "user/dashboard/events",
  userActivityEP: (user: string, filter: number = 1) =>
    `user/${user}/activity?filter=${filter}`,
  userChangeImageEP: "user/profile/image",
  userChangepsswdEP: `user/change/password`,
  userPreferencesEP: (user: string) => `user/${user}/preferences`,
  userImageEP:(user: string) => `user/image/${user}`,

  // Roles EP
  securityRoleEP: "role",
  securityRoleByIDEP: (role: string) => `role/${role}`,
  securityRoleMenuEP: (role: string) => `role/${role}/menu`,
  roleByPermissionEP: (permission: string) => `role/byPermission/${permission}`,
  roleByMenuEP: (menu: string) => `role/byMenu/${menu}`,
  rolePermissions: (role: string) => `role/${role}/permission`,
  roleListFilter: `role/list/filter`,

  // Menu  EP
  menuEP: "menu",
  menuByIDEP: (menu: string) => `menu/${menu}`,

  // Permissions
  securityPermissionEP: "permission",
  securityPermissionByIDEP: (permission: string) => `permission/${permission}`,

  // Dealers EP
  dealerEP: "dealer",
  dealerNAEP: "dealer/type/na",
  dealerActivateEP: "dealer/action/activate",
  dealerByIdEP: (dealer: string) => `dealer/${dealer}`,
  dealerImageEP: (dealer: string) => `dealer/${dealer}/image`,
  dealerUsersEP: (dealer: string) => `dealer/${dealer}/dealer_user`,
  dealerPutActiveEP: (dealer: string) => `dealer/${dealer}/active`,
  dealerResendInvitationEP: (dealer: string) =>
    `dealer/action/resend-invited-mail/${dealer}`,
  dealerUserResendInvitationEP: (dealer_user: string) =>
    `dealer/action/resend-invited-mail/dealer_user/${dealer_user}`,
  dealerUserDeleteEP: (dealer_user: string) =>
    `dealer/${dealer_user}/delete-user`,
  dealerUserPostEP: (dealer: string) => `dealer/${dealer}/create-dealer-user`,
  dealerUserPutEP: (dealer_user: string) =>
    `dealer/${dealer_user}/update-dealer-user`,
  dealerUserAssignAccountEP: (dealer_user: string) =>
    `dealer/${dealer_user}/assign-accounts`,
  dealerUserUnassignEP: (dealer_user: string, organization: string) =>
    `dealer/${dealer_user}/${organization}/unassign`,
  dealerUserEditSiteEP: (dealer_user: string) =>
    `dealer/${dealer_user}/edit-sites`,
  dealerUserDetailsEP: (dealer_user: string) => `dealer/${dealer_user}/details`,
  dealerRolesListEP: (dealer: string) => `dealer/${dealer}/rol-list`,
  dealerUserPutActiveEP: (dealer: string, dealer_user: string) =>
    `dealer/${dealer}/dealer_user/${dealer_user}/activate`,
  dealerAlarmsEP: (dealer: string) => `dealer/${dealer}/alarms`,

  // Dealer Request
  dealerRequestEP: `dealerrequest`,
  dealerRequestApplyEP: `dealerapply`,
  dealerRequestByIdEP: (dealer: string) => `dealerrequest/${dealer}`,
  dealerRequestApproveEP: (dealer: string) => `dealerrequest/${dealer}/approve`,
  dealerRequestDenyEP: (dealer: string) => `dealerrequest/${dealer}/deny`,
  dealerRequestReviewEP: (dealer: string) => `dealerrequest/${dealer}/review`,
  dealerRequestEmailListEP: `dealerrequest/emaillist`,
  dealerRequestApplyEmailCode: `dealerapply/email_code`,
  dealerRequestGetEmailByCode: (code: string) =>
    `dealerapply/email_code?code=${code}`,

  // Organization
  organizationEP: "organization",
  organizationByDealerEP: (dealer: string) => `organization/${dealer}`,
  organizationUpdateEP: (organization: string) =>
    `organization/${organization}`,
  organizationPutActiveEP: (organization: string) =>
    `organization/${organization}/activate`,
  organizationUserEP: (organization: string) =>
    `/organization/${organization}/users`,
  organizationCounterEP: (organization: string) =>
    `/organization/${organization}/counters`,
  organizationDetailEP: (organization: string) =>
    `/organization/${organization}/details`,
  organizationControllerEP: (organization: string) =>
    `/organization/${organization}/controllers`,
  organizationTransactionEP: (organization: string) =>
    `/organization/${organization}/transactions`,
  organizationRoleEP: (organization: string) =>
    `/organization/${organization}/roles`,
  organizationUserPutEP: (organization: string, user: string) =>
    `/organization/${organization}/users/${user}`,
  organizationSchedulesGetEP: (organization: string) =>
    `/organization/${organization}/schedules`,
  orginfoDealerEP: "orginfo/dealer",
  organizationDeleteUserEP: (organization_user: string) =>
    `/organization/${organization_user}/delete-user`,
  organizationProfileDealerEP: "organization/profile/dealer",
  organizationProfileDealerImageEP: "organization/profile/dealer/image",
  organizationUserResendInvitationEP: (organization_user: string) =>
    `/organization/${organization_user}/resend-invitation`,
  summaryOrganizationEP: (organization: string) =>
    `summary/${organization}/organization`,
  organizationVerifyNameEP: (name: string) =>
    `organization/verify/name-exists?name=${name}`,
  organizationChangeMultisite: (organization: string) =>
    `organization/${organization}/change_mode_site`,
  organizationKeypadCodeEP: (organization: string) =>
    `/organization/${organization}/keypadcode`,
  organizationUserOrgCustomFieldsEP: (organization: string) =>
    `/organization/${organization}/userorgcustomfields`,
  organizationAlarmsEP: (organization: string) => `/organization/${organization}/alarms`,

  // Controller
  controllerByDealerEP: (dealer: string) => `controller/${dealer}`,
  controllerPingEp: `controller/ping`,
  controllerAssignEp: `controller/assign_site`,
  controllerRegisterInfoEp: (controller: string) =>
    `controller/${controller}/register_info`,
  controllerBasicInfo: (controller: string) =>
    `controller/${controller}/basic_data`,
  controllerDoors: (controller: string) => `controller/${controller}/doors`,
  controllerRebootEP: (controller: string) => `controller/${controller}/reboot`,
  controllerByOrganizationListEP: (organization: string) =>
    `/controller/${organization}/list`,
  controllerByOrganizationReaderListEP: (organization: string) =>
    `/controller/${organization}/list_with_readers`,
  removeControllerEP: `controller/unassign_site`,
  controllerRefreshEP: (controller: string) =>
    `controller/${controller}/sync_entities`,
  controllerUsercardsEP: (organization: string) =>
    `/controller/${organization}/users-cards`,
  controllerReplaceEP: `controller/replace`,
  controllerReplaceStatusEP: (organization: string, controller: string) =>
    `controller/${organization}/replace-status/${controller}`,
  constrollerAssignStatusEP: (organization: string, controller: string) =>
    `controller/${organization}/assign-status/${controller}`,
  controllerSyncEntitiesStartEP: (controller: string) => 
    `controller/${controller}/sync_entities_start`,
  controllerDetails : (controller: string) => `controller/${controller}/details`,

  // Doors
  doorEP: (door: string) => `door/${door}`,
  doorNoteEP: (door: string) => `door/${door}/notes`,
  doorEventsEP: (door: string, queryString: string) =>
    `door/${door}/events${queryString ? `?${queryString}` : ""}`,
  controllerDoorLabel: (door: string) => `/door/${door}/label`,
  controllerDoorActive: (door: string, active: boolean) =>
    `/door/${door}/active?active=${active}`,
  controllerDoorOpen: (door: string, open: boolean) =>
    `/door/${door}/open?open=${open}`,
  doorOrganizationEP: (organization: string) =>
    `door/organization/${organization}`,
  doorSetActiveness: (door: string, active: boolean) =>
    `door/${door}/active?active=${active}`,
  doorGrantAccessEP: (
    door: string,
    action:
      | "reset"
      | "lock"
      | "unlock"
      | "grant-access"
      | "grant-extend"
      | "lockdown-grant-access"
  ) => `door/${door}/grant-access?action=${action}`,
  doorEnableEP: (door: string, enable: boolean) =>
    `door/${door}/enable?enable=${enable}`,
  doorRestoreAlarmEP: (door: string) => `door/${door}/reset-alarm`,

  // Events
  getOrganizationEventsEP: (organization: string) =>
    `events/${organization}/organization`,
  eventsTypes: () => `events/types`,
  eventDetailsEP: (event: string) => `events/${event}/details`,

  // Shared EP
  countriesEP: "shared/countries",
  regionsEP: "shared/regions",
  timezonesEP: "shared/timezone",
  subscriptionPlanEP: "shared/suscription_plan",
  KeepChannelEP: "shared/keepchannel",
  emailSupportEP: "shared/sendSupport",

  // Testing tool
  testingtoolPingEp: `testingtool/ping`,
  testingtoolUnassignEp: `testingtool/unassign_site`,
  testingtoolAssingEp: `testingtool/assign_site`,
  testingtoolCmdEp: `testingtool/cmd`,
  testingtoolMessageEp: "testingtool/message",

  // Payment
  addPaymentEP: "payment/dealer/add",
  getPaymentEP: (dealer: string) => `payment/dealer/${dealer}/list`,
  deletePaymentEP: (id: string) => `payment/dealer/delete/${id}`,
  defaultPaymentEP: (id: string) => `payment/dealer/setDefault/${id}`,

  // Sites
  siteEP: "site",
  siteActionEP: (site: string) => `site/${site}`,
  siteEventsEP: (site: string) => `site/${site}/events`,
  siteByOrganizationEP: (organization: string) =>
    `site/organization/${organization}`,
  siteByOrganizationtoAssignEP: (organization: string) =>
    `site/organization/${organization}/toAssign`,
  siteHaveMasterControllerEP: (site: string) =>
    `site/${site}/has-master-controller`,
  siteFindControllerEP: (site: string) => `/site/${site}/findcontrollers`,
  siteAssignReaderEP: (site: string) => `/site/${site}/assign-default-reader`,
  siteUnassignReaderEP: (site: string) =>
    `/site/${site}/unassign-default-reader`,
  siteUpdateNotificationEP: (site: string) =>
    `site/${site}/update-notification`,
  siteDetailsEP: (site: string) => `/site/${site}/details-entities`,
  siteKeypadCodeEP: (site: string) => `/site/${site}/keypadcode`,
  siteUserOrgCustomFieldsEP: (site: string) => `/site/${site}/userorgcustomfields`,
  siteTempKeypadCodeEP: (site: string) => `/site/${site}/tempkeypadcode`,

  // Holidays
  holidayByIdEP: (ID: string) => `holiday/${ID}`,
  holidayInfo: (ID: string) => `holiday/${ID}/info`,
  holidayActivateEP: (holiday: string) => `holiday/${holiday}/active`,
  holidayOrganizationEP: (organization: string) =>
    `/holiday/organization/${organization}`,
  holidayOrganizationEPBySite: (organization: string) =>
    `/holiday/organization/${organization}`,

  // Access level
  accesslevelByIdEP: (ID: string) => `accesslevel/${ID}`,
  accesslevelActivateEP: (accesslevel: string) =>
    `accesslevel/${accesslevel}/active`,
  accesslevelOrganizationEP: (organization: string) =>
    `/accesslevel/organization/${organization}`,
  accesslevelDoorEP: (accesslevel: string) =>
    `/accesslevel/${accesslevel}/door`,

  // Access level manager
  accessLevelManagerListEP: (organization: string, site: string = "", filter: string = "") =>
    `/alevelmanager/${organization}?site=${site}&filter=${filter}`,
  accessLevelManagerUserorgEP: (organization: string, accesslevel: string) =>
    `/alevelmanager/${organization}/${accesslevel}/userorg`,
  accessLevelManagerNoUserorgEP: (organization: string, accesslevel: string) =>
    `/alevelmanager/${organization}/${accesslevel}/nouserorg`,
  accessLevelManagerNoAccessLevelEP: (organization: string, site: string = "", filter: string = "") =>
    `/alevelmanager/${organization}/noaccesslevel${site ? `?site=${site}` : ""}${filter ? `&filter=${filter}` : ""}`,
  accessLevelManagerReplaceEP: (organization: string) =>
    `/alevelmanager/${organization}/replace`,

  // Schedules
  scheduleByIdEP: (scheduleId: string) => `schedule/${scheduleId}`,
  schedulePostEP: (siteId: string) => `schedule/${siteId}`,
  scheduleDeleteEP: (scheduleId: string) => `schedule/${scheduleId}`,
  schedulePutEP: (scheduleId: string) => `schedule/${scheduleId}`,
  scheduleActivateEP: (scheduleId: string) => `schedule/${scheduleId}/active`,

  // Userorg
  userorgInfoEP: (userorg: string) => `userorg/${userorg}/info`,
  userorgImageEP: (userorg: string) => `userorg/${userorg}/image`,
  userorgRemovecodeEP: (userorg: string) => `userorg/${userorg}/remove-code`,
  userorgOrganizationAccessLevel: (organization: string) =>
    `/userorg/organization/${organization}/access-level`,
  userorgOrganizationOrganization: (organization: string) =>
    `/userorg/organization/${organization}`,
  userorgOrganizationLogin: (organization: string) =>
    `/userorg/organization/${organization}/login`,
  userorgOrganizationRoles: (organization: string) =>
    `/userorg/organization/${organization}/roles`,
  userorgOrganizationSites: (organization: string) =>
    `/userorg/organization/${organization}/sites`,
  userorgOrganization: (organization: string) => `/userorg/${organization}`,
  userorgUUID: (userorg: string) => `/userorg/${userorg}/edit`,
  userorgDelete: (userorg: string) => `/userorg/${userorg}`,
  userorgCards: (userorg: string) => `/userorg/${userorg}/cards`,
  userorgResendemail: (userorg: string) =>
    `/userorg/${userorg}/resend-invitation`,
  userorgDeleteloginEP: (userorg: string) => `/userorg/${userorg}/delete-login`,
  userorgActiveEP: (userorg: string) => `/userorg/${userorg}/active`,
  userorgMultisiteEP: (userorg: string) => `/userorg/${userorg}/change_mode`,
  userorgSiteEP: (userorg: string) => `/userorg/${userorg}/site`,
  userorgPutSiteEP: (userorg: string, site: string) =>
    `/userorg/${userorg}/site/${site}`,
  userorgCustomFieldsEP: (userorg: string) => `/userorg/${userorg}/customfield`,
  userorgKeypadCodeEP: (userorg: string) => `/userorg/${userorg}/keypadcode`,

  // Card
  cardById: (card: string) => `/card/${card}`,
  cardDetails: (card: string) => `/card/${card}/details`,
  cardOrganizationEP: (organization: string) => `/card/${organization}`,
  cardFormatsEP: "/card/formats",
  cardAssignEP: (idcard: string) => `card/assign/${idcard}`,
  cardUnassignEP: (card: string) => `/card/unassign/${card}`,
  cardActiveEP: (card: string) => `/card/active/${card}`,
  cardGenerateCodeEP: `card/genereteCode`,
  cardListByUserEP: () => `/card/list/by-user-sites`,
  cardInfoByNumberEP: (card_number: string) => `/card/${card_number}/info`,

  // Tasks
  tasksGetEP: `/task/`,

  // Table
  tableOrganizationEP: `/table/organization/`,
  tableSiteByOrganizationEP: `/table/site/`,

  // Department
  department: "/department",
  departmentOrganizationOrganization: (organization: string) =>
    `/department/organization/${organization}`,

  //Readers
  readerOrganizationEP: (organization: string) => `/reader/${organization}`,
  readerAliveEP: (reader: string) => `/reader/alive/${reader}`,
  readerStartScanEP: `/reader/scan/start`,
  readerCancelScanEP: `/reader/scan/cancel`,
  readerUpdateTamperOptions: (reader: string) => `/reader/${reader}/tamper`,
  readerUpdateData: (reader: string) => `/reader/${reader}`,
  readerRestore: (reader: string) => `/reader/${reader}/tamper/restore`,
  readerDelete: (readerUUid: string) => `/reader/${readerUUid}`,

  // Mobile pass
  mobilepassGenereteCardNumberEP: "/mobilepass/genereteCardNumber",
  mobilepassGenereteCodeSite: (site: string) =>
    `/mobilepass/genereteCode/${site}`,
  mobilepassAliveEP: (card: string) => `/mobilepass/alive/${card}`,
  mobilepassOrganizationEP: (organization: string) =>
    `mobilepass/${organization}`,
  mobilepassEditOrganizationEP: (mobilepass: string) =>
    `mobilepass/edit/${mobilepass}`,
  mobilepassActivedEP: (organization: string) =>
    `mobilepass/${organization}/actived`,
  mobilepassSendEmailEP: `/mobilepass/sendEmail`,
  mobilepassReenrollEP: (card: string) => `mobilepass/reenroll/${card}`,

  // Tamper Controllers
  getConfigTamperController: (controller: string) =>
    `/controller/${controller}/tamper`,
  postRestorerControllerTamperAlarm: (controller: string) =>
    `/controller/${controller}/tamper/restore`,
  getReadersByControllerUUID: (controller: string) =>
    `/controller/${controller}/readers`,
  getControllerSync: (controller: string) =>
    `/controller/${controller}/last_step_sync`,
  verifySyncController: (controller: string) =>
    `/controller/${controller}/verify_sync`,

  //Resources
  resourcesEP: `resources`,
  resourcesByIdEP: (resource: string) => `resources/${resource}`,
  getInterfacersBycontroller: (controller: string) =>
    `/interfacers/${controller}`,

  // Lockdown
  lockdownParamEP: (param: string) => `lockdown/${param}`,
  lockdownStartEP: (lockdown: string) => `lockdown/${lockdown}/start`,
  lockdownStartedEP: (lockdown: string) => `lockdown/${lockdown}/started`,

  //Subscription dealers
  getDealerSubscription: (dealer: string) => `/subscription/dealer/${dealer}`,
  getNewSubscriptionDealerValues: (
    dealer: string,
    doors: number,
    mobilepass: number
  ) =>
    `/subscription/dealer/${dealer}/new-subscription-values-qty?doors=${doors}&mobilepass=${mobilepass}`,
  getDealerInvoices: (dealer: string) =>
    `/subscription/dealer/${dealer}/invoices`,
  updateDealerSubscription: (dealer: string) =>
    `/subscription/dealer/${dealer}/update-subscription`,
  getDealerPaymentInvoices: (dealer: string, payment: string) =>
    `/subscription/dealer/${dealer}/invoices/${payment}`,
  postDealerSUbscription: (dealer: string) =>
    `dealer/${dealer}/add-subcription`,

  //Subscription organization
  getOrganizationSubscription: (organization: string) =>
    `/subscription/${organization}`,
  getNewSubscriptionValues: (
    organization: string,
    doors: number,
    mobilepass: number
  ) =>
    `/subscription/${organization}/new-subscription-values-qty?doors=${doors}&mobilepass=${mobilepass}`,
  updateSubscription: (organization: string) =>
    `/subscription/${organization}/update-subscription`,
  getInvoices: (organization: string) =>
    `/subscription/${organization}/invoices`,
  //pricing
  getPricing: "/setting/price",
  // postPricing: () =>
  // `/setting/price`,

  eventsByEntity: (
    organization: string,
    entity:
      | "User_org"
      | "Holiday"
      | "Card"
      | "Schedule"
      | "Accesslevel"
      | "Input"
      | "Output"
      | "Site"
      | "Floor_plan",
    entityUuid: string,
    description: string,
    site: string,
    from: string,
    to: string
  ) =>
    `/events/entity/${organization}/organization?entity=${entity}&entityuuid=${entityUuid}&description=${description}&from=${from}&to=${to}`,

  getInputsByOrganization: (
    organization: string,
    site: string,
    filter: string
  ) => `/input/${organization}?filter=${filter}&site=${site}`,
  geConnectionMethods: "/input/connection_methods",
  updateInputData: (input: string) => `/input/${input}`,
  inputDetails: (input: string) => `/input/${input}/details`,
  getOutputsByOrganization: (
    organization: string,
    site: string,
    filter: string
  ) => `/output/${organization}?site=${site}&filter=${filter}`,
  updateOutputData: (output: string) => `/output/${output}`,
  outputDetails: (output: string) => `/output/${output}/details`,
  reportUsers: (
    organization: string,
    filter: string,
    site: string,
    accesslevel: string,
    department: string,
    active: boolean | string,
    from: string,
    to: string,
    dateFilter: string
  ) =>
    `/report/${organization}?filter=${filter}&site=${site}&accesslevel=${accesslevel}&department=${department}&active=${active}&from=${from}&to=${to}&date_filter=${dateFilter}`,
  reportUsersFile: (
    organization: string,
    filter: string,
    site: string,
    accesslevel: string,
    department: string,
    active: boolean | string,
    from: string,
    to: string,
    dateFilter: string,
    excel: boolean,
    option_date: string
  ) =>
    `/report/${organization}/export?filter=${filter}&site=${site}&accesslevel=${accesslevel}&department=${department}&active=${active}&from=${from}&to=${to}&date_filter=${dateFilter}&excel=${excel}&option_date=${option_date}`,
  reportCards: (organization: string, queryString: string) =>
    `/report/cards/${organization}?${queryString}`,
  reportCardsFile: (organization: string, queryString: string) =>
    `/report/cards/${organization}/export?${queryString}`,

  // Firmware
  controllerFirmwareInstallEP: (controller: string) =>
    `/controller/${controller}/firmware/install`,
  readerFirmwareInstallEP: (reader: string) =>
    `/reader/${reader}/firmware/install`,
  firmwareInstallEP: `/firmware/install`,
  firmwareMarkErrorEP: `/firmware/mark-error-as-seen`,
  firmwareAbortEP: (controller: string, reader: string) => `/firmware/install?controller=${controller}&reader=${reader}`,
  firmwareRemoveEP: (controller: string, reader: string) => `/firmware/remove-file?controller=${controller}&reader=${reader}`,
  firmwareUploadEP: `/firmware/upload`,
  firmwareEP: `firmware`,
  firmwareFirmwareEP: (firmware: string) => `/firmware/${firmware}`,
  firmwareLaunchEP: (firmware: string) => `/firmware/${firmware}/launch`,
  firmwareDiscontinueEP: (firmware: string) =>
    `/firmware/${firmware}/discontinue`,
  firmwareControllerOrReader: (firmware: string, type: string) => `/firmware/listByControllerOrReader?type=${type}&object=${firmware}`,
  firmwareTransferEP: `/firmware/transfer`,
  firmwareUpdateEP: `/firmware/update`,

  // Report
  reportEvents: (
    organization: string,
    filter: string,
    site: string,
    event_class: string,
    object_type: string,
    object_event: string,
    event_type: string,
    object: string,
    from: string,
    to: string
  ) =>
    `/report/events/${organization}?filter=${filter}&site=${site}&event_class=${event_class}&object_type=${object_type}&object_event=${object_event}&event_type=${event_type}&object=${object}&from=${from}&to=${to}`,

  reportEventsExport: (
    organization: string,
    filter: string,
    site: string,
    event_class: string,
    object_type: string,
    object_event: string,
    event_type: string,
    from: string,
    to: string,
    option_date: string,
    excel: boolean,
    print_all: boolean
  ) =>
    `/report/events/${organization}/export?filter=${filter}&site=${site}&event_class=${event_class}&object_type=${object_type}&object_event=${object_event}&event_type=${event_type}&object=&from=${from}&to=${to}&option_date=${option_date}&excel=${excel}&print_all=${print_all}`,
  reportOrgnizationEP: (organization: string) => `report/${organization}`,
  reportOrgnizationReportEP: (organization: string, report: string) =>
    `report/${organization}/${report}`,
  reportDefinitionEP: (organization: string, report_type: string) =>
    `report/definition/${organization}?report_type=${report_type}`,
  reportObjectsEP: (organization: string, site: string, object_type: string) =>
    `report/objects/${organization}?site=${site}&object_type=${object_type}`,
  // Report
  getUsersByRole: (role: string, filter: string) =>
    `/role/${role}/users?filter=${filter}`,

  //Kprog
  assignKprog: (site: string) => `/site/${site}/assign-kprog`,
  getKprog: (organization: string, serial: string) =>
    `/organization/kprog-code/${organization}/${serial}`,

  //Stripe
  getStripeConfigEP: `/stripe/config`,
  // Floorplan
  floorplanSiteEP: (site: string) => `floorplan/${site}`,
  floorplanDetailsEP: (floor_plan_id: string) =>
    `floorplan/${floor_plan_id}/details`,
  putFloorplanSiteEP: (site: string, floor_plan_id: string) =>
    `floorplan/${site}/${floor_plan_id}`,
  deleteFloorplanSiteEP: (floor_plan_id: string) =>
    `floorplan/${floor_plan_id}`,
  floorplanImageEP: (floor_plan_id: string) =>
    `floorplan/${floor_plan_id}/image`,
  floorplanSiteAddelementEP: (site: string, floor_plan: string) =>
    `floorplan/${site}/${floor_plan}/add-element`,
  floorplanSiteRemoveelementEP: (site: string, floor_plan: string) =>
    `floorplan/${site}/${floor_plan}/remove-element`,
  floorplanSiteAdduserEP: (site: string, floor_plan: string) =>
    `floorplan/${site}/${floor_plan}/add-user`,
  floorplanSiteRemoveuserEP: (site: string, floor_plan: string, user: string) =>
    `floorplan/${site}/${floor_plan}/remove/${user}`,

  // Interlocks
  getSiteInterlocksEP: (site: string) => `interlock/${site}`,
  getInterlocksAvailableDoorsEP: (site: string, interlock: string) => `door/interlocks/${site}?interlock=${interlock}`,
  postInterlockEP: (site: string) => `interlock/${site}`,
  deleteInterlockEP: (interlock: string) => `interlock/${interlock}`,
  putInterlockEP: (interlock: string) => `interlock/${interlock}`,
  getInterlockEP: (interlock: string) => `interlock/${interlock}/details`,
};

export default constant;
