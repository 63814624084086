import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Card,
  CardContent,
  CardActions,
  FormControlLabel,
  Typography,
  Stack,
  Menu,
  MenuItem,
  Button as MuiButton,
} from "@mui/material";
import Modal from "../../components/Modal/Modal";
// Icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// Store
import { useDispatch } from "../../store";
import { setUser } from "../../store/actions/user";
// Interfaces
import user from "../../interfaces/user.interface";
// Components
import Snack from "../../components/Snack/Snack";
// Assets
import logo from "../../assets/images/media/logos/CDVI_CLOUD4.png";
// Api
import { postOauthAuthenticate, postOtpLogin } from "../../api/api";
import { blue, grey } from "@mui/material/colors";
import Button from "../../components/Button/Button";
import FormField from "../../components/FormField/FormField";
import lngs from "../../layout/lngs";
import Checkbox from "../../components/Checkbox/Checkbox";
import { useNavigate } from "react-router-dom";
import SupportWidget from "../../components/SupportWidget/SupportWidget";
import LoginOTP from "./LoginOTP/LoginOTP";

const LoginScreen = () => {
  const dispatch = useDispatch();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabledLogin, setDisabledLogin] = useState(true);
  const [disabledOTP, setDisabledOTP] = useState(true);

  const [errorAlert, setErrorAlert] = useState({
    open: false,
    message: "",
  });
  const [otpMode, setOtpMode] = useState(false);
  const [otp, setOtp] = useState("");

  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const regex = /^[0-9]*$/;

  const handleOpenUserMenu = (event: any): any => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  // useEffect for auto selecting remember me checkbox
  useEffect(() => {
    const rememberMeUser = JSON.parse(
      localStorage.getItem("REMEMBER_ME_USER") || "{}"
    );
    if (Object.keys(rememberMeUser).length) {
      setUsername(rememberMeUser.username);
      setPassword(atob(rememberMeUser.password));
      setRememberMe(true);
    }
  }, []);

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === "Enter") {
        event.preventDefault();

        if (!loading && !disabledLogin) handleLogin();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  });

  // useEffect for disabling Login button
  useEffect(() => {
    setDisabledLogin(!Boolean(username) || !Boolean(password));
  }, [username, password]);

    // useEffect for disabling OTP button
    useEffect(() => {
      if (otp.length === 6)
        setDisabledOTP(false);
      else setDisabledOTP(true);
    }, [otp]);

    
  const handleLogin = async () => {
    setLoading(true);
    try {
      const resp: {
        status: number;
        token: string;
        data: any;
        message?: string;
        headers: any;
      } = await postOauthAuthenticate(username, password);
        if (resp.status === 403) {
          setLoading(false)
          return setOtpMode(true)
        }

        if (resp.status === 200) {
        // Remember me
        if (rememberMe) {
          localStorage.setItem(
            "REMEMBER_ME_USER",
            JSON.stringify({ username: username, password: btoa(password) })
          );
        } else {
          localStorage.removeItem("REMEMBER_ME_USER");
        }

        // New user
        const newUser: user = { ...resp.data };
        dispatch(setUser(newUser));
        setLoading(false);
      } else {
        setErrorAlert({
          open: true,
          message: resp.message || "Could not authenticate",
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      setErrorAlert({ open: true, message: "Could not authenticate" });
    }
  };

  const handleVerifyOtp = async () => {
    setLoading(true)
    try {
      const resp = await postOtpLogin(username, otp)
      if (resp.status === 200) {
      // New user
      const newUser: user = { ...resp.data };
      dispatch(setUser(newUser));
      setLoading(false);

      } else {
      setErrorAlert({
        open: true,
        message: resp.message || "Could not authenticate",
      });
      }
      setOtpMode(false)
      setLoading(false);
    } catch (error:any) {
      console.log(error)
      setErrorAlert({
        open: true,
        message: error.response.data?.message || error.message,
      });
      setOtp("")
      setLoading(false);
    }
  }

  const getTypography = (text: string, mt?: number, mb?: number) => {
    return (
      <Typography
        color={grey[400]}
        textAlign="center"
        mt={mt ? mt : 0}
        mb={mb ? mb : 5}
      >
        {text}
      </Typography>
    );
  };

  const anchor = (to: string, text: string) => {
    return (
      <a
        style={{
          color: blue[900],
          textDecoration: "underline",
          cursor: "pointer",
        }}
        onClick={(e) => navigate(to)}
      >
        {text}
      </a>
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Card
          variant="outlined"
          sx={{
            background: "#eff2f5",
            width: { xs: "100%", md: "640px" },
            // borderRadius: "0.75em",
            margin: "0.25em",
            border: "none",
          }}
        >
          <Stack direction="row" justifyContent="center">
            <img src={logo} width={200} alt="CDVI Logo" style={{ marginLeft: -90 }}/>
          </Stack>
          {/* Login title */}
          { !otpMode ? (
          <>
            <Typography
              component="h3"
              variant="h3"
              fontWeight={1000}
              fontSize={32}
              color={blue[900]}
              textAlign="center"
              mb={2}
            >
              Cloud-Based Access Control System
            </Typography>
            {/* {getTypography(t("login.log_msg"), 2, 5)} */}
            {/* Username input */}

            <Stack direction="row" justifyContent="center">
              <Box sx={{ width: { xs: "100%", md: "450px" } }}>
                <FormField
                  bigForm
                  placeholder={t("login.username")}
                  sx={{backgroundColor: "#E3E6E9"}}
                  value={username}
                  marginBottom={4}
                  onChange={(event: any) => setUsername(event.target.value)}
                  id="login-screen-username"
                />
                <FormField
                  bigForm
                  type="password"
                  placeholder={t("login.password")}
                  sx={{backgroundColor: "#E3E6E9"}}
                  value={password}
                  marginBottom={2}
                  onChange={(event: any) => setPassword(event.target.value)}
                  id="login-screen-password"
                />
                {/* Remember me checkbox */}
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                >
                  <FormControlLabel
                    disabled={loading}
                    control={
                      <Checkbox
                        checked={rememberMe}
                        onChange={() => setRememberMe(!rememberMe)}
                      />
                    }
                    label={t("login.remember_me")}
                  />
                  {anchor("/forgot_password", t("login.forgot_password"))}
                </Stack>
              </Box>
            </Stack>
            <Stack width="100%" direction="row" justifyContent="center">
              <Box sx={{ width: { xs: "100%", md: "450px" } }}>
                {/* Login Button */}
                <Button
                  bigBtn
                  width="100%"
                  color="darkBlue"
                  disabled={loading || disabledLogin}
                  loading={loading}
                  onClick={handleLogin}
                  id="login-screen-signin"
                >
                  {t("login.sign_in")}
                </Button>
              </Box>
            </Stack>
            <Typography
              color={grey[400]}
              textAlign="center"
              mt={2}
              mb={3}
              paddingLeft={6}
              paddingRight={6}
            >
              {t("login.account_msg")}{" "}
              {anchor("/preregistration", t("login.register_here"))}
            </Typography>
            <Typography
              color={grey[400]}
              textAlign="center"
              mt={2}
              mb={3}
              paddingLeft={6}
              paddingRight={6}
            >
              {t("login.click_msg")} {anchor("", t("login.terms"))}{" "}
              {t("login.and")} {anchor("", t("login.privacy"))}
            </Typography>
            <Stack justifyContent="center" alignItems="center" marginBottom={5}>
              <MuiButton
                onClick={handleOpenUserMenu}
                style={{ color: grey[400], background: "transparent" }}
              >
                <img
                  width={25}
                  style={{ borderRadius: 1000, marginRight: 5 }}
                  src={lngs[i18n.language.substring(0, 2)].flag}
                />
                {"     "}
                {t(lngs[i18n.language.substring(0, 2)].nativeName)}
                <KeyboardArrowDownIcon />
              </MuiButton>
            </Stack>

          <Menu
            sx={{ mt: "0px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {Object.keys(lngs).map((lng) => (
              <MenuItem
                key={lng}
                onClick={() => {
                  i18n.changeLanguage(lng);
                  handleCloseUserMenu();
                }}
              >
                <Stack direction={"row"} gap={1}>
                  <img width={15} src={lngs[lng].flag} />
                  <Typography textAlign="center">
                    {t(lngs[lng].nativeName)}
                  </Typography>
                </Stack>
              </MenuItem>
            ))}
          </Menu>
        </>
        ) : (
          <LoginOTP otp={otp} setOtp={setOtp} handleLogin={handleLogin} handleVerifyOtp={handleVerifyOtp}/>
        )}
        </Card>
      </Box>
      {/* Error Snack */}
      <Snack
        open={errorAlert.open}
        onClose={() => setErrorAlert({ ...errorAlert, open: false })}
        message={errorAlert.message}
        severity="error"
      />
      <SupportWidget/>
    </>
  );
};

export default LoginScreen;
