const outputs = {
    table: {
        displayName: 'Display name',
        serialNumber: 'Serial number',
        normallyOpen: 'Normally open',
        enabled: 'Enabled',
        searchPlaceHolder: 'Display name / serial number',
    },
    modal: {
        title: 'Output properties',
        tabInformation: 'General Information',
        tabEvents: 'Events',
    },
    form: {
        options: 'Options',
        checkEnable: 'Enable',
        radioNormallyOpen: 'Normally open',
        radioNormallyClose: 'Normally close',
        inputResponseTime: 'Input response time (ms)',
        inputRestoreTime: 'Input restore time (ms)'
    },
};

export default outputs;
