import React from "react";
// Imports
import { Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

interface props {
  info: string;
  sx?: any;
}

const InfoIconLabel = ({ info, sx }: props) => {
  return (
    <Tooltip title={info} placement="top">
      <InfoIcon
        sx={{ fontSize: 16, marginLeft: 0.5, marginBottom: -0.4, ...sx }}
      />
    </Tooltip>
  );
};

export default InfoIconLabel;
