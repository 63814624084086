import { Box, CircularProgress } from "@mui/material";
import logo_cdvi from '../../assets/images/media/logos/CDVI_CLOUD4.png'

const SplashScreen = () => {
  return (
    <Box sx={{height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '1em'}}>
      <img src={logo_cdvi} alt="" />
      <CircularProgress size={75} />
    </Box>
  )
}

export default SplashScreen