import { ReactNode } from "react";
// MUI
import { Button as MaterialUIButton, CircularProgress } from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import { useTheme } from "@mui/system";

interface ButtonProps {
  children: ReactNode;
  onClick?: (e?: any) => void;
  color?: "primary" | "green" | "gray" | "red" | "darkBlue" | "transparent";
  filled?: boolean;
  width?: string | null;
  loading?: boolean;
  disabled?: boolean;
  bigBtn?: boolean;
  paddingLeft?: string;
  paddingRight?: string;
  sx?: any;
  type?: "button" | "reset" | "submit";
  id?: string;
}

const Button = ({
  children,
  onClick,
  color = "primary",
  filled = false,
  width = null,
  loading,
  disabled = false,
  bigBtn = false,
  paddingLeft = "2em",
  paddingRight = "2em",
  sx,
  type,
  id,
}: ButtonProps) => {
  const theme = useTheme();

  const colors = {
    primary: {
      light: theme.palette.button.background,
      dark: "#e3f2fd",
      hover: {
        main: "#2196f3BE",
        secondary: "#e3f2fdBE",
      },
    },
    green: {
      light: "#54AD32",
      dark: "#e3f2fd",
      hover: {
        main: "#54AD32",
        secondary: "#e3f2fdBE",
      },
    },
    gray: {
      light: "#eeeeee",
      dark: "#9e9e9e",
      hover: {
        main: "#eeeeeeBE",
        secondary: "#9e9e9eBE",
      },
    },
    red: {
      light: "#f32121",
      dark: "#fde3e3",
      hover: {
        main: "#fde3e3BE",
        secondary: "#f32121BE",
      },
    },
    darkBlue: {
      light: blue[900],
      dark: grey[300],
      hover: {
        main: "#0d47a1BE",
        secondary: grey[300],
      },
    },
    transparent: {
      light: "transparent",
      dark: "transparent",
      hover: {
        main: "#eeeeeeBE",
        secondary: "#e3f2fdBE",
      },
    },
  };

  return (
    <MaterialUIButton
      disabled={disabled}
      disableRipple
      onClick={onClick}
      id={id}
      sx={{
        width: width,
        minHeight: bigBtn ? "4.5em" : "2.5em",
        // borderRadius: bigBtn ? "100em !important" : "0.5em",
        paddingLeft,
        paddingRight,
        backgroundColor: filled ? colors[color].dark : colors[color].light,
        color: filled ? colors[color].light : colors[color].dark,
        ":hover": {
          backgroundColor: filled
            ? colors[color].hover.secondary
            : colors[color].hover.main,
          color: filled
            ? colors[color].hover.main
            : colors[color].hover.secondary,
        },
        "&:disabled": {
          backgroundColor: theme.palette.button.disabled,
          color: "darkgray",
        },
        ...sx,
      }}
      type={type || "button"}
    >
      {loading ? (
        <CircularProgress
          size={25}
          style={{ color: filled ? colors[color].light : colors[color].dark }}
        />
      ) : (
        children
      )}
    </MaterialUIButton>
  );
};

export default Button;
