interface RequiredFieldIndicatorProps {
  color?: string;
  disabled?: boolean;
  disabledColor?: string;
  indicator?: string;
}

const RequiredFieldIndicator = ({
  color = '#f44336',
  disabled = false,
  disabledColor = '#ef9a9a',
  indicator = '*',
}: RequiredFieldIndicatorProps) => {
  return (
    <span
      style={{ color: disabled ? disabledColor : color, marginLeft: '0.2em' }}>
      {indicator}
    </span>
  );
};

export default RequiredFieldIndicator;
