const general = {
  new: "Nuevo",
  active: "Activo",
  inactive: "Inactivo",
  yes: "Si",
  no: "No",
  day: "Día",
  days: "Días",
  hour: "Hora",
  minute: "Minuto",
  second: "Segundo",
  the: "La",
  yearly: "Anual",
  on: "En",
  and: "Y",
  welcome: "Bienvenido",
  online: "En linea",
  offline: "Fuera de linea",
  all_sites: "Todos los sitios",
  refresh: "Resfrescar",
  success: "Éxito",
  error: "Error",
  time_out: "Se acabó el tiempo",
  kprog_error: "Error Kprog",
  delete_message: "Estas seguro que deseas eliminar",
  replacing: "Reemplazando",
  rebooting: "Reiniciando",
  from: "De",
  to: "Para",
  general: "General",
  confirmation: "Confirmación",
  filters: "Filtros",
  results: "Resultados",
  loading: "Cargando",
  features: "Características",
  coming_soon: "Muy pronto",
  reason: "Razón",
  alerts: "Alertas",
  multisite: "Multi-Sitio",
  comments: "Comentarios",
  leave_message: "¿Estás seguro de que quieres salir? Se perderán los cambios no guardados.",
  date_time: "Fecha - Hora",
  quantity: "Cantidad",
  status: "Estado",
  more_filters: "Mas filtros",
  btn: {
    launch: "Lanzamiento",
    discontinue: "Discontinuar",
    continue: "Continuar",
    back: "Retroceder",
    open: "Abrir",
    new: "Nuevo",
    select: "Seleccionar",
    selected: "Seleccionado",
    view: "Vista",
    delete: "Borrar",
    update: "Actualizar",
    edit: "Editar",
    submit: "Enviar",
    confirm: "Confirmar",
    next: "Próximo",
    previous: "Anterior",
    save: "Guardar",
    checkout: "Verificar",
    activate: "Activar",
    activated: "Activado",
    inactivate: "Desactivar",
    inactivated: "Desactivado",
    finish: "Finalizar",
    add_card: "Agregar",
    cancel: "Cancelar",
    close: "Cerrar",
    dismiss: "Descartar",
    add_user: "Agregar",
    edit_user: "Editar",
    enable: "Habilitar",
    disable: "Deshabilitar",
    enable_access: "Habilitar acceso",
    disable_access: "Deshabilitar acceso",
    done: "Hecho",
    replace: "Reemplazar",
    remove: "Remover",
    reset: "Reiniciar al estado normal",
    firmware: "Firmware",
    lock: "Cerrar (anular)",
    unlock: "Desbloquear (anular)",
    grant_access: "Autorizará el acceso",
    grant_extend: "Extender Accesso",
    actions: "Acciones",
    enabled: "Habilitado",
    disabled: "Deshabilitado",
    access: "Acceso",
    unassigned: "No asignado",
    assigned: "Asignado",
    register: "Registrar",
    change: "Cambiar",
    refresh: "Refrescar",
    restore: "Restaurar",
    resend_mail: "Reenviar correo",
    send: "Enviar",
    skip: "Saltar",
    test: "Prueba",
    list_view: "Vista de lista",
    chart_view: "Vista de gráfico",
    default: "Defecto",
    sign_in: "Iniciar Sesión",
    go_back: "Regresar",
    now: "Ahora",
    later: "Más tarde",
    resend: "Reenviar",
    add: "Agregar",
    reset_position: "Restablecer posición",
    ok: "OK",
    clear: "Limpiar",
    apply: "Aplicar",
  },
  form: {
    timezone: "Zona horaria",
  },
  month: {
    january: "Enero",
    february: "Febrero",
    march: "Marzo",
    april: "Abril",
    may: "Mayo",
    june: "Junio",
    july: "Julio",
    august: "Agosto",
    september: "Septiembre",
    october: "Octubre",
    november: "Noviembre",
    december: "Diciembre",
  },
  day_name: {
    sunday: "Domingo",
    monday: "Lunes",
    tuesday: "Martes",
    wednesday: "Miércoles",
    thursday: "Jueves",
    friday: "Viernes",
    saturday: "Sábado"
  },
  cardinal: {
    first: "Primero",
    second: "Segundo",
    third: "Tercero",
    fourth: "Cuarto",
    last: "Último"
  }
};

export default general;
