import {
  Checkbox as MaterialUICheckbox,
  SvgIcon,
  SvgIconProps,
  SxProps,
  Theme,
} from "@mui/material";
import { blue, grey } from "@mui/material/colors";

interface CheckboxProps {
  checked?: boolean;
  disabled?: boolean;
  onClick?: (event: any) => void;
  onChange?: (event: any) => void;
  indeterminate?: boolean;
  label?: string;
  sx?: SxProps<Theme> | undefined;
  id?: string;
}

const Checkbox = ({
  checked,
  disabled,
  onClick,
  onChange,
  indeterminate,
  label,
  sx,
  id,
}: CheckboxProps) => {
  const UncheckedIcon = (props: SvgIconProps) => {
    return (
      <SvgIcon {...props}>
        <rect
          opacity="0.3"
          x="2"
          y="2"
          width="20"
          height="20"
          rx="5"
          fill="currentColor"
        />
      </SvgIcon>
    );
  };

  const CheckedIcon = (props: SvgIconProps) => {
    return (
      <SvgIcon {...props}>
        <rect
          opacity="0.3"
          x="2"
          y="2"
          width="20"
          height="20"
          rx="5"
          fill={grey[400]}
        />
        <path
          d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
          fill={blue[900]}
        />
      </SvgIcon>
    );
  };

  const IndeterminateIcon = (props: SvgIconProps) => {
    return (
      <SvgIcon {...props}>
        <rect
          opacity="0.3"
          x="2"
          y="2"
          width="20"
          height="20"
          rx="5"
          fill="currentColor"
        />
        <rect
          x="6.0104"
          y="10.9247"
          width="12"
          height="2"
          rx="1"
          fill="currentColor"
        />
      </SvgIcon>
    );
  };

  return (
    <MaterialUICheckbox
      color="primary"
      onClick={onClick}
      checked={checked}
      onChange={onChange}
      indeterminate={indeterminate}
      disabled={disabled}
      inputProps={{
        "aria-labelledby": label ? label : undefined,
      }}
      icon={<UncheckedIcon />}
      checkedIcon={<CheckedIcon />}
      indeterminateIcon={<IndeterminateIcon />}
      sx={sx}
      id={id ? id : "material-checkbox"}
    />
  );
};

export default Checkbox;
