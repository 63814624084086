import { AnyAction } from "@reduxjs/toolkit";
import { SET_SITE } from "../actions/selected-site";

// Initial state
const initialState = {
  site: undefined,
};

// Reducer
const SelectedSiteReducer = (
  state = initialState as { site: any | undefined },
  action: AnyAction
) => {
  switch (action.type) {
    // Set User's data
    case SET_SITE:
      const { site } = action;
      return {
        ...state,
        site: {
          ...site,
        },
      };

    default:
      return state;
  }
};

export default SelectedSiteReducer;
