/**
 * Color intention that you want to used in your theme
 * @param {JsonObject} theme Theme customization object
 */

const darkPalette = (theme: any) => {
  return {
      mode: "dark",
      common: {
        black: "#fff",
        white: "#111936"
      },
      primary: {
        200: "#5472d3",
        800: "#90a4e6",
        gradient: "linear-gradient(0deg, rgba(59,61,73,1) 0%, rgba(82,91,97,1) 100%)",
        light: "#29314f",
        main: "#555555",
        dark: "#1565c0",
        contrastText: "#fff",
        container: "#40434D",
      },
      secondary: {
        200: "#9679ba",
        800: "#7858a8",
        gradient: "linear-gradient(0deg, rgba(59,61,73,1) 0%, rgba(82,91,97,1) 100%)",
        light: "#40434D",
        main: "#40434D",
        dark: "#4527a0",
        contrastText: "#fff"
      },
      error: {
        light: "#c66a6a",
        main: "#f44336",
        dark: "#c62828",
        contrastText: "#fff"
      },
      warning: {
        light: "#e6e0b3",
        main: "#ffe57f",
        dark: "#ffc107",
        contrastText: "#212121"
      },
      success: {
        200: "#52c986",
        light: "#80e5a8",
        main: "#69f0ae",
        dark: "#00e676",
        contrastText: "#212121",
      },
      dark: {
        800: "#e5e7f2",
        900: "#fafafa",
        light: "#6b76af",
        main: "#bdc8f0",
        dark: "#29314f"
      },
      text: {
        primary: "#ffffff",
        secondary: "#e0e0e0",
        dark: "#ffffff",
        hint: "#212121",
        disabled: "#999999",
        icon: "rgba(0, 0, 0, 0.5)",
        title: "#FFFFFF",
      },
      background: {
        paper: theme.paper,
        default: theme.backgroundDefault,
      },
      contrastThreshold: 3,
      tonalOffset: 0.2,
      divider: "rgba(0, 0, 0, 0.12)",
      action: {
        active: "#212121",
        hover: "rgba(0, 0, 0, 0.08)",
        hoverOpacity: 0.08,
        selected: "rgba(0, 0, 0, 0.16)",
        selectedOpacity: 0.16,
        disabled: "rgba(0, 0, 0, 0.3)",
        disabledBackgroun: "rgba(0, 0, 0, 0.12)",
        disabledOpacity: 0.38,
        focus: "rgba(0, 0, 0, 0.12)",
        focusOpacity: 0.12,
        activatedOpacity: 0.24
      },
      input: {
        background: '#4c4c4c',
        disabled: '#404040',
        timezone: '#4c4c4c',
        searchbar: '#D0D0D0',
      },
      button: {
        background: "#555555",
        icon: "#FFFFFF",
        radio: "#FFFFFF",
        disabled: "#404040",
        arrow: "#757575",
        dark: theme.backgroundDefault,
        hover: "#808080",
        transparent: "#555555",
        switch: "#FFFFFF",
      },
      container: {
        background: "#4c4c4c",
        disabled: '#404040',
        hover: "#18191A",
        body: "#5A6167",
        card: "#545D64",
        border: "#6D767F",
        dark: "#4c4c4c",
        tooltip: "rgba(229, 229, 229, 0.8)",
        label: "#828689"
      },
      table: {
        header: "#212121",
        darkBg: "#212121",
      }
    }
};

export default darkPalette;
