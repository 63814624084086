import tableActions from "../../interfaces/tableActions.interface";

/**
 * Filter the array of action by active tag and if it does mot have a tag param
 * @param permissions
 * @param customActions
 * @returns filtered array by missign tag param or action with active tag
 */
// OLD VERSION

// const disableOptionByTag = (
//   permissions: any[],
//   customActions: tableActions[]
// ) =>
//   customActions.filter(
//     (action) =>
//       permissions.length === 0 ||
//       !action.tag ||
//       permissions.find((perm) =>
//         action.tag?.includes(perm.tag.trim().toLocaleLowerCase())
//       )?.active
//   );

/**
 * Filter the array of action by active tag and if it does not have a tag param
 * user only needs to have one of the tags to see the action
 * @param permissions
 * @param customActions
 * @returns filtered array by missing tag param or action with active tag
 */
// NEW VERSION - MODIFIED ON JAN, 18 2024
const disableOptionByTag = (permissions: any[], customActions: tableActions[]) =>
  customActions.filter((action) =>
    permissions.length === 0 ||
    !action.tag ||
    action.tag.some(
      // if the user has one of the tags, the action will be displayed
      (tag) =>
        permissions.find(
          (perm) => perm.tag.trim().toLocaleLowerCase() === tag
        )?.active
    )
  );

export default disableOptionByTag;
