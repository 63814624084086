const holidays = {
  holidays: "Vacances",
  search_holiday: "Recherche vacances",
  add_holiday: "Ajouter un jour férié",
  update_holiday: "Mettre à jour les vacances",
  modal_general: "Général",
  modal_events: "Événements",
  form: {
    name: "Nom",
    ocurrence: "Occurrence",
    month: "Mois",
    day: "Jour",
    occurss: "Se produire",
    year: "An",
    duration_days: "Durée Jours",
    hours: "Les heures",
    minutes: "Minutes",
  },
  table: {
    holiday: "VACANCES",
    state: "ETAT",
    date: "DATE",
    duration: "DURÉE",
    is_yearly: "EST ANNUEL",
    is_relative: "EST UNE DATE RELATIVE",
    relative_week_day: "JOUR DE SEMAINE RELATIF",
  },
};

export default holidays;
