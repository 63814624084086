// MUI
import { Box, Typography, TextField } from "@mui/material";
import { DatePicker as MUIDatePicker } from "@mui/x-date-pickers";
// Components
import RequiredFieldIndicator from "../RequiredFieldIndicator/RequiredFieldIndicator";
import InfoIconLabel from "../InfoIconLabel/InfoIconLabel";
import {useTheme} from "@mui/system";

interface DateTimePickerProps {
  label?: string;
  value?: any;
  width?: string;
  marginTop?: number | string;
  marginBottom?: number | string;
  onChange?: any;
  disabled?: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
  required?: boolean;
  info?: string;
  pattern?: string;
  sx?: any;
  minDate?: any;
  error?: boolean;
  helperText?: string;
  id?:string;
}

const DatePicker = ({
  label,
  value,
  width,
  marginTop,
  marginBottom,
  onChange,
  disabled,
  disableFuture,
  disablePast,
  required,
  info,
  sx,
  minDate,
  error,
  helperText,
  id,
}: DateTimePickerProps) => {
  const theme = useTheme();
  return (
    <Box
      flex={1}
      width={width ? width : "100%"}
      marginTop={marginTop ? marginTop : 0}
      marginBottom={marginBottom ? marginBottom : 2}
    >
      {label && (
        <Box minHeight={20} >
          <Typography
            fontWeight="bold"
            sx={{
              marginLeft: "0.25em",
              color: disabled ? "lightgray" : null,
              fontSize: "0.75em",
              ...sx,
            }}
          >
            {label}
            {required && <RequiredFieldIndicator disabled={disabled} />}
            {info && <InfoIconLabel info={info} />}
          </Typography>
        </Box>
      )}
      <MUIDatePicker
        renderInput={(props) => (
          <TextField
            {...props}
            fullWidth
            sx={{
              height: "2.5em",
              "& fieldset": { border: "none" },
              ".MuiInputBase-root": {
                backgroundColor: disabled ? theme.palette.input.disabled : theme.palette.input.background,
                borderRadius: "0em",
              },
              ".MuiInputBase-input": {
                // background: disabled ? theme.palette.input.background : theme.palette.input.background,
                borderRadius: "0em",
                height: "0.2em",
              },
            }}
            error={error}
            helperText={helperText}
            id={id ? id : "datepicker"}
          />
        )}
        value={value}
        onChange={(date: any) => {
          onChange(date);
        }}
        disabled={disabled}
        disableFuture={disableFuture}
        disablePast={disablePast}
        minDate={minDate}
      />
    </Box>
  );
};

export default DatePicker;
