const global_settings = {
  keypad_tab: "Keypad code",
  fields_tab: "User custom fields",
  code_length: "User code length",
  min_length: "Minimum length",
  max_length: "Maximum length",
  keypad_confirmation_msg:
    "Are you sure you want to change the user code length from {{initialKeypadCode}} to {{keypadCode}}?",
  keypad_var_confirmation_msg:
    "Are you sure you want to change the minimum and maximum length of the user code?",
  field_label: "Field label",
  field_type: "Field type",
  fields_confirmation_msg:
    "Are you sure you want to save the changes? Any deletion of custom fields will affect users that had them.",
  fields_multi_confirmation_msg:
    "Changing to multisite will affect all sites, removing any data there may be, are you sure you want to continue?",
  fields_delete_msg:
    "Are you sure you want to delete this custom field? It has been assigned to {{user_count}} users.",
  fields_apply_default_msg:
    "If you apply the account settings, all data in existing custom fields will be removed, are you sure you want to continue?",
  fields_success: "Custom fields saved successfully",
  fields_error: "Error saving custom fields",
  keypad_success: "User code length changed successfully",
  keypad_error: "Error changing user code length",
  keypad_exists:
    "There are users with keypad code, modifications will be limited.",
  apply_multisite: "Apply to all sites",
  apply_default: "Apply account settings",
  multisite_keypad:
    "Multi-site option is enabled, cannot change user code length.",
  multisite_customfields:
    "Multi-site option is enabled, cannot change user custom fields.",
};

export default global_settings;
