const lockdown = {
  lockdown: "Lockdown",
  lockdown_options: "Lockdown Options",
  start_lockdown: "Start Lockdown",
  stop_lockdown: "Stop Lockdown",
  lockdown_started_msg: "(System lockdown started)",
  start_lockdown_message:
    "To Start Lockdown for Site - {{site}}, type your password and then press confirm.",
  stop_lockdown_message:
    "To Stop Lockdown for Site - {{site}}, type your password and then press confirm.",
  settings_modal_tile: "Lockdown - Edit {{site}}",
  start_options: "Start options",
  stop_options: "Stop options",
  login_required: "Login password required",
  card_pin_options: "Credential and pin options",
  unavailable_lockdown: "Unavailable in lockdown",
  confirm_start_lockdown:
    "Are you sure you want to start the lockdown for site {{site}}?",
  confirm_stop_lockdown:
    "Are you sure you want to stop the lockdown for site {{site}}?",
  pin_option: {
    single_swipe: "Single swipe",
    double_swipe: "Double Swipe required",
    two_men: "Two men rule required",
  },
};

export default lockdown;
