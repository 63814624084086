import { createTheme, ThemeOptions } from "@mui/material/styles";

// assets
import colors from "../assets/scss/_themes-vars.module.scss";

// project imports
import componentStyleOverrides from "./compStyleOverride";
import lightPalette from "./lightPalette";
import darkPalette from "./darkPalette";
import themeTypography from "./typography";
import darkTypography from "./darkTypography";

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */

export const theme = (customization: any) => {
  const color = colors;

  const commonThemeOptions = {
    // Common properties for both light and dark modes
    customization: {
      fontFamily: [
        "Lato",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      MuiDatePicker: {
        styleOverrides: {
          root: {
            backgroundColor: "red",
          },
        },
      },
    },
    mixins: {
      toolbar: {
        minHeight: "48px",
        padding: "16px",
        "@media (min-width: 600px)": {
          minHeight: "48px",
        },
      },
    },
  };

  const commonTheme = {
    direction: "ltr",
    typography: themeTypography(commonThemeOptions),
  };

  const lightTheme = {
    MuiInputBase: {
      styleOverrides: {
          root: {
              color: 'white',
              background: "red"
          }
      }
  },
    ...commonTheme,
    palette: lightPalette({
      ...commonThemeOptions,
      ...{
        colors: color,
        heading: color.grey900,
        paper: color.paper,
        backgroundDefault: color.secondaryLight,
        background: color.primaryLight,
        darkTextPrimary: color.grey700,
        darkTextSecondary: color.grey500,
        textDark: color.grey900,
        menuSelected: color.secondaryDark,
        menuSelectedBack: color.secondaryLight,
        divider: color.grey200,
      
      },
    }),
  };

  const darkTheme = {
      direction: "ltr",
      typography: darkTypography(commonThemeOptions),
      palette: darkPalette({
      ...commonThemeOptions,
      ...{
        colors: color,
        heading: color.grey900,
        paper: '#5C6166',
        default: '#18191A',
        backgroundDefault: '#000000',
        background: color.primaryLight,
        textDark: "#FFFFFF",
      },
    }),
  };

  const themes = {
    light: createTheme(lightTheme as ThemeOptions),
    dark: createTheme(darkTheme as ThemeOptions),
  };

  // const themes = createTheme(themeOptions as ThemeOptions);
  themes.light.components = componentStyleOverrides(lightTheme);
  themes.dark.components = componentStyleOverrides(darkTheme);
 

  return themes;
};

export default theme;