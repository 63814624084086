import {
  Snackbar,
  Alert,
  AlertTitle,
  AlertColor,
  SnackbarCloseReason,
  SnackbarOrigin,
  AlertProps,
} from '@mui/material';

interface SnackProps {
  open: boolean;
  onClose: any;
  severity?: AlertColor | string;
  title?: string;
  message: string;
  duration?: number;
  origin?: SnackbarOrigin;
  variant?: AlertProps['variant'];
}

const Snack = ({
  open,
  onClose,
  severity = 'info',
  title,
  message,
  duration = 5000,
  origin = { vertical: 'top', horizontal: 'center' },
  variant = 'standard',
}: SnackProps) => {
  const handleClose = (
    event: Event | React.SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => {
    if (reason && reason === 'clickaway') {
      return;
    } else {
      onClose();
    }
  };

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      autoHideDuration={duration}
      anchorOrigin={origin}>
      <Alert
        sx={{ width: '100%', borderRadius: '0em' }}
        severity={severity as AlertColor}
        onClose={handleClose}
        variant={variant}>
        {title && <AlertTitle>{title}</AlertTitle>}
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Snack;
