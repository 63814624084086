import {
  combineReducers,
  configureStore,
  PreloadedState,
} from "@reduxjs/toolkit";
import {
  TypedUseSelectorHook,
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from "react-redux";

// Reducers
import UserReducer from "./reducers/user";
import { controllerReadersReducer } from "./reducers/controller-readers";
import { hardwareInputReducer } from "./reducers/hardware-inputs";
import { hardwareOutputReducer } from "./reducers/hardware-outputs";
import { sitesLockdownReducer } from "./reducers/sites-lockdown";
import SelectedSiteReducer from "./reducers/selected-site";
import stripePromiseReducer from "./reducers/stripe-promise";

const rootReducer = combineReducers({
  user: UserReducer,
  controllerReaders: controllerReadersReducer,
  hardwareInputs: hardwareInputReducer,
  hardwareOutputs: hardwareOutputReducer,
  lockdown: sitesLockdownReducer,
  selectedSite: SelectedSiteReducer,
  stripePromise: stripePromiseReducer,
});

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
}

// Store types
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];

// Hooks
export const useDispatch: () => AppDispatch = useReduxDispatch;
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
