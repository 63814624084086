const forgot_password = {
  log_into_account: "Ingrese a su cuenta",
  log_msg: "Ingrese su nombre de usuario y contraseña",
  username: "Nombre de usuario",
  password: "Contraseña",
  current_password: "Contraseña actual",
  new_password: "Nueva contraseña",
  confirm_password: "Confirmar contraseña",
  remember_me: "Recuérdame",
  login: "Acceso",
  sign_in: "Registrarse",
  click_msg: "Al hacer clic en Iniciar sesión, aceptas nuestras",
  terms: "Términos y condiciones",
  and: "y",
  privacy: "Política de privacidad",
  account_msg: "¿No tienes cuenta?",
  register_here: "Registrarse aquí",
  forgot_password: "¿Se te olvidó tu contraseña?",
  enter_email: "Ingrese su correo electrónico para restablecer su contraseña",
  email: "Correo electrónico",
  cancel: "Cancelar",
  submit: "Entregar",
  to_continue:
    "Para continuar, complete este paso de verificación. Hemos enviado un código a",
  enter_it: "Ingréselo abajo",
  hello: "Hola",
  finish_msg: "para terminar de establecer una nueva contraseña",
  error: {
    required: "Campo requerido",
    email_format: "Formato de correo inválido",
    password_match: "Las contraseñas no coinciden",
  },
};

export default forgot_password;
