import {
  Box,
  Divider,
  Menu,
  MenuItem,
  Stack,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Button from "../../../components/Button/Button";
import { useTranslation } from "react-i18next";
import Avatar from "../../../components/Avatar/Avatar";
import routes from "../../../routes/routes";
import { useNavigate } from "react-router-dom";
import ChangePassword from "../ChangePassword/ChangePasword";
import SwitchProfile from "../SwitchProfile/SwitchProfile";
import user from "../../../interfaces/user.interface";
import { useDispatch, useSelector } from "../../../store";
import { removeUser } from "../../../store/actions/user";
import avatar from "../../../assets/images/media/avatars/avatar.png";
import lngs from "../../lngs";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import { putPreferences } from "../../../api/api";
import { setUser } from "../../../store/actions/user";

const settings = [
  "config.profile",
  "config.switch_profile",
  "config.change_password",
  "config.language",
  "config.dark_mode",
  "config.logout",
];

const settingsDealer = [
  "config.profile",
  "config.dealer_info",
  "config.switch_profile",
  "config.change_password",
  "config.language",
  "config.dark_mode",
  "config.logout",
];

const UserInfoButton = ({
  setMenu,
  setAlert,
}: {
  setMenu: (menu: any) => void;
  setAlert: (alert: any) => void;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user: user = useSelector((store) => store.user.user);
  const currentProfile = useMemo(
    () =>
      user.user_profiles.find(
        (profile) => profile.dealer_org_role_id === user.role
      ),
    [user.user_profiles]
  );

  const darkModeEnabled = user.features.find((element:any) => element.feature == "dark_mode").enabled
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const location = useLocation();
  const { pathname } = location;
  const { t, i18n } = useTranslation();

  const isFloorPlan = () =>
    pathname.includes(routes.floor_plan_view.split(":")[0]);

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [anchorElLang, setAnchorElLang] = useState<null | HTMLElement>(null);
  const [openSwitch, setOpenSwitch] = useState(false);
  const [openChangepsswd, setOpenChangepsswd] = useState(false);

  const localStorageUser = JSON.parse(localStorage.getItem("USER") as string);
 
  const checkUserProfile = useCallback(() => {
    if (user.user_profiles.length > 1 && !user.user_profiles.find((p:any) => p.dealer_org_role_id === user.role)) {
      setOpenSwitch(true);
    }
  }, [user.user_profiles]);

  useEffect(() => {
    checkUserProfile();
  }, [checkUserProfile]);

  useEffect(() => {
    i18n.changeLanguage(user.language);
    if (!darkModeEnabled) {
      user.darkmode === true && putDarkMode();
    };
  }, []);

  const putDarkMode = async () => {
    const body = {
      darkmode: !user.darkmode,
    }
    try {
      const resp = await putPreferences(body, user.user);
      localStorage.setItem(
        "USER",
        JSON.stringify({
          token: localStorageUser.token,
          data: {...localStorageUser.data, darkmode: !user.darkmode},
        })
      );
      dispatch(setUser({...user, darkmode: !user.darkmode}));
    } catch (error: any) {
      console.log(error)
    }
  };

  const handleChangeLang = async (lng:string) => {
    const body = {
      language: lng,
    }
    try {
      const resp = await putPreferences(body, user.user);
      localStorage.setItem(
        "USER",
        JSON.stringify({
          token: localStorageUser.token,
          data: {...localStorageUser.data, language: lng},
        })
      );
      i18n.changeLanguage(lng);
    } catch (error: any) {
      console.log(error)
    }
  }

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenLangMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElLang(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCloseLangMenu = () => {
    setAnchorElUser(null);
    setAnchorElLang(null);
  };

  const handleSuccess = (msg: string) => {
    setOpenChangepsswd(false);
    setAlert({
      open: true,
      message: msg,
      severity: "success",
    });
  };

  const handleActionClick = (
    setting: string,
    e: React.MouseEvent<HTMLElement>
  ) => {
    switch (setting) {
      case "config.logout":
        localStorage.removeItem("USER");
        dispatch(removeUser());
        // darkMode === true && handleDarkModeToggle();
        break;

      case "config.profile":
        navigate(routes.profile);
        break;

      case "config.change_password":
        setOpenChangepsswd(true);
        break;

      case "config.switch_profile":
        setOpenSwitch(true);
        break;

      case "config.language":
        handleOpenLangMenu(e);
        break;
      case "config.dealer_info":
        navigate(routes.dealer_info_view);
        break;
      case "config.dark_mode":
        putDarkMode();
        break;
    }
    setAnchorElUser(null);
    setAnchorElLang(null);
  };

  return (
    <>
      <Tooltip title={t("config.open_settings")}>
        <Button
          color="transparent"
          paddingLeft="1em"
          paddingRight="1em"
          onClick={handleOpenUserMenu}
          id="navigation-bar-user-menu-button"
          disabled={isFloorPlan()}
          sx={{
            "&:disabled": {
               backgroundColor: null,
               color: "darkgray",
            },
          }}
        >
          <Stack
            direction={matches ? "row" : "column"}
            alignItems="center"
            spacing={matches ? 2 : 0}
            marginTop={matches ? 0 : 1}
            textAlign={"left"}
          >
            <Avatar
              className="transparent-avatar"
              sx={{ width: 40, height: 40 }}
              variant="rounded"
              src={
                user.logo
                  ? `data:image/png;base64,${user.logo}`
                  : "/static/images/avatar/2.jpg"
              }
            >
              <img
                src={user.logo ? `data:image/png;base64,${user.logo}` : avatar}
              />
            </Avatar>
            <Box>
              {matches && (
                <Typography component="div" variant="subtitle1">
                  {t("general.welcome")}
                </Typography>
              )}
              <Typography component="div" variant="caption">
                {user.first_name} {user.last_name}
              </Typography>
            </Box>
          </Stack>
        </Button>
      </Tooltip>
      <Menu
        PaperProps={{ style: { width: 275, maxWidth: "100%" } }}
        sx={{ mt: "50px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <Stack
          direction="row"
          padding={1}
          alignItems="center"
          spacing={2}
          textAlign={"left"}
        >
          <Avatar
            className="transparent-avatar"
            variant="rounded"
            sx={{ width: 40, height: 40 }}
            src={
              user.logo
                ? `data:image/png;base64,${user.logo}`
                : "/static/images/avatar/2.jpg"
            }
          >
            <img
              src={user.logo ? `data:image/png;base64,${user.logo}` : avatar}
            />
          </Avatar>

          <Box>
            <Typography component="div" variant="subtitle1">
              {user.first_name} {user.last_name}
            </Typography>
            <Typography component="div" variant="caption">
              {currentProfile?.company_org_name || null}
            </Typography>
          </Box>
        </Stack>
        <Divider />
        {user.role_name?.toLowerCase() === "dealer"
          ? settingsDealer.map((setting) =>
              setting === "config.switch_profile" &&
              user.user_profiles.length <= 1 || (
              setting === "config.dark_mode" && !darkModeEnabled) ? null : (
                <MenuItem
                  style={{ margin: 6 }}
                  key={setting}
                  id={`menu-item-${setting?.replace(/[._]/g, "-")}`}
                  onClick={(e) => {
                    if (setting !== "config.language") {
                      handleActionClick(setting, e);
                    }
                    if (setting === "config.language") {
                      setAnchorElLang(e.currentTarget);
                    }
                  }}
                >
                  <Stack
                    direction={"row"}
                    width="100%"
                    justifyContent="space-between"
                    gap={1}
                  >
                    {setting !== "config.dark_mode" ? (
                      <Typography component="div" textAlign="center">
                        {t(setting)}
                      </Typography>
                    ) : (
                    darkModeEnabled && (
                      <Stack
                        direction={"row"}
                        width="100%"
                        justifyContent={"space-between"}
                        gap={1}
                      >
                        <Typography component="div" textAlign="center">
                          {user.darkmode ? "Light Mode" : "Dark Mode"}
                        </Typography>
                        <IconButton
                          sx={{ padding: 0 }}
                        >
                          {user.darkmode ? (
                            <Brightness7Icon
                              sx={{ color: "white", fontSize: 18.5 }}
                            />
                          ) : (
                            <Brightness4Icon sx={{ fontSize: 18.5 }} />
                          )}
                        </IconButton>
                      </Stack>
                      )
                    )}
                    {setting == "config.language" && (
                      <Stack
                        direction={"row"}
                        width="100%"
                        justifyContent="flex-end"
                        gap={1}
                      >
                        <Typography component="div" textAlign="center">
                          {t(lngs[i18n.language.substring(0, 2)].nativeName)}{" "}
                        </Typography>
                        <img
                          width={15}
                          src={t(lngs[i18n.language.substring(0, 2)].flag)}
                        />
                      </Stack>
                    )}
                  </Stack>
                </MenuItem>
              )
            )
          : settings.map((setting) =>
              setting === "config.switch_profile" &&
              user.user_profiles.length <= 1 || (
              setting === "config.dark_mode" && !darkModeEnabled) ? null : (
                <MenuItem
                  key={setting}
                  style={{ margin: 6 }}
                  id={`menu-item-${setting?.replace(/[._]/g, "-")}`}
                  onClick={(e) => {
                    if (setting !== "config.language") {
                      handleActionClick(setting, e);
                    }
                    if (setting === "config.language") {
                      setAnchorElLang(e.currentTarget);
                    }
                  }}
                >
                  <Stack
                    direction={"row"}
                    width="100%"
                    justifyContent="space-between"
                    gap={1}
                  >
                   {setting !== "config.dark_mode" ? (
                      <Typography component="div" textAlign="center">
                        {t(setting)}
                      </Typography>
                    ) : (
                      darkModeEnabled && (
                      <Stack
                        direction={"row"}
                        width="100%"
                        justifyContent={"space-between"}
                        gap={1}
                      >
                        <Typography component="div" textAlign="center">
                          {user.darkmode ? "Light Mode" : "Dark Mode"}
                        </Typography>
                        <IconButton
                          sx={{ padding: 0 }}
                        >
                          {user.darkmode ? (
                            <Brightness7Icon
                              sx={{ color: "white", fontSize: 18.5 }}
                            />
                          ) : (
                            <Brightness4Icon sx={{ fontSize: 18.5 }} />
                          )}
                        </IconButton>
                      </Stack>
                      )
                    )}
                    {setting == "config.language" && (
                      <Stack
                        direction={"row"}
                        width="100%"
                        justifyContent="flex-end"
                        gap={1}
                      >
                        <Typography component="div" textAlign="center">
                          {t(lngs[i18n.language.substring(0, 2)].nativeName)}{" "}
                        </Typography>
                        <img
                          width={15}
                          src={t(lngs[i18n.language.substring(0, 2)].flag)}
                        />
                      </Stack>
                    )}
                  </Stack>
                </MenuItem>
              )
            )}
      </Menu>
      <Menu
        PaperProps={{ style: { width: 150, maxWidth: "100%" } }}
        sx={{ mt: "50px", boxShadow: 1 }}
        id="menu-appbar"
        anchorEl={anchorElLang}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        open={Boolean(anchorElLang)}
        onClose={handleCloseLangMenu}
      >
        {Object.keys(lngs).map((lng) => (
          <MenuItem
            style={{ margin: 6 }}
            key={lng}
            onClick={() => handleChangeLang(lng)}
          >
            <Stack direction={"row"} gap={1.5}>
              <img width={15} src={lngs[lng].flag} />
              <Typography component="div" textAlign="center">
                {t(lngs[lng].nativeName)}
              </Typography>
            </Stack>
          </MenuItem>
        ))}
      </Menu>

      {/* Modals dialogs */}
      <SwitchProfile
        open={openSwitch}
        onClose={() => setOpenSwitch(false)}
        setMenu={setMenu}
      />
      <ChangePassword
        open={openChangepsswd}
        onClose={() => setOpenChangepsswd(false)}
        onSuccess={(msg: string) => handleSuccess(msg)}
        onError={(msg: string) =>
          setAlert({
            open: true,
            message: msg,
            severity: "error",
          })
        }
      />
    </>
  );
};

export default UserInfoButton;
