import React, { useEffect, useState } from "react";
import Modal from "../../../../../components/Modal/Modal";
import { useTranslation } from "react-i18next";
import MaterialTable from "../../../../../components/MaterialTable/MaterialTable";
import { Stack, Typography } from "@mui/material";
import Button from "../../../../../components/Button/Button";
import sitesHeadCell from "./sitesHeadCell";

interface props {
  open: boolean;
  account: any;
  siteList: any[];
  onClose: () => void;
  onSuccess: (obj: any) => void;
}

let allList: any[] = [];

const EditSites = ({ open, account, siteList, onClose, onSuccess }: props) => {
  const { t } = useTranslation();

  const [list, setList] = useState<any[]>([]);
  const [selected, setSelected] = useState<any[]>([]);
  const [initialSelected, setInitialSelected] = useState<any[]>([]);
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      allList = siteList;
      setList(allList);

      if (allList?.length > 0) {
        const selectedArr = account?.all_sites
          ? allList.map((l) => l.site)
          : account?.sites;

        setInitialSelected(selectedArr);
        setSelected(selectedArr);
      }
    }
  }, [siteList, open]);

  useEffect(() => {
    // Check if selected is different from initial selected
    if (selected?.length !== initialSelected?.length) {
      setHasChanges(true);
    } else {
      const diff = selected.filter(
        (ele) => !initialSelected.includes(ele)
      ).length;
      setHasChanges(diff > 0);
    }
  }, [selected, initialSelected]);

  const handleActionClick = (action: string, row: any) => {
    switch (action) {
      case "edit":
        break;
    }
  };

  const search = (event: any) => {
    setList(
      allList.filter(
        (ele) =>
          String(ele.name)
            .toLocaleLowerCase()
            .indexOf(event.currentTarget.value.toLocaleLowerCase()) > -1
      )
    );
  };

  return (
    <Modal
      open={open}
      width="md"
      onClose={onClose}
      title={`${t("PERMISSION_OPTION")} - ${t("general.btn.edit")} ${t(
        "sites.sites"
      ).toLowerCase()}`}
      showLeaveConfirmation={hasChanges}
    >
      <>
        <Typography pl={2} component="h3" variant="h3">
          {account?.name}
        </Typography>
        <MaterialTable
          id={"site"}
          rows={list}
          selectable
          selected={selected || []}
          setSelectedItems={(i) => setSelected(i)}
          searchbarPlaceholder={t("sites.search_site")}
          onChangeSearchbar={search}
          headCells={sitesHeadCell}
          onRowClick={(row) => {}}
          onActionClick={handleActionClick}
        />
        <Typography pl={2} component="h3" variant="h3">
          {`${t("sites.total_sites_assigned")}: ${selected?.length}`}
        </Typography>
        <Stack direction="row" justifyContent="end" flexWrap="wrap" gap={2}>
          <Button color="gray" onClick={onClose}>
            {t("general.btn.cancel")}
          </Button>
          <Button disabled={!hasChanges} onClick={() => onSuccess(selected)}>
            {t("general.btn.save")}
          </Button>
        </Stack>
      </>
    </Modal>
  );
};

export default EditSites;
