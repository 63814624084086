const tasks = {
    general: {
        search: 'Search by UUID',
        clear: 'Clear',
        searchBtn: 'Search',
        serialNmbr: 'Serial Number',
        any: 'Any',
    },
    selectors: {
        selector3: {
            notAvailable: 'Not Available',
            pending: 'Pending',
            executing: 'Executing',
            finished: 'Finished',
        },
        selector4: {
            any: 'Any',
            today: 'Today',
            yesterday: 'Yesterday',
            last7Days: 'Last 7 days',
            custom: 'Custom',
        },
    },
    table: {
        siteName: 'Site Name',
        date: 'Date',
        status: 'Status',
        id: 'ID',
        id_detail: 'ID Detail',
        serial_number: 'Serial Number',
        commandtype: 'Command Type',
        entitytype: 'Entity Type',
        success: 'Success',
        success_label: 'Success',
        started_at: 'Started At',
        finished_at: 'Finished At',
    },
    modal: {
        title: 'Controller Task-Detail',
        header: 'Header',
        details: 'Details',
        notAvailable: 'Not Available',
        pending: 'Pending',
        executing: 'Executing',
        finished: 'Finished',
    }
}

export default tasks;