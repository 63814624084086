const roles = {
  header: "Lista de Roles",
  view_role: "Ver Rol",
  edit_role: "Editar Rol",
  add_role: "Agregar un Rol",
  update_role: "Actualizar un Rol",
  add_new_role: "Agregar nuevo Rol",
  admin_access: "Acceso de Admin",
  cancel: "Cancelar",
  submit: "Enviar",
  total_users: "Cantidad de usuarios con este rol",
  menu_details: "Detalles del Menú",
  permission_details: "Detalles del permiso",
  search_role: "Rol de búsqueda",
  select_role_details: "Selecciona un rol para ver detalles",
  no_permissions: "No hay permisos configurados para este rol.",
  table: {
    name: "Nombre",
    description: "Descripción",
    active: "Activo",
    users: "Usuarios",
    admin: "Administrador",
  },
  form: {
    role_name: "Nombre del Rol",
    allow_full_access: "Permite el acceso completo al sistema",
    roles_permission: "Permisos de Rol",
    select_all: "Seleccionar Todo",
    view: "Ver",
    write: "Escribir",
    create: "Crear",
    delete: "Eliminar",
  },
  error: {},
  roleType: {
    label: "Tipo de rol",
    admin: "Administrador",
    dealer: "Proveedor",
    organization: "Cuenta",
  },
  defaultRole: "Rol por defecto",
};

export default roles;
