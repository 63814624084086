const organization = {
  organizations: "Accounts",
  search_organization: "Search Accounts",
  create_organization: "Create Accounts",
  add_organization: "Add Account",
  add_new_organization: "Add New Account",
  organization_role: "Account Role",
  controllers: "Controllers",
  search_controller: "Search Controllers",
  organization: "Account",
  monthly: "Monthly",
  yearly: "Yearly",
  doors: "Doors",
  doors_licenses: "Doors Licenses",
  pricing: "Pricing",
  profile: "Profile",
  billing: "Billing",
  search_billing: "Search Billing",
  licenses: "Licenses",
  subscription: "Subscription",
  users: "Users",
  fee: "Fee",
  amount: "amount",
  how_many_doors_msg: "How many doors licenses do you want to activate?",
  payment_methods: "Payment methods",
  name_on_card: "Name on Credential",
  card_number: "Credential number",
  expiration_date: "Expiration date",
  is_default: "Make as default",
  expires_at: "Credential expires at",
  confirm_checkout_msg: "Are you sure you want to Checkout?",
  skip_checkout_msg:
    "You can buy door later on your account. Do you want to proceed?",
  request_processed: "Your request has been processed",
  set_organization: "Set Account",
  change_organization_msg: "Do you want to exit the account?",
  select_organization_msg: "Please go to accounts and select one",
  summary: "Summary",
  invoices: "Invoices",
  invoice: "Invoice",
  paid: "Paid",
  pending: "Pending",
  mobile_passes: "Mobile Passes",
  next_invoice: "Next Invoice",
  last_update: "Last Update",
  set_default: "Set Default",
  organization_created_msg: "{{account}} has been created!",
  activate_controller_msg:
    "There are no controllers added yet. Kickstart your access by adding your first controller",
  single_site: "Single site",
  validate: {
    type_email: "Please, type your email",
    verification_code: "Verification",
    validation_label:
      "A validation code has been sent to your email, please check your inbox",
    done: "Done",
    success: "Success! You may now use your CDVI Cloud account",
    continue: "Continue",
    finish: "Finish",
  },
  table: {
    company: "COMPANY",
    full_name: "FULL NAME",
    email: "EMAIL",
    country: "COUNTRY",
    last_login: "LAST LOGIN",
    active: "ACTIVE",
    address: "ADDRESS",
    status: "STATUS",
    site: "SITE",
    controller_type: "CONTROLLER TYPE",
    serial_number: "SERIAL NUMBER",
    ip_address: "IP ADDRESS",
    port: "PORT",
    organization: "ACCOUNT",
    firmware_version: "FIRMWARE VERSION",
    date: "DATE",
    invoice: "INVOICE",
    amount: "AMOUNT",
    payment: "PAYMENT",
    invoice_number: "INVOICE NUMBER",
    type: "TYPE",
    total: "TOTAL",
  },
  form: {
    type: "Type of Dealer",
    company: "Company",
    full_name: "Full Name",
    first_name: "First Name",
    main_contact_name: "Main Contact - First Name",
    last_name: "Last Name",
    second_contact_name: "Second Contact - First Name",
    second_contact_last_name: "Last Name",
    email: "Email",
    country: "Country",
    address: "Address",
    city: "City",
    state: "State",
    zip_code: "Zip Code",
    phone: "Phone",
    mobile: "Mobile",
    create_admin: "Create an user",
    timezone: "Timezone",
    username: "Username",
  },
  information: {
    organization: "Account",
    dealer: "Dealer",
    company: "Company",
    first_name: "First name",
    last_name: "Last name",
    email: "Email",
    username: "Username",
    website: "Website",
    country: "Country",
    address: "Address",
    city: "City",
    state: "State",
    zipcode: "Zipcode",
    timezone: "Timezone",
    region: "Region",
    phone: "Phone",
  },
  modal: {
    title: "Upgrade Subscription",
    remove_title: "Remove Subscription",
    description: "How many doors licenses would you like to add?",
    current_subscription: "Current Subscription",
    previous: "Previous",
    checkout: "Checkout",
    change_card: "Change Card",
    invoice_details: "Invoice details",
    current: "Current",
    next_cycle: "Next Cycle",
    request_processed: "Your request has been processed",
    print_receipt: "Print Receipt",
    download_receipt: "Download Receipt",
    finish: "Finish",
    due_today: "Due Today",
    confirmation_description:
      "The payment cannot be modified after checking this window, are you sure you want to checkout?",
    total_amount: "Total amount",
    remove_confirmation_description:
      "When you click the 'confirm' button, the process of eliminating doors or mobile passes will be done inmediately.",
    remove_description: "How many doors licenses would you like to remove?",
    remove: "Remove",
    confirmation: "Confirmation",
    delete_payment_description: "Are you sure to delete this card",
    default_payment_description:
      "Are you sure you want to set this card as default",
  },
  error: {
    invalid_email: "Invalid email format",
  },
  set_multisite_msg:
    "Are you sure you want to change <b style={{color: 'blue'}}>{{name}} from Single Site to</b> <b style={{color: 'green'}}>Multiple Sites</b>",
  set_singlesite_msg:
    "Are you sure you want to change <b style={{color: 'blue'}}>{{name}} from Multiple Sites to</b> <b style={{color: 'green'}}>Single Site</b>",
};

export default organization;
