const acceslevel = {
  accesslevel: "Nivel de acceso",
  search_accesslevel: "Buscar nivel de acceso",
  add_accesslevel: "Agregar nivel de acceso",
  update_accesslevel: "Actualizar nivel de acceso",
  accesslevel_manager: "Gestionar",
  doors: "Puertas",
  users: "Usuarios",
  schedules: "Horarios",
  can_not_delete: "El nivel de acceso está protegido. no se puede borrar",
  extend_time: "Extender tiempo",
  override_inter: "Anular enclavamientos",
  form: {
    name: "Nombre",
    ocurrence: "Ocurrencia",
    month: "Mes",
    day: "Día",
    occurss: "Ocurrir",
    year: "Año",
    duration_days: "Duración (Días",
    hours: "Horas",
    minutes: "Minutos",
    status: "Estatus",
    active: "Activo",
    inactive: "Inactivo",
  },
  table: {
    state: "EXPRESAR",
    accesslevel: "NIVEL DE ACCESO",
    door: "PUERTA",
    schedules: "CALENDARIO",
  },
  manager: {
    search_users: "Buscar usuarios",
    user_count: "Cantidad de usuarios",
    back_to_list: "Volver a la lista",
    add_users: "Asignar usuarios",
    users_remove_success: "Usuarios desasignados exitosamente",
    users_remove_error: "Error al desasignar usuarios",
    users_add_success: "Usuarios asignados exitosamente",
    users_add_error: "Error al asignar usuarios",
    remove_selected_btn: "Desasignar ({{count}}) seleccionados",
    remove_selected_msg:
      "¿Estás seguro de que deseas desasignar los siguientes usuarios de {{accessLevelLabel}}?",
    add_selected_btn: "Asignar ({{count}}) seleccionados",
    add_selected_msg:
      "¿Estás seguro de que deseas asignar los siguientes usuarios a {{accessLevelLabel}}?",
    replace: "Reemplazar",
    replace_selected_btn: "Reemplazar ({{count}}) seleccionados",
    replace_success: "Reemplazo de nivel de acceso exitoso",
    replace_error: "Error al reemplazar nivel de acceso",
    replace_alevel_msg:
      "¿Estás seguro de que deseas reemplazar los siguientes usuarios de {{accessLevelLabel}} por {{newAccessLevelLabel}}?",
    count_users_selected: "{{count}} usuarios seleccionados",
    users_with_errors:
      "El nivel de acceso no se puede asignar a los siguientes usuarios:",
    alert: 'Alerta',
  },
};

export default acceslevel;
