import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// Material UI
import { Box, Stack, Theme, Typography, useMediaQuery } from "@mui/material";

// Store
import { useDispatch, useSelector } from "../../store";
import { changeProfile, removeUser } from "../../store/actions/user";
// Imports
import user from "../../interfaces/user.interface";
import Snack from "../../components/Snack/Snack";
import { useInterval } from "usehooks-ts";
import { blue } from "@mui/material/colors";
import { site } from "../../views/OrganizationAdmin/Sites/interfaces/site.interface";
import { getDealersImg, getOrganizationDetail } from "../../api/api";
import { setSelectedSite } from "../../store/actions/selected-site";
import Avatar from "../../components/Avatar/Avatar";
import UserInfoButton from "./UserInfoButton/UserInfoButton";
import SelectionNav from "./SelectionNav/SelectionNav";
import SelectionNavMini from "./SelectionNavMini/SelectionNavMini";
import Clock from "./Clock/Clock";
import routes from "../../routes/routes";
import placeholder from "./../../assets/images/media/avatars/logo_placeholder.png";

const NavigationBar = ({
  open,
  setMenu,
}: {
  open: boolean;
  setMenu: (menu: any) => void;
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const user: user = useSelector((store) => store.user.user);
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const { pathname } = location;

  const [organization, setOrganization] = useState<any>();
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  useEffect(() => {
    fecthOrgnization();
  }, []);

  useEffect(() => {
    user.selectedDealer?.dealer && fetchImage();
  }, [user.selectedDealer?.dealer]);

  const displayNavbar = () =>
    !pathname.includes(routes.floor_plan_view.split(":")[0]);

  const fecthOrgnization = async () => {
    try {
      const { status, data } = await getOrganizationDetail(
        user.selectedOrg?.organization || user.currentProfile
      );
      status === 200 &&
        setOrganization({
          ...data,
        });
    } catch (error: any) {}
  };

  const fetchImage = async () => {
    try {
      const { image } = await getDealersImg(
        String(user.selectedDealer?.dealer)
      );
      dispatch(
        changeProfile(
          user.permissions,
          user.role,
          user.role_name,
          user.currentProfile,
          user.profile_type,
          user.selectedOrg,
          {
            ...user.selectedDealer,
            image: image ? `data:image/jpeg;base64,${image}` : "",
          }
        )
      );
    } catch (error) {
    } finally {
    }
  };

  return (
    <>
      <Stack
        direction="row"
        sx={{ alignItems: "center" }}
        justifyContent="space-between"
        width="100%"
      >
        {displayNavbar() ? (
          <Box pl={matches ? (open ? 1 : 0) : open ? 0 : 4}>
            {matches ? <SelectionNav /> : <SelectionNavMini />}
          </Box>
        ) : (
          <Box sx={{display: "flex", alignItems: "center"}}>
            <Avatar
              className="transparent-avatar"
              variant="rounded"
              sx={{ width: 40, height: 40, marginTop:1 }}
              src={
                organization?.logo
                ? `data:image/png;base64,${organization?.logo}`
                : placeholder
              }
            >
              <img width={50} height={50} src={placeholder} />
            </Avatar>
            <Box pl={1}>
              {matches ? <SelectionNav /> : <SelectionNavMini />}
            </Box>
          </Box>
        )}
        {/* User section and settings */}
        <Box
          sx={{ flexGrow: 0, display: "flex", gap: 1, alignItems: "center" }}
        >
          {matches && <Clock />}
          <UserInfoButton setMenu={setMenu} setAlert={setAlert} />
        </Box>
      </Stack>

      {/* Snack component */}
      <Snack
        open={alert.open}
        message={alert.message}
        onClose={() => setAlert({ ...alert, open: false })}
        severity={alert.severity}
      />

      {/* confirm clear organization */}
      {/* <ConfirmationModal
        icon
        message={`${t("organization.change_organization_msg")}`}
        cancelBtnTxt={t("general.btn.cancel")}
        submitBtnTxt={t("general.btn.confirm")}
        open={showConfirmModal}
        onClose={() => {
          setShowConfirmModal(false);
        }}
        onConfirm={() => {
          setShowConfirmModal(false);
          dispatch(
            changeProfile(
              user.permissions,
              user.role,
              user.role_name,
              user.currentProfile,
              user.profile_type,
              null,
              user.selectedDealer
            )
          );
          navigate(routes.organization_list);
        }}
      /> */}

      {/* confirm clear dealer */}
      {/* <ConfirmationModal
        icon
        message={`${t("dealers.change_dealer_msg")}`}
        cancelBtnTxt={t("general.btn.cancel")}
        submitBtnTxt={t("general.btn.confirm")}
        open={showConfirmDealerModal}
        onClose={() => {
          setShowConfirmDealerModal(false);
        }}
        onConfirm={() => {
          setShowConfirmDealerModal(false);
          dispatch(
            changeProfile(
              user.permissions,
              user.role,
              user.role_name,
              user.currentProfile,
              user.profile_type,
              null,
              null
            )
          );
          navigate(routes.dealer_list);
        }}
      /> */}
    </>
  );
};
export default NavigationBar;
