import React, { ChangeEventHandler, FC } from "react";

// MUI
import { styled, alpha } from "@mui/material/styles";
import { Box, InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { blue, grey } from "@mui/material/colors";
import { useTheme } from "@mui/system";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  // borderRadius: theme.shape.borderRadius,
  backgroundColor: grey[100],
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "20ch",
      "&:focus": {
        width: "32ch",
      },
    },
  },
}));

const MaterialSearchBar: FC<{
  marginTop?: string;
  width?: string;
  placeholder?: string;
  height?: string;
  onChange: ChangeEventHandler<HTMLInputElement> | undefined;
  hideIcon?: boolean;
  value?: string;
  id?: string
}> = ({ marginTop, width, placeholder, height, onChange, hideIcon, value, id }) => {
  const theme = useTheme();
  return (
    <Search sx={{backgroundColor: theme.palette.input.searchbar, height: height || "", marginTop: marginTop || "" }}>
      <SearchIconWrapper sx={{ display: hideIcon ? "none" : "flex" }}>
        <SearchIcon style={{ color: grey[600] }} />
      </SearchIconWrapper>
      <StyledInputBase
        style={{ color: grey[600] }}
        placeholder={placeholder || ""}
        inputProps={{
          "aria-label": "search",
          style: {
            padding: `${hideIcon ? "8px" : ""}`,
            width: width || "",
          },
        }}
        onChange={onChange}
        value={value}
        id={id ? id : "search-bar"} 
      />
    </Search>
  );
};

export default MaterialSearchBar;
