// Interfaces
import user from "../../interfaces/user.interface";

// Actions
export const SET_USER = "SET_USER";
export const REMOVE_USER = "REMOVE_USER";
export const CHANGE_PROFILE = "CHANGE_PROFILE";

// Action creators
export const setUser = (user: user) => ({
  type: SET_USER,
  user: user,
});

export const removeUser = () => ({
  type: REMOVE_USER,
});

export const changeProfile = (
  permissions: any[],
  role: string,
  role_name: string,
  dealer_org_id: string,
  profile_type: string,
  selectedOrg: any | null,
  selectedDealer?: any | null
) => ({
  type: CHANGE_PROFILE,
  permissions,
  role,
  role_name,
  dealer_org_id,
  profile_type,
  selectedOrg,
  selectedDealer,
});
