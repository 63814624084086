const lockdown = {
  lockdown: "Confinement",
  lockdown_options: "Options de verrouillage",
  start_lockdown: "Démarrer le verrouillage",
  stop_lockdown: "Arrêtez le confinement",
  lockdown_started_msg: "(Le verrouillage du système a commencé)",
  start_lockdown_message:
    "Pour démarrer le verrouillage du site - {{site}}, saisissez votre mot de passe puis appuyez sur confirmer.",
  stop_lockdown_message:
    "Pour arrêter le verrouillage du site - {{site}}, saisissez votre mot de passe puis appuyez sur confirmer.",
  settings_modal_tile: "Verrouillage - Modifier {{site}}",
  start_options: "Options de démarrage",
  stop_options: "Options d'arrêt",
  login_required: "Mot de passe de connexion requis",
  card_pin_options: "Options d'informations d'identification et de code PIN",
  unavailable_lockdown: "Indisponible pendant le confinement",
  confirm_start_lockdown:
    "Etes-vous sûr de vouloir démarrer le verrouillage du site {{site}}?",
  confirm_stop_lockdown:
    "Êtes-vous sûr de vouloir arrêter le verrouillage du site {{site}}?",
  pin_option: {
    single_swipe: "Un seul coup",
    double_swipe: "Double balayage requis",
    two_men: "La règle de deux hommes est requise",
  },
};

export default lockdown;
