import {
  Box,
  FormControlLabel,
  IconButton,
  Paper,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import UserAvatarStatus from "../../../../../components/UserAvatarStatus/UserAvatarStatus";
import { amber, blue, green } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import ConfirmationModal from "../../../../../components/ConfirmationModal/ConfirmationModal";
import getTag from "../../../../../utils/security/getTag";
import getActionArray from "../../../../../utils/security/getActionsArray";
import { useSelector } from "../../../../../store";
import EditIcon from "@mui/icons-material/Edit";
import { getDealerRolesList, putDealerUser } from "../../../../../api/api";
import Select from "../../../../../components/Select/Select";
import Snack from "../../../../../components/Snack/Snack";
import Checkbox from "../../../../../components/Checkbox/Checkbox";
import remove from "../../../../../assets/images/perms/remove_access.png";
import grant from "../../../../../assets/images/perms/grant_access.png";
import RolesTooltip from "../../../../../components/RolesTooltip/RolesTooltip";
import InfoIcon from "@mui/icons-material/Info";

interface props {
  user: any;
  dealer: string;
  setUser: (user: any) => void;
  openEdit: () => void;
  clearList: () => void;
}

const EmployeeCard = ({
  user,
  dealer,
  setUser,
  openEdit,
  clearList,
}: props) => {
  const { t } = useTranslation();
  const userInfo = useSelector((store: any) => store.user.user);
  // Security list of permissions
  const actionList = useMemo(
    () => getActionArray(userInfo.permissions, ["user account"]),
    []
  );

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showConfirmRole, setShowConfirmRole] = useState(false);
  const [showAllAccess, setShowAllAccess] = useState(false);
  const [newRole, setNewRole] = useState<any>(null);
  const [roles, setRoles] = useState([]);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  useEffect(() => {
    fecthRoles();
  }, []);

  const editUser = async ({
    new_status,
    dealer_role,
    dealer_u_allorg,
  }: {
    new_status?: boolean;
    dealer_role?: string;
    dealer_u_allorg?: boolean;
  }) => {
    try {
      const { status, message } = await putDealerUser(
        String(user.dealer_user),
        String(user.first_name),
        String(user.last_name),
        String(user.email),
        dealer_role || String(user.dealer_role),
        new_status !== undefined ? new_status : user.dealer_u_active,
        dealer_u_allorg !== undefined ? dealer_u_allorg : user.dealer_u_allorg
      );
      if (status === 201) {
        setTimeout(() => {
          setUser({
            ...user,
            dealer_role: dealer_role || String(user.dealer_role),
            dealer_u_active:
              new_status !== undefined ? new_status : user.dealer_u_active,
            dealer_u_allorg:
              dealer_u_allorg !== undefined
                ? dealer_u_allorg
                : user.dealer_u_allorg,
          });
        }, 500);
        setAlert({
          open: true,
          message: message,
          severity: "success",
        });
        dealer_u_allorg === false && clearList();
      }
    } catch (error: any) {
      setAlert({
        open: true,
        message: error?.response.data.message,
        severity: "error",
      });
    } finally {
      setShowConfirmModal(false);
      setShowConfirmRole(false);
      setShowAllAccess(false);
    }
  };

  const fecthRoles = async () => {
    try {
      const { status, roles } = await getDealerRolesList(dealer);
      status === 200 && setRoles(roles);
    } catch (error: any) {
      setAlert({
        open: true,
        message: error?.response.data.message,
        severity: "error",
      });
    }
  };

  return (
    <Paper sx={{ borderRadius: 0 }}>
      <Box padding={2} sx={{ position: "relative" }}>
        {getTag(actionList, ["useraccount-edit"]) && (
          <IconButton
            sx={{
              position: "absolute",
              top: -10,
              right: -10,
            }}
            onClick={openEdit}
          >
            <EditIcon />
          </IconButton>
        )}
        {/* Avatar */}
        <Stack
          direction="column"
          alignContent="start"
          justifyContent="start"
          mb={1}
        >
          <Box alignSelf="start">
            <UserAvatarStatus
              imgSrc={user?.photo}
              text={`${user?.first_name?.charAt(0)}${user?.last_name?.charAt(
                0
              )}`}
              status={user?.dealer_u_active}
              dimmension={100}
            />
          </Box>
          <Typography
            textAlign="start"
            variant="h4"
          >{`${user?.first_name} ${user?.last_name}`}</Typography>
        </Stack>
        <Stack
          direction="row"
          flexWrap="wrap"
          justifyContent="space-between"
          gap={1}
          alignContent="center"
        >
          <Box flex={1}>
            {/* Roles */}
            <Typography
              variant="h4"
              sx={{
                marginBottom: 0.2,
                fontSize: 11,
                marginLeft: 0.3,
              }}
            >
              {t("dealers.emp_role")}
              <Typography
                component="span"
                sx={{ marginLeft: 0.2, color: "#EE2222" }}
              >
                *
              </Typography>
              <RolesTooltip
                arrow
                placement="right-start"
                roles={roles}
                entity={user}
                roleKey="dealer_role"
                entityKey="dealer_role"
              >
                <InfoIcon
                  sx={{
                    fontSize: 16,
                    color: amber[400],
                    marginLeft: 1,
                    marginBottom: 0.2,
                  }}
                />
              </RolesTooltip>
            </Typography>
            <Select
              required
              value={user.dealer_role}
              onChange={
                (event: any) => {
                  setNewRole(
                    roles.find((r: any) => event.target.value === r.dealer_role)
                  );
                  setShowConfirmRole(true);
                }
                // setUser({ ...user, dealer_role: event.target.value })
              }
              options={roles}
              optionName="name"
              optionValue="dealer_role"
              marginBottom="0.1"
            />
          </Box>
          <Box alignSelf="end" minWidth={110}>
            <FormControlLabel
              labelPlacement="start"
              label={
                user?.dealer_u_active
                  ? t("general.active")
                  : t("general.inactive")
              }
              control={
                <Switch
                  checked={user?.dealer_u_active}
                  onClick={() => setShowConfirmModal(true)}
                />
              }
            />
          </Box>
        </Stack>
        {/* <FormControlLabel
          control={
            <Checkbox
              checked={user.dealer_u_allorg}
              disabled={true}
              onChange={() => setShowAllAccess(true)}
            />
          }
          label={t("dealers.access_all_accounts")}
        /> */}
      </Box>

      {/* Confirmation for states */}
      <ConfirmationModal
        icon
        message={`${t("users.activate_confirm_msg", {
          action: user?.dealer_u_active
            ? t("general.btn.disable").toLowerCase()
            : t("general.btn.enable").toLowerCase(),
          user: `${user?.first_name} ${user?.last_name}`,
        })}`}
        cancelBtnTxt={t("general.btn.cancel")}
        submitBtnTxt={t("general.btn.confirm")}
        open={showConfirmModal}
        onClose={() => {
          setShowConfirmModal(false);
        }}
        onConfirm={() => editUser({ new_status: !user.dealer_u_active })}
      />

      {/* Confirmation for role */}
      <ConfirmationModal
        icon
        message={`${t("users.role_confirm_msg", {
          role: newRole?.name || "",
        })}`}
        cancelBtnTxt={t("general.btn.cancel")}
        submitBtnTxt={t("general.btn.confirm")}
        open={showConfirmRole}
        onClose={() => {
          setShowConfirmRole(false);
        }}
        onConfirm={() => editUser({ dealer_role: newRole.dealer_role })}
      />

      {/* Set all acess */}
      <ConfirmationModal
        customIcon={
          <img width={100} src={user.dealer_u_allorg ? remove : grant} />
        }
        message={
          user?.dealer_u_allorg
            ? t("dealers.remove_all_access")
            : t("dealers.grant_all_access")
        }
        cancelBtnTxt={`${t("general.btn.cancel")}`}
        submitBtnTxt={`${t("general.btn.confirm")}`}
        open={showAllAccess}
        onClose={() => setShowAllAccess(false)}
        onConfirm={() => editUser({ dealer_u_allorg: !user.dealer_u_allorg })}
      />

      {/* Snack */}
      <Snack
        open={alert.open}
        onClose={() => setAlert({ ...alert, open: false })}
        message={alert.message}
        severity={alert.severity}
      />
    </Paper>
  );
};

export default EmployeeCard;
