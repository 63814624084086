const floorplan = {
  floorplan: "Floor Plan",
  add_floorplan: "Add Floor Plan",
  upload_floorplan: "Upload Floor Plan",
  remove_floorplan: "Remove Floor Plan",
  search_floorplan: "Seach Floor Plan",
  add_door_input: "Add doors / inputs",
  add_selected: "Add all selected",
  live_view: "Live view",
  delete_image_msg: "Are you sure you want to remove the floor plan image?",
  last_activity: "Last Activity",
  custom: "Custom",
  form: {
    available_allusers: "Available for all users",
    ask_password_every: "Ask password every",
    small_icons: "Small Icons",
    doors: "Doors",
    inputs: "Inputs",
  },
  modal: {
    title: "Custom Time",
    minutes: "Minutes",
    hours: "Hours",
    description_password: "To continue, enter your password.",
    password: "Password",
    failed_attempts: "Failed Attempts"
  }
};

export default floorplan;
