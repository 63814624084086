const holidays = {
  holidays: "Días festivos",
  search_holiday: "Buscar vacaciones",
  add_holiday: "Añadir vacaciones",
  update_holiday: "Actualizar vacaciones",
  modal_general: "General",
  modal_events: "Eventos",
  form: {
    name: "Nombre",
    ocurrence: "Ocurrencia",
    month: "Mes",
    day: "Día",
    occurss: "Ocurrir",
    year: "Año",
    duration_days: "Duración (Días",
    hours: "Horas",
    minutes: "Minutos",
  },
  table: {
    holiday: "VACACIÓN",
    state: "EXPRESAR",
    date: "FECHA",
    duration: "DURACIÓN",
    is_yearly: "ES ANUAL",
    is_relative: "ES FECHA RELATIVA",
    relative_week_day: "DÍA DE LA SEMANA RELATIVO",
  },
};

export default holidays;
