const global_settings = {
  keypad_tab: "Code clavier",
  fields_tab: "Champs personnalisés de l'utilisateur",
  code_length: "Longueur du code utilisateur",
  min_length: "Longueur minimale",
  max_length: "Longueur maximale",
  keypad_confirmation_msg:
    "Êtes-vous sûr de vouloir changer la longueur du code utilisateur de {{initialLength}} à {{keypadCodeLength}}?",
  keypad_var_confirmation_msg:
    "Êtes-vous sûr de vouloir changer la longueur minimale et maximale du code utilisateur ?",
  field_label: "Étiquette du champ",
  field_type: "Type de champ",
  fields_confirmation_msg:
    "Êtes-vous sûr de vouloir enregistrer les modifications ? Toute suppression de champs personnalisés affectera les utilisateurs qui les avaient.",
  fields_multi_confirmation_msg:
    "Le passage au multi-site affectera tous les sites, supprimant toutes les données qui pourraient y être, êtes-vous sûr de vouloir continuer ?",
  fields_delete_msg:
    "Êtes-vous sûr de vouloir supprimer ce champ personnalisé ? Il a été attribué à {{user_count}} utilisateurs.",
  fields_apply_default_msg:
    "Si vous appliquez les paramètres du compte, toutes les données dans les champs personnalisés existants seront supprimées, êtes-vous sûr de vouloir continuer ?",
  fields_success: "Champs personnalisés enregistrés avec succès",
  fields_error: "Erreur lors de l'enregistrement des champs personnalisés",
  keypad_success: "Longueur du code utilisateur modifiée avec succès",
  keypad_error: "Erreur lors du changement de la longueur du code utilisateur",
  keypad_exists:
    "Il y a des utilisateurs avec un code clavier, les modifications seront limitées.",
  apply_multisite: "Appliquer à tous les sites",
  apply_default: "Appliquer les paramètres du compte",
  multisite_keypad:
    "L'option multi-site est activée, impossible de changer la longueur du code utilisateur.",
  multisite_customfields:
    "L'option multi-site est activée, impossible de changer les champs personnalisés de l'utilisateur.",
};

export default global_settings;
