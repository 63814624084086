import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "../../../../store";
import { Box, CircularProgress, Grid, Paper, Stack } from "@mui/material";
import Button from "../../../../components/Button/Button";
import routes from "../../../../routes/routes";
import {
  deleteDealerUserAssignAccount,
  getDealerUserDetails,
  getOrganizationDealer,
  getSitesByOrganization,
  postDealerUserAssignAccount,
  putDealerUserEditSites,
} from "../../../../api/api";
import AddUserModal from "../DealerUsers/AddUser/AddUserModal";
import user from "../../../../interfaces/user.interface";
import EmployeeCard from "./EmployeeCard/EmployeeCard";
import MaterialTable from "../../../../components/MaterialTable/MaterialTable";
import tableActions from "../../../../interfaces/tableActions.interface";
import accountsHeadCell from "./accountsHeadCell";
import AssignAccount from "./AssignAccount/AssignAccount";
import Snack from "../../../../components/Snack/Snack";
import ConfirmationModal from "../../../../components/ConfirmationModal/ConfirmationModal";
import EditSites from "./EditSites/EditSites";
import { grey } from "@mui/material/colors";

let selectedAccount: any = null;
let sites: any = [];

const ViewEmployee = () => {
  const { _employee } = useParams();
  const { t } = useTranslation();
  const userState: user = useSelector((store) => store.user.user);
  const dealerId = userState.selectedDealer?.dealer || userState.currentProfile;

  const [user, setUser] = useState<any>(null);
  // const [account, setAccount] = useState<any>(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showAssignAccount, setShowAssignAccount] = useState(false);
  const [showEditSites, setShowEditSites] = useState(false);
  const [accountList, setAccountList] = useState<any[]>([]);
  const [siteList, setSiteList] = useState<any[]>([]);
  const [selected, setSelected] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  const returnSiteList = useMemo(() => {
    return (
      siteList.find(
        (s) => s[0].organization.organization === selectedAccount?.organization
      ) || []
    );
  }, [selectedAccount]);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    await getOrganizations();
    // await fetchUserImage();
  };

  const fetchUser = async (organizations: any) => {
    try {
      setLoading(true);
      const { dealer_user } = await getDealerUserDetails(String(_employee));
      //   const { user_org_img } = await getUserorgImage(String(_user));
      setUser({
        ...dealer_user,
        photo: "",
        //   `data:image/png;base64,${user_org_img}`,
      });
      const accounts = dealer_user.dealer_u_organizations?.map(
        (a: any) => a.organization
      );
      setSelected(
        organizations.filter((a: any) => accounts?.includes(a.organization))
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getOrganizations = async () => {
    try {
      const { status, organizations } = await getOrganizationDealer(
        String(dealerId),
        "all",
        ""
      );
      if (status === 200) {
        setAccountList(
          organizations.map((o: any) => {
            return {
              ...o,
              site_title: !o.multi_site
                ? t("organization.single_site")
                : t("general.all_sites"),
            };
          })
        );
        await getSitesByOrg(organizations);
        fetchUser(organizations);
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  const getSitesByOrg = async (organizations: any) => {
    try {
      const list = await Promise.all(
        organizations.map(
          async (org: any) =>
            await getSitesByOrganization(String(org.organization))
        )
      );
      const sitesTemp = list.map((l) => l.sites);
      setSiteList(sitesTemp);
      sites = sitesTemp;
    } catch (error: any) {
      console.error(error);
    }
  };

  const removeAccess = async (organization: string) => {
    try {
      const { status, message } = await deleteDealerUserAssignAccount(
        String(_employee),
        organization
      );
      if (status === 201) {
        setSelected((s) => s.filter((a) => a.organization !== organization));
        setAlert({
          open: true,
          message: message,
          severity: "success",
        });
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      selectedAccount = null;
      setShowConfirmModal(false);
    }
  };

  const handleActionClick = (action: string, row: any) => {
    switch (action) {
      case "edit":
        selectedAccount = row;
        setShowEditSites(true);
        break;
      case "remove":
        selectedAccount = row;
        setShowConfirmModal(true);
        break;
    }
  };

  const handleUpdateUser = (newUserInfo: any) => {
    setUser({ ...newUserInfo });
  };

  const handleAssignSuccess = (obj: any) => {
    const { msg, selected: newList } = obj;
    setSelected((s) => s.concat(newList));
    setShowAssignAccount(false);
    setAlert({
      open: true,
      message: msg,
      severity: "success",
    });
  };

  const successEditSites = async (selectedSites: any[]) => {
    try {
      const body = {
        organization: selectedAccount?.organization,
        sites: selectedSites,
        all_sites: returnSiteList?.length === selectedSites?.length,
        site_title:
          returnSiteList?.length === selectedSites?.length
            ? t("general.all_sites")
            : returnSiteList
                .filter((s: any) => selectedSites.includes(s.site))
                .map((s: any) => s.name)
                .join(","),
      };
      const { status, message } = await putDealerUserEditSites(
        String(_employee),
        body
      );
      if (status === 201) {
        setAlert({
          open: true,
          message: message,
          severity: "success",
        });
        setSelected(
          selected.map((a: any) =>
            a.organization === body.organization ? { ...a, ...body } : { ...a }
          )
        );
        setShowEditSites(false);
      }
    } catch (error: any) {
      setAlert({
        open: true,
        message: error.response.data?.message || error.message,
        severity: "error",
      });
    }
  };

  //  if loading show progress
  if (loading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="20em"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Grid
        container
        direction={"row"}
        columnSpacing={2}
        rowSpacing={2}
      >
        <Grid item xs={12} md={4}>
          {user && (
            <EmployeeCard
              user={user}
              dealer={dealerId}
              setUser={(userInfo: any) => {
                handleUpdateUser(userInfo);
              }}
              clearList={() => {}}
              openEdit={() => setShowAddUserModal(true)}
            />
          )}
        </Grid>
        {/* Security tag check */}
        <Grid item xs={12} md={8}>
          {user && (
            <Paper sx={{ borderRadius: 0, p: 2 }}>
              <Stack
                direction="row"
                justifyContent="end"
                flexWrap="wrap"
                gap={2}
                mb={2}
              >
                {/* <Button
                  disabled={user?.dealer_u_allorg}
                  onClick={() => setShowAssignAccount(true)}
                >
                  {`${t("users.card.assign")} ${t(
                    "organization.organizations"
                  )}`}
                </Button> */}
              </Stack>

              {user && (
                <AssignAccount
                  user={user}
                  setUser={(userInfo: any) => {
                    handleUpdateUser(userInfo);
                  }}
                  clearList={() => setSelected([])}
                  // open={showAssignAccount}
                  accountList={accountList}
                  selectedAccounts={selected}
                  siteList={siteList}
                  _employee={String(_employee)}
                  onClose={() => setShowAssignAccount(false)}
                  onSuccess={handleAssignSuccess}
                  setAlert={setAlert}
                />
              )}
              {/* <MaterialTable
                  actions={!user?.dealer_u_allorg}
                  removeToolBar
                  id={"organization"}
                  rows={user?.dealer_u_allorg ? accountList : selected}
                  headCells={accountsHeadCell}
                  customActions={customActions}
                  onRowClick={(row) => {}}
                  onActionClick={handleActionClick}
                /> */}
            </Paper>
          )}
        </Grid>
        {/* Add Employee Modal */}
        <AddUserModal
          editMode
          _user={user}
          dealer={dealerId}
          open={showAddUserModal}
          onClose={() => setShowAddUserModal(false)}
          onSuccess={(userInfo: any) => {
            //   getSecurityUserList();
            setUser(userInfo);
            setShowAddUserModal(false);
          }}
        />
        {/* Assign Account Modal */}
        {/* <AssignAccount
          user={user}
          setUser={(userInfo: any) => {
            handleUpdateUser(userInfo);
          }}
          clearList={() => setSelected([])}
          open={showAssignAccount}
          accountList={accountList}
          selectedAccounts={selected}
          siteList={siteList}
          _employee={String(_employee)}
          onClose={() => setShowAssignAccount(false)}
          onSuccess={handleAssignSuccess}
          setAlert={setAlert}
        /> */}
        {/* Confirmation Modal */}
        <ConfirmationModal
          icon
          message={t("dealers.remove_access_msg", {
            name: selectedAccount?.name,
          })}
          cancelBtnTxt={`${t("general.no")}, ${t("general.btn.cancel")}`}
          submitBtnTxt={`${t("general.yes")}, ${t("general.btn.remove")}`}
          open={showConfirmModal}
          onClose={() => setShowConfirmModal(false)}
          onConfirm={() => removeAccess(selectedAccount?.organization)}
        />

        {/* Edit sites modal */}
        <EditSites
          open={showEditSites}
          account={selectedAccount}
          siteList={returnSiteList}
          onClose={() => setShowEditSites(false)}
          onSuccess={successEditSites}
        />
        {/* Snack */}
        <Snack
          open={alert.open}
          onClose={() => setAlert({ ...alert, open: false })}
          message={alert.message}
          severity={alert.severity}
        />
      </Grid>
    </>
  );
};

export default ViewEmployee;
