const events = {
  general: {
    search: "Search Event",
    refresh: "Refresh",
    add: "Add",
    assign: "Assign",
    details: "Event details",
    fetchError: "Failed to fetch data",
  },
  selectors: {
    selector1: {
      any: "Any",
      today: "Today",
      yesterday: "Yesterday",
      last7Days: "Last 7 days",
      custom: "Custom",
      month: "This month",
      last60: "Last 60 days",
      last180: "Last 180 days",
    },
    selector2: {
      all: "All",
      access: "Access",
      security: "Security",
      alarms: "Alarms",
      troubles: "Troubles",
      lockdown: "Lockdown",
      others: "Others",
      cloud: "Cloud",
    },
    selector3: {
      all: "All",
      organization: "Account",
      site: "Site",
      controller: "Controller",
      door: "Door",
      accessLevel: "Access Level",
      card: "Credential",
      user: "User",
      schedule: "Schedule",
      holiday: "Holiday",
      reader: "Reader",
    },
  },
  table: {
    date: "Date",
    description: "Description",
    object: "Object",
    detail: "Detail",
    info: "Info",
    extraInfo: "Extra Info",
    instigator: "Instigator",
    event: "Event",
    type: "Type",
    id: "ID",
    displayName: "Display Name",
    module: "Module",
    sequenceNumber: "Sequence Number",
  },
  cards: {
    mobilepass: "Mobile Pass is not supported yet",
  },
};

export default events;
