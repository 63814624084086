const outputs = {
    table: {
        displayName: 'Afficher un nom',
        serialNumber: 'Numéro de série',
        normallyOpen: 'Normalement ouvert',
        searchPlaceHolder: 'Nom d\'affichage / numéro de série',
        enabled: 'Activé',
    },
    modal: {
        title: 'Propriétés d\'entrée',
        tabInformation: 'Informations générales',
        TabEvents: 'Événements',
    },
    form: {
        options: 'Choix',
        checkEnable: 'Activer',
        radioNormallyOpen: 'Normalement ouvert',
        radioNormallyClose: 'Normalement fermé',
        inputResponseTime: 'Temps de réponse d\'entrée (ms)',
        inputRestoreTime: 'Temps de restauration d\'entrée (ms)'
    },
};
export default outputs;
