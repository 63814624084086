import React from "react";
// MUI
import { Box, Stack, Typography } from "@mui/material";
import { blue, green, red, teal, yellow, grey } from "@mui/material/colors";
// MUI icons
import CancelIcon from "@mui/icons-material/Cancel";
import CircleIcon from "@mui/icons-material/Circle";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import SmartScreenIcon from "@mui/icons-material/SmartScreen";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HelpIcon from "@mui/icons-material/Help";
import PasswordIcon from '@mui/icons-material/Password';
// COMPONENTS
import DoorStateIcon from "./../../../components/DoorStateIcon/DoorStateIcon";
import InputStateIcon from "../../InputStateIcon/InputStateIcon";
import icon from "../../../interfaces/icon.interface";

interface props {
  tag: string;
  color?: "blue" | "green" | "red" | "teal" | "yellow" | "grey";
  colorIntensity?: number;
  state?: icon["state"];
  id?: string;
}

const TableIcons = ({ tag, color, colorIntensity, state, id }: props) => {
  const components: any = {
    cancelIcon: CancelIcon,
    circleIcon: CircleIcon,
    lockIcon: LockIcon,
    lockOpenIcon: LockOpenIcon,
    smartScreenIcon: SmartScreenIcon,
    warningIcon: WarningIcon,
    checkCircleIcon: CheckCircleIcon,
    helpIcon: HelpIcon,
    passwordIcon: PasswordIcon,
  };

  const colors: any = {
    blue: blue,
    green: green,
    red: red,
    teal: teal,
    yellow: yellow,
    grey: grey,
  };

  const render = () => {
    const TagName = components[tag || "foo"];
    if (tag === "doorStateIcon") {
      return <DoorStateIcon id={id ? id : "door-icon"} state={state || "closed"} width={22} />;
    } 
    else if (tag === "inputStateIcon") {
      return <InputStateIcon id={id ? id : "input-icon"} state={state || "closed"} width={22} />;
    } else {
      return (
        <TagName htmlColor={color && colors[color][colorIntensity || 500]} />
      );
    }
  };

  return render();
};

export default TableIcons;
