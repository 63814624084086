import React from "react";
// Door Icons
import doorOpen from "../../assets/images/doors/door_open_new.png";
import doorClosed from "../../assets/images/doors/door_new.png";
import doorPrealarm from "../../assets/images/doors/door_open_new.png";
import doorForced from "../../assets/images/doors/door_open_new.png";
import doorTampered from "../../assets/images/doors/door_new.png";
import doorLockdown from "../../assets/images/doors/door_new.png";
// Access icons
import accessDisabled from "../../assets/images/lockstatus/disable.png";
import accessEnabled from "../../assets/images/doors/access_enabled.png";
// Lock Status
import lockOperator from "../../assets/images/lockstatus/green1.png";
import unlockOperator from "../../assets/images/lockstatus/red1.png";
import locked from "../../assets/images/lockstatus/green1.png";
import unlocked from "../../assets/images/lockstatus/red1.png";
import unlockedAccess from "../../assets/images/lockstatus/red1.png";
import unlockedGrant from "../../assets/images/lockstatus/schedule.png";
// others
import unknow from "../../assets/images/lockstatus/unknow.png";
import warning from "../../assets/images/lockstatus/warning1_new.png";
// Material
import { Box } from "@mui/material";
import icon from "../../interfaces/icon.interface";

interface props {
  state: icon["state"];
  width?: number;
  id?: string;
}

const getImg = (key: string) => {
  switch (key) {
    case "open":
      return doorOpen;
    case "closed":
      return doorClosed;
    case "prealarm":
      return doorPrealarm;
    case "forced":
      return doorForced;
    case "alarm":
      return doorClosed;
    case "tampered":
      return doorTampered;
    case "accessDisabled":
      return accessDisabled;
    case "accessEnabled":
      return accessEnabled;
    case "lockOperator":
      return lockOperator;
    case "unlockOperator":
      return unlockOperator;
    case "locked":
      return locked;
    case "unlocked":
      return unlocked;
    case "unlockedAccess":
      return unlockedAccess;
    case "unknow":
      return unknow;
    case "warning":
      return warning;
    case "unlockedGrant":
      return unlockedGrant;
    case "lockdown":
      return doorLockdown;
  }
};

const getBlink = (key: string) => {
  switch (key) {
    case "forced":
      return "blink";
    case "tampered":
      return "blink";
    case "lockOperator":
      return "blink";
    case "unlockOperator":
      return "blink";
    case "lockdown":
      return "blink";
    default:
      return "";
  }
};

const getBg = (key: string) => {
  switch (key) {
    case "open":
     return "#c8e6c9";
    case "prealarm":
      return "#ffcc80";
    case "forced":
      return "#ef9a9a";
    case "alarm":
      return "#ef9a9a";
    default:
      return "";
  }
};

const DoorStateIcon = ({ state, width, id }: props) => {
  return (
    <Box maxWidth={width || 30} id={id} sx={{backgroundColor: getBg(state ?? "")}}>
      <img className={getBlink(state ?? "")} width="100%" src={getImg(String(state))} />
    </Box>
  );
};

export default DoorStateIcon;
