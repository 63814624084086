import { ReactNode } from "react";
// MUI
import Typography from "@mui/material/Typography";
import { SxProps } from "@mui/material";
// Components
import RequiredFieldIndicator from "../RequiredFieldIndicator/RequiredFieldIndicator";

interface FormLabelProps {
  children?: ReactNode;
  required?: boolean;
  disabled?: boolean;
  sx?: SxProps;
  marginBottom?: string;
}

const FormLabel = ({
  children,
  required = false,
  disabled = false,
  sx,
  marginBottom,
}: FormLabelProps) => {
  return (
    <Typography
      fontWeight="bold"
      sx={{
        marginLeft: "0.25em",
        fontSize: "0.75em",
        color: disabled ? "lightgray" : null,
        marginBottom: marginBottom ? marginBottom : "0",
        ...sx,
      }}
    >
      {children}
      {required && <RequiredFieldIndicator disabled={disabled} />}
    </Typography>
  );
};

export default FormLabel;
