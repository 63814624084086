import React, {useState} from "react";
import { Box, Typography, Collapse, IconButton, Stack } from "@mui/material";
import colors from "../../utils/colors";
import { grey } from "@mui/material/colors";
import { useTheme } from "@mui/system";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

interface props {
  title: string;
  body: React.ReactNode;
  padding?: number;
  hideCollapse?: boolean;
  open?: boolean;
  setOpen?: (open: any) => void;
}

const CardComponent = ({ title, body, padding, hideCollapse, open, setOpen }: props) => {
  const theme = useTheme();

  return (
    <Box
      // borderRadius={2}
      overflow="hidden"
      sx={{ border: `1px solid ${grey[200]}`}}
    >
      <Stack
        padding={2}
        paddingTop={1.2}
        paddingBottom={1.2}
        direction="row"
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{height:"38px", color: "white", background: theme.palette.secondary.main }}
      >
        <Typography>{title}</Typography>
        {!hideCollapse && (
          <IconButton 
                sx={{padding: 0}}
                size="small"
                onClick={() => setOpen && setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon sx={{color:"white"}} /> : <KeyboardArrowDownIcon sx={{color:"white"}} />}
          </IconButton>
        )}
      </Stack>
      {!hideCollapse ? (
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
        >
          <Box padding={padding || 2}>
            {body}
          </Box>
        </Collapse>
      ) : (
        <Box padding={padding || 2}>
          {body}
        </Box>
      )}
    </Box>
  );
};

export default CardComponent;
