import { Route, Routes, Navigate } from "react-router-dom";

// Store
import { useSelector } from "../store";

// Interfaces
import user from "../interfaces/user.interface";

// Material

// Project imports
import routes from "./routes";
import StaticLayout from "../layout/StaticLayout/StaticLayout";
import ClearLayout from "../layout/ClearLayout/ClearLayout";
import LoginScreen from "../views/LoginScreen/LoginScreen";
import ForgotPassword from "../views/ForgotPassword/ForgotPassword";
import Activate from "../views/Dealers/Activate/Activate";
import AddDealerRequest from "../views/LoginScreen/AddDealerRequest";
import PreRegister from '../views/LoginScreen/PreRegister';

const PublicRoutes = () => {
  const user: user = useSelector((state) => state.user?.user);

  return (
    <Routes>
      <Route element={<ClearLayout />}>
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/preregistration" element={<PreRegister />} />
        <Route path="/dealer_request" element={<AddDealerRequest />} />
      {/* <Route element={<StaticLayout />}> */}
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path={"/activate_account"} element={<Activate />} />
        {/* Page Not Found */}
        <Route path="*" element={<Navigate to={"/login"} />} />
      {/* </Route> */}
      </Route>
    </Routes>
  );
};

export { PublicRoutes };
