import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

// MUI
import { Button, Menu, MenuItem, SxProps, Theme } from "@mui/material";
import { grey } from "@mui/material/colors";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

//Store
import { useSelector } from "../../../store";

// Project imports
import setActionTable from "../../../utils/setActionTable";
import user from "../../../interfaces/user.interface";
import routes from "../../../routes/routes";
import { useTranslation } from "react-i18next";
import tableActions from "../../../interfaces/tableActions.interface";
import disableOptionByTag from "../../../utils/security/disableOptionByTag";
import disableOptionByValue from "../../../utils/security/disableOptionByValue";
import { idFormat } from "../../../utils/idFormat";

interface props {
  title?: string;
  onActionClick: any;
  customActions?: any;
  customLabel?: string;
  customBgColor?: string;
  customColor?: string;
  fullWidth?: boolean;
  actionList?: any[];
  disabled?: boolean;
  row?: any;
  id?:any;
  sx?: SxProps<Theme> | undefined;
}

const initActions = {
  view: false, // View action
  update: false, // Update action
  add_new: false, // Add new action
  delete: false, // Delete action
};

const TableOptionsBtn = ({
  title,
  onActionClick,
  customActions,
  customLabel,
  customBgColor,
  customColor,
  fullWidth,
  actionList,
  disabled = false,
  row,
  id,
  sx,
}: props) => {
  const { t } = useTranslation();
  const user: user = useSelector((store) => store.user.user);
  const location = useLocation();

  const [actions, setActions] = React.useState(initActions);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    setActions({
      view: setActionTable(user, "view"), // View action
      update: setActionTable(user, "update"), // Update action
      add_new: setActionTable(user, "add_new"), // Add new action
      delete: setActionTable(user, "delete"), // Delete action
    });
  }, [location.pathname, user]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleActionClick = (
    event: React.MouseEvent<HTMLElement>,
    action: string
  ) => {
    event.stopPropagation();
    setAnchorEl(null);
    if (onActionClick) {
      onActionClick(action);
    }
  };

  return (
    <div>
      {customActions?.length === 1 ? (
        disableOptionByValue(
          disableOptionByTag(actionList || [], customActions),
          row
        ).map((ele: any, i:number) => (
          <Button
            variant="contained"
            disabled={disabled}
            style={{ background: customBgColor || grey[100] }}
            onClick={(event: React.MouseEvent<HTMLElement>) =>
              handleActionClick(event, customActions[0].action)
            }
            id={`table-option-button-${id ? id : ""}`}
          >
            <span style={{ color: customColor || grey[500] }}>
              {title || t(customActions[0].title)}
            </span>
          </Button>
        ))
      ) : (
        disableOptionByValue(
          disableOptionByTag(actionList || [], customActions),
          row
        ).length > 0 && (
          <>
            <Button
              sx={{ ...sx }}
              variant="contained"
              fullWidth={fullWidth}
              style={{ background: customBgColor || grey[100] }}
              onClick={handleClick}
              disableElevation
              disabled={disabled}
              id={`table-options-${id ? id : ""}`}
            >
              <span style={{ color: customColor || grey[500] }}>
                {customLabel ? t(customLabel) : t("general.btn.actions")}
              </span>
              <KeyboardArrowDownIcon
                style={{ color: customColor || grey[500], width: "0.6em" }}
              />
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={(event: React.MouseEvent<HTMLElement>) =>
                handleClose(event)
              }
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              {customActions ? (
                disableOptionByValue(
                  disableOptionByTag(actionList || [], customActions),
                  row
                ).map((ele: any, i: number) => (
                  <MenuItem
                    key={ele.title}
                    onClick={(event: React.MouseEvent<HTMLElement>) =>
                      handleActionClick(event, ele.action)
                    }
                    id={`menu-option-${i}`}
                  >
                    {t(ele.title)}
                  </MenuItem>
                ))
              ) : (
                <>
                  {/* View Action */}
                  {actions.view && (
                    <MenuItem
                      onClick={(event: React.MouseEvent<HTMLElement>) =>
                        handleActionClick(event, "view")
                      }
                    >
                      View
                    </MenuItem>
                  )}

                  {/* Edit Action */}
                  {actions.update && (
                    <MenuItem
                      onClick={(event: React.MouseEvent<HTMLElement>) =>
                        handleActionClick(event, "edit")
                      }
                    >
                      Edit
                    </MenuItem>
                  )}

                  {/* Edit Action */}
                  {location.pathname.includes(routes.dealer_list) && (
                    <MenuItem
                      onClick={(event: React.MouseEvent<HTMLElement>) =>
                        handleActionClick(event, "resend_invitation")
                      }
                    >
                      Re-send invitation
                    </MenuItem>
                  )}

                  {/* Delete Action */}
                  {actions.delete && (
                    <MenuItem
                      onClick={(event: React.MouseEvent<HTMLElement>) =>
                        handleActionClick(event, "delete")
                      }
                    >
                      Delete
                    </MenuItem>
                  )}
                </>
              )}
            </Menu>
          </>
          )
      )}
    </div>
  );
};

export default TableOptionsBtn;
