const roles = {
  header: "Liste des rôles",
  view_role: "Afficher le rôle",
  edit_role: "Modifier le rôle",
  add_role: "Ajouter un rôle",
  update_role: "Mettre à jour un rôle",
  add_new_role: "Ajouter un nouveau rôle",
  admin_access: "Accès administrateur",
  cancel: "Annuler",
  submit: "Soumettre",
  total_users: `Nombre total d'utilisateurs avec ce rôle`,
  menu_details: "Détails des menus",
  permission_details: `Détails de l'autorisation`,
  search_role: "Rechercher un rôle",
  select_role_details: "Sélectionnez un rôle pour voir les détails",
  no_permissions: "Aucune autorisation configurée pour ce rôle.",
  table: {
    name: "Nom",
    description: "La description",
    active: "Actif",
    users: "Utilisateurs",
    admin: "Administrateur",
  },
  form: {
    role_name: "Nom de rôle",
    allow_full_access: "Permet un accès complet au système",
    roles_permission: "Autorisations des rôles",
    select_all: "Tout sélectionner",
    view: "Voir",
    write: "Écrire",
    create: "créer",
    delete: "effacer",
  },
  error: {},
  roleType: {
    label: "Type de rôle",
    admin: "Administrateur système",
    dealer: "Marchand",
    organization: "Compte",
  },
  defaultRole: "Rôle par défaut",
};

export default roles;
