import React, { FC, ReactNode } from "react";

// Mui
import { Box, Stack } from "@mui/material";
import { pink, yellow } from "@mui/material/colors";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

// Project imports
import Modal from "../Modal/Modal";

// React suite Icons
import { Icon } from "@rsuite/icons";
import Button from "../Button/Button";

import { blue, red, orange, green, grey } from "@mui/material/colors";

const arrowsSVG = React.forwardRef((props, ref: any) => (
  <svg
    {...props}
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      className="iconPath"
      d="M89.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L370.3 160H320c-17.7 0-32 14.3-32 32s14.3 32 32 32H447.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32s-32 14.3-32 32v51.2L398.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C57.2 122 39.6 150.7 28.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM23 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V448c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L109.6 352H160c17.7 0 32-14.3 32-32s-14.3-32-32-32H32.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z"
    />
  </svg>
));

const css = `
  .iconPath{
    fill: #ffeb3b;
    animation: color 5s ease;
  }

  @keyframes color {
    0% {
      fill: green;
    }
    100% {
      fill: #ffeb3b;
    }
  }
`;

const ConfirmationModal: FC<{
  open: boolean;
  onClose: () => void;
  onConfirm: (str?: any) => void;
  onCancel?: () => void;
  message: string;
  message2?: string;
  cancelBtnTxt: string;
  submitBtnTxt: string;
  icon?: boolean;
  customIcon?: ReactNode;
  displayConfirm?: boolean;
  alternativeTitle?: string;
  errorIcon?: boolean;
  refreshIcon?: boolean;
  errorMessage?: string;
  infoMessage?: string;
  color?: string;
  element?: React.ReactNode;
  width?: string;
  disabled?: boolean;
}> = ({
  open,
  onClose,
  onConfirm,
  onCancel,
  message,
  message2,
  color,
  cancelBtnTxt,
  submitBtnTxt,
  icon,
  customIcon,
  displayConfirm = true,
  alternativeTitle,
  errorIcon,
  refreshIcon,
  errorMessage,
  infoMessage,
  element,
  width,
  disabled,
}) => {
  return (
    <>
      <style>{css}</style>
      <Modal
        width={width}
        open={open}
        onClose={onClose}
        title={alternativeTitle ? alternativeTitle : "Confirmation"}
      >
        <Box padding={4} paddingTop={0}>
          <Stack direction={"column"} gap={3.5} alignContent={"center"}>
            {customIcon && <Box alignSelf="center">{customIcon}</Box>}
            {icon && (
              <>
                {refreshIcon ? (
                  <Icon
                    as={arrowsSVG}
                    spin
                    style={{
                      fontSize: "80px",
                      alignSelf: "center",
                      marginTop: "20px",
                    }}
                  />
                ) : (
                  <ErrorOutlineOutlinedIcon
                    sx={{
                      fontSize: "100px",
                      color: errorIcon ? "red" : yellow[700],
                      alignSelf: "center",
                    }}
                  />
                )}
              </>
            )}
            <span style={{ textAlign: "center" }}>
              <div dangerouslySetInnerHTML={{ __html: message }}></div>
              {message2 && (
                <>
                  <span style={{ color: color ? color : "" }}>{message2}</span>
                  <span>?</span>
                </>
              )}
              {errorMessage && (
                <span style={{ color: "red" }}>
                  <br></br>
                  {errorMessage}
                </span>
              )}
              {infoMessage && (
                <span>
                  <br></br>
                  {infoMessage}
                </span>
              )}
            </span>
            {element}
          </Stack>
          <Stack
            marginTop={5}
            direction={"row"}
            justifyContent={"center"}
            gap={2}
          >
            <Button
              color="gray"
              onClick={onCancel || onClose}
              id="confirmation-modal-cancel-btn"
            >
              {cancelBtnTxt}
            </Button>
            {displayConfirm && (
              <Button
                color="primary"
                disabled={disabled}
                onClick={onConfirm}
                id="confirmation-modal-confirm-btn"
              >
                {submitBtnTxt}
              </Button>
            )}
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
