import React, { ChangeEventHandler, FC, ReactNode } from "react";

// Project imports
import EnhancedTable from "./EnhancedTable/EnhancedTable";
import HeadCell from "./interfaces/HeadCell.interface";

const MaterialTable: FC<{
  headCells: HeadCell<any>[];
  rows: any[];
  id: string;
  actionBtn?: () => void;
  secondActionBtn?: () => void;
  thirdActionBtn?: () => void;
  forceDisplayBtn?: boolean;
  actionBtnTitle?: string;
  secondActionBtnTitle?: string;
  thirdActionBtnTitle?: string;
  actionBtnDisabled?: boolean;
  secondActionBtnLabel?: string;
  searchbarPlaceholder?: string;
  onChangeSearchbar?: ChangeEventHandler<HTMLInputElement>;
  searchBarValue?: string;
  secondSearchBarPlaceholder?: string;
  onChangeSecondSearchbar?: ChangeEventHandler<HTMLInputElement>;
  secondSearchBarValue?: string;
  actions?: boolean;
  selectable?: boolean;
  onActionClick?: (action: string, row: any) => void;
  onSwitchChange?: (row: any, event: any) => void;
  customActions?: any[];
  hideCustomActions?: string[];
  deleteSelected?: (selected: any) => void;
  filterRadioValue?: any;
  filterRadioOptions?: any;
  filterRadioOnChange?: (value: string) => void;
  filterRadioName?: string;
  filterRadio2Value?: any;
  filterRadio2Options?: any;
  filterRadio2OnChange?: (value: string) => void;
  filterRadio2Name?: string;
  filterSelectValue?: any;
  filterSelectOptions?: any;
  filterSelectOnChange?: (value: string) => void;
  filterCheckBoxValues?: string[];
  filterCheckBoxOptions?: any[];
  filterCheckBoxOnChange?: (value: string) => void;
  filterFildSetCheckBoxName?: string;
  secondFilterSelectValue?: any;
  secondFilterSelectOptions?: any;
  secondFilterSelectOnChange?: (value: string) => void;
  thirdFilterSelectValue?: any;
  thirdFilterSelectOptions?: any;
  thirdFilterSelectOnChange?: (value: string) => void;
  fourthFilterSelectValue?: any;
  fourthFilterSelectOptions?: any;
  fourthFilterSelectOnChange?: (value: string) => void;
  fifthFilterSelectValue?: any;
  fifthFilterSelectOptions?: any;
  fifthFilterSelectOnChange?: (value: string) => void;
  firstSelectPlaceholder?: string;
  secondSelectPlaceholder?: string;
  thirdSelectPlaceholder?: string;
  fourthSelectPlaceholder?: string;
  clearBtn?: boolean;
  onClearBtn?: () => void;
  fromDate?: Date | null;
  toDate?: Date | null;
  fromDatePickerOnChange?: (date: Date | null) => void;
  toDatePickerOnChange?: (date: Date | null) => void;
  hiddenBtnIcon?: boolean;
  datePickers?: boolean;
  onRowClick?: (row: any) => void;
  removeToolBar?: boolean;
  hideAddIcon?: boolean;
  individualActions?: boolean;
  addCardAction?: (row: any) => void;
  assignUserAction?: (row: any) => void;
  disableActionBtn?: boolean;
  refreshBtnIcon?: boolean;
  dropDownButton?: boolean;
  dropDownButtonTitle?: string;
  dropDownActions?: any;
  dropDownActionClick?: (action: string) => void;
  headerElement?: ReactNode;
  actionList?: any[];
  rowsPerPageOptions?: number[];
  customRowsPerPage?: number;
  styleToolBar?: 1 | 2 | 3 | 4;
  hover?: boolean;
  reportStyle?: boolean;
  setSelectedItems?: (items: any) => void;
  selected?: readonly string[];
  onCheckClick?: (items: any) => void;
  hideMainCheck?: boolean;
  darkBg?: boolean;
  stickyHeader?: boolean;
  tableContainerHeight?: string | number;
  hideSelectedCount?: boolean;
  disableRowKey?: string;
}> = ({
  headCells,
  rows,
  id,
  actionBtn,
  secondActionBtn,
  forceDisplayBtn,
  actionBtnTitle,
  secondActionBtnTitle,
  actionBtnDisabled,
  secondActionBtnLabel,
  searchbarPlaceholder,
  onChangeSearchbar,
  searchBarValue,
  secondSearchBarPlaceholder,
  onChangeSecondSearchbar,
  secondSearchBarValue,
  actions = false,
  selectable = false,
  onActionClick,
  onSwitchChange,
  customActions,
  hideCustomActions,
  deleteSelected,
  filterRadioValue,
  filterRadioOptions,
  filterRadioOnChange,
  filterRadioName,
  filterRadio2Value,
  filterRadio2Options,
  filterRadio2OnChange,
  filterRadio2Name,
  filterCheckBoxOptions,
  filterCheckBoxValues,
  filterCheckBoxOnChange,
  filterFildSetCheckBoxName,
  filterSelectValue,
  filterSelectOptions,
  filterSelectOnChange,
  secondFilterSelectValue,
  secondFilterSelectOptions,
  secondFilterSelectOnChange,
  thirdFilterSelectValue,
  thirdFilterSelectOptions,
  thirdFilterSelectOnChange,
  fourthFilterSelectValue,
  fourthFilterSelectOptions,
  fourthFilterSelectOnChange,
  firstSelectPlaceholder,
  secondSelectPlaceholder,
  thirdSelectPlaceholder,
  fourthSelectPlaceholder,
  clearBtn,
  onClearBtn,
  hiddenBtnIcon,
  datePickers,
  fromDatePickerOnChange,
  toDatePickerOnChange,
  fromDate,
  toDate,
  onRowClick,
  removeToolBar,
  hideAddIcon,
  individualActions,
  addCardAction,
  assignUserAction,
  disableActionBtn,
  refreshBtnIcon,
  dropDownButton,
  dropDownButtonTitle,
  dropDownActions,
  dropDownActionClick,
  headerElement,
  actionList,
  rowsPerPageOptions,
  customRowsPerPage,
  fifthFilterSelectOnChange,
  fifthFilterSelectOptions,
  fifthFilterSelectValue,
  thirdActionBtn,
  thirdActionBtnTitle,
  styleToolBar = 1,
  hover = true,
  reportStyle,
  setSelectedItems,
  selected,
  onCheckClick,
  hideMainCheck,
  darkBg,
  stickyHeader,
  tableContainerHeight,
  hideSelectedCount,
  disableRowKey
}) => {
  return (
    <EnhancedTable
      headCells={headCells}
      rows={rows}
      id={id}
      onRowClick={onRowClick}
      actionBtn={actionBtn}
      secondActionBtn={secondActionBtn}
      actionBtnTitle={actionBtnTitle}
      secondActionBtnTitle={secondActionBtnTitle}
      actionBtnDisabled={actionBtnDisabled}
      secondActionBtnLabel={secondActionBtnLabel}
      forceDisplayBtn={forceDisplayBtn}
      searchbarPlaceholder={searchbarPlaceholder}
      onChangeSearchbar={onChangeSearchbar}
      searchBarValue={searchBarValue}
      secondSearchBarPlaceholder={secondSearchBarPlaceholder}
      onChangeSecondSearchbar={onChangeSecondSearchbar}
      secondSearchBarValue={secondSearchBarValue}
      actions={actions}
      selectable={selectable}
      onActionClick={onActionClick}
      onSwitchChange={onSwitchChange}
      customActions={customActions}
      hideCustomActions={hideCustomActions}
      deleteSelected={deleteSelected}
      filterRadioValue={filterRadioValue}
      filterRadioOptions={filterRadioOptions}
      filterRadioOnChange={filterRadioOnChange}
      filterRadioName={filterRadioName}
      removeToolBar={removeToolBar}
      filterSelectValue={filterSelectValue}
      filterSelectOptions={filterSelectOptions}
      filterSelectOnChange={filterSelectOnChange}
      hideAddIcon={hideAddIcon}
      secondFilterSelectValue={secondFilterSelectValue}
      secondFilterSelectOptions={secondFilterSelectOptions}
      secondFilterSelectOnChange={secondFilterSelectOnChange}
      thirdFilterSelectValue={thirdFilterSelectValue}
      thirdFilterSelectOptions={thirdFilterSelectOptions}
      thirdFilterSelectOnChange={thirdFilterSelectOnChange}
      fourthFilterSelectValue={fourthFilterSelectValue}
      fourthFilterSelectOptions={fourthFilterSelectOptions}
      fourthFilterSelectOnChange={fourthFilterSelectOnChange}
      thirdSelectPlaceholder={thirdSelectPlaceholder}
      onClearBtn={onClearBtn}
      clearBtn={clearBtn}
      hiddenBtnIcon={hiddenBtnIcon}
      datePickers={datePickers}
      fromDate={fromDate}
      toDate={toDate}
      fromDatePickerOnChange={fromDatePickerOnChange}
      toDatePickerOnChange={toDatePickerOnChange}
      individualActions={individualActions}
      addCardAction={addCardAction}
      assignUserAction={assignUserAction}
      disableActionBtn={disableActionBtn}
      refreshBtnIcon={refreshBtnIcon}
      dropDownButton={dropDownButton}
      dropDownButtonTitle={dropDownButtonTitle}
      dropDownActions={dropDownActions}
      dropDownActionClick={dropDownActionClick}
      actionList={actionList}
      rowsPerPageOptions={rowsPerPageOptions}
      customRowsPerPage={customRowsPerPage}
      fifthFilterSelectValue={fifthFilterSelectValue}
      fifthFilterSelectOptions={fifthFilterSelectOptions}
      fifthFilterSelectOnChange={fifthFilterSelectOnChange}
      thirdActionBtn={thirdActionBtn}
      thirdActionBtnTitle={thirdActionBtnTitle}
      styleToolBar={styleToolBar}
      filterRadio2Value={filterRadio2Value}
      filterRadio2Options={filterRadio2Options}
      filterRadio2OnChange={filterRadio2OnChange}
      filterRadio2Name={filterRadio2Name}
      filterCheckBoxOptions={filterCheckBoxOptions}
      filterCheckBoxValues={filterCheckBoxValues}
      filterCheckBoxOnChange={filterCheckBoxOnChange}
      filterFildSetCheckBoxName={filterFildSetCheckBoxName}
      hover={hover}
      reportStyle={reportStyle}
      setSelectedItems={setSelectedItems}
      selected={selected}
      onCheckClick={onCheckClick}
      hideMainCheck={hideMainCheck}
      darkBg={darkBg}
      stickyHeader={stickyHeader}
      tableContainerHeight={tableContainerHeight}
      hideSelectedCount={hideSelectedCount}
      disableRowKey={disableRowKey}
    />
  );
};

export default MaterialTable;
