import user from "../../interfaces/user.interface";

/**
 * Return a permission after searching for name
 * @param permissions - user state permissions array
 * @param permissionArray - string with the name of the permission
 * @returns permission object
 */
const getPermission = (
  permissions: user["permissions"],
  permissionArray: string[]
) => {
  const temp = permissions.filter((p) =>
    permissionArray
      .map((p) => p.trim().toLocaleLowerCase())
      .includes(String(p.name?.trim().toLocaleLowerCase()))
  );
  return temp;
};

// permissions.forEach((ele) => {
//   permissionArray
//     .map((p) => p.trim().toLocaleLowerCase())
//     .includes(String(ele.name?.trim().toLocaleLowerCase()));
// });

export default getPermission;
