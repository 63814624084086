const reports = {
  users: {
    table: {
      users: "User",
      login: "Login",
      accessLevel: "Access Level",
      departament: "Departament",
      activationDate: "Activation Date",
      lastLogin: "Last Login",
      status: "Status",
    },
    search: "User / Login",
    refreshButton: "Search",
    resetButton: "Reset",
    exportButton: "Export",
    dateFilter: {
      activation: "Activation date",
      lastLogin: "Last login",
      notLogged: "Not logged within",
    },
    statusFilter: {
      all: "All Status",
      active: "Active",
      inactive: "Inactive",
    },
    allAccesslevel: "All Access level",
    allDepartments: "All Departments",
  },
  cards: {
    table: {
      card: "Credential",
      format: "Format",
      username: "Username",
      activationDate: "Activation Date",
      lastUsed: "Last Used",
      status: "Status",
      assigned: "Assigned",
      stolen: "Stolen",
      lost: "Lost",
      lockdown: "Lockdown",
      usedForAccess: "Used for Access",
    },
    cardSearch: "Credential",
    usernameSearch: "User",
    cardStatusRadio: "Credential Status",
    cardOptionsRadio: "Credential Options",
    allFormats: "All Formats",
    cardStatusFilter: {
      all: "All",
      active: "Active",
      inactive: "Inactive",
    },
    cardAssignedFilter: {
      all: "All",
      yes: "Yes",
      no: "No",
    },
    dateFilter: {
      activation: "Activation Date",
      lastUsed: "Last Used",
      notUsed: "Not Used In",
    },
  },
  events: {
    table: {
      date_created: "Date created",
      controller: "COntroller",
      event_class: "Event class",
      object_type: "Object type",
      event_type: "Event type",
      description: "Description",
      access: "Access",
    },
    report: "Report",
    all_users: "All Users",
    search: "General search",
    all: "All",
    access: "Access",
    security: "Security",
    alarms: "Alarms",
    troubles: "Troubles",
    lockdown: "Lockdown",
    others: "Others",
    cloud: "Activity data",
    remove_report_msg: "Are you sure that you want to remove the report?",
    save_report: "Save report",
    save_report_msg: "Fill out the name of the report and save it",
    name_report: "Name - Report",
    event_class: "Event class",
    event_type: "Event type",
    object: "Object",
    object_type: "Object type",
    print_all_description: "Print all description",
    date: "Date",
    from: "From",
    to: "To",
  },
};

export default reports;
