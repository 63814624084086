import * as actions from '../actions/controller-readers';
import { AnyAction } from "@reduxjs/toolkit";

let initialState = {
    readers: []
}

export const controllerReadersReducer = (state = initialState as { readers:any | undefined }, action:AnyAction) => {
    switch (action.type) {

        // Set reades list of controller
        case actions.SET_READERS:
            return {
                ...state,
                readers: [...action.payload]
            }
        
        // Update reader list"
        case actions.UPDATE_READER:
            const updateReaders = state.readers.map((reader:any) => {
                return reader.serial_number === action.payload.serial_number ? action.payload : reader
            });

            return {
                ...state,
                readers: updateReaders
            }
        default: 
            return state;
    }
}