const acceslevel = {
  accesslevel: "Access Level",
  search_accesslevel: "Search Access Level",
  add_accesslevel: "Add Access Level",
  update_accesslevel: "Update Access Level",
  accesslevel_manager: "Manage",
  doors: "Doors",
  users: "Users",
  schedules: "Schedules",
  can_not_delete: "The access level is protected. It can't be deleted",
  extend_time: "Extend time",
  override_inter: "Override Interlocks",
  form: {
    name: "Name",
    ocurrence: "Occurrence",
    month: "Month",
    day: "Day",
    occurss: "Occurs",
    year: "Year",
    duration_days: "Duration Days",
    hours: "Hours",
    minutes: "Minutes",
    status: "Status",
    active: "Active",
    inactive: "Inactive",
  },
  table: {
    state: "STATE",
    accesslevel: "ACCESS LEVEL",
    door: "DOOR",
    schedules: "SCHEDULE",
  },
  manager: {
    search_users: "Search Users",
    user_count: "User count",
    back_to_list: "Back to list",
    add_users: "Assign users",
    users_remove_success: "Users unassigned successfully",
    users_remove_error: "Error unassigning users",
    users_add_success: "Users assigned successfully",
    users_add_error: "Error assigning users",
    remove_selected_btn: "Unassign ({{count}}) selected",
    remove_selected_msg:
      "Are you sure you want to unassign the following user(s) from {{accessLevelLabel}}?",
    add_selected_btn: "Assign ({{count}}) selected",
    add_selected_msg:
      "Are you sure you want to assign the following user(s) to {{accessLevelLabel}}?",
    replace: "Replace",
    replace_selected_btn: "Replace ({{count}}) selected",
    replace_success: "Replace access level successfull",
    replace_error: "Error replacing access level",
    replace_alevel_msg: "Are you sure you want to replace the following user(s) from {{accessLevelLabel}} to {{newAccessLevelLabel}}?",
    count_users_selected: "{{count}} users selected",
    users_with_errors: "The access level cannot be assigned to the following user(s):",
    alert: 'Alert',
  },
};

export default acceslevel;
