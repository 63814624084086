const routes = {
  role_list: "/roles/list",
  role_view: "/roles/view/:role",
  role_menu: "/roles/menu/:menu",
  role_permission: "/roles/permission/:permission",
  user_list: "/users/list",
  tasks_list: "/tasks/list",
  dealer_request_list: "/dealerRequest/list",
  dealer_request_view: "/dealerRequest/view/:_dealer",
  // Dealers
  dealer_list: "/dealer/list",
  dealer_create: "/dealer/create",
  dealer_view: "/dealer/view/:_dealer",
  dealer_edit: "/dealer/edit/:_dealer",
  dealer_activate: "/dealer/activate/:_dealer",
  // DealerInfo
  dealer_info_view: "/dealerInfo/view",
  dealer_info_edit: "/dealerInfo/edit",
  // Resources
  resources_list: "/resources/list",
  resources_create: "/resources/create",
  resources_view: "/resources/view/:_resource",
  resources_edit: "/resources/edit/:_resource",
  // Organizations
  organization_list: "/organization/list",
  organization_create: "/organization/create",
  organization_view: "/organization/view/:_organization",
  organization_info: "/organization_info",
  // Controllers
  controller_list: "/controller/list",
  // Sites
  sites_list: "/site/list",
  site_dashboard: "/site/dashboard",
  site_info: "/site/info",
  site_notifications: "/site/notifications",
  //Door
  door_list: "/door/list",
  // Users Organization
  user_org_list: "/user_org/list",
  user_org_view: "/user_org/view/:_user_org",
  employee_org_view: "/employee/view/:_employee",
  // Holiday
  holiday_list: "/holiday/list",
  // Schedules
  schedule_list: "/schedule/list",
  // Access level
  access_level: "/access_level/list",
  access_level_manager: "/access_level/manager",
  // Cards
  card_list: "/card/list",
  // Events
  event_list: "/event/list",
  // Organization profile
  organization_profile: "/organization/profile",
  // Lockdown
  lockdown: "/lockdown",
  // Others
  permission_list: "/permissions/list",
  menu_list: "/menus/list",
  home: "/home",
  testing_tool: "testingtool",
  profile: "profile",
  change_password: "change_password",
  pricing: "/pricing",
  // input
  input_list: "/input/list",
  output_list: "/output/list",
  // Reports
  report_users: "/report/users",
  report_cards: "/report/cards",
  report_events: "/report/events",
  // Firmware
  firmware_list: "/firmware/list",
  firmware_manager: "/firmware_manager",
  // Global settings
  global_settings: "/global_settings",
  // Floor plan
  floor_plan: "/floor_plan",
  add_floor_plan: "/floor_plan/add/:_site",
  floor_plan_view: "/floor_plan/view/:_site/:_floor_plan",
  // Interlock
  interlocks_list: "/interlock/list",
};

export default routes;
