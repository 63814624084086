const cards = {
  cards: "Credenciales",
  search_cards: "Buscar Credencial/Nombre para mostrar",
  add_card: "Agregar credencial",
  add_mobile_pass: "Añadir Pase Móvil",
  update_card: "Actualizar credencial",
  numbers: "Números",
  options: "Opciones",
  general_information: "Información general",
  assign_user: "Asignar Usuario",
  assigned_user: "Usuario asignado",
  assign_msg1: "¿Quieres asignar la credencial?",
  assign_msg2: "a",
  assign_reader1: "¿Quieres escanear con lector?",
  assign_reader2: "del sitio",
  unassign_user_msg1: "¿Quieres desasignar?",
  unassign_user_msg2: "de la credencial",
  delete_card_multiple_sites:
    "NOTA: Esta credencial está asociada con más de un sitio, se eliminará de todos ellos.",
  readers: "Lectores",
  custom_number: "Número personalizado",
  scan_card: "Escanear credencial",
  search_devices: "Buscar Dispositivos",
  scan_status: "Estado de escaneo",
  start_scan_msg:
    "Se creará una credencial, ¿desea continuar con el proceso de escaneo?",
  registration_copied: "Código de registro copiado en portapapeles",
  send_to: "Enviar a",
  send_another: "Enviar a otro",
  enrolled: "Inscrita",
  re_enroll: "Re-inscribir",
  available: "Disponible",
  discover: "Descubrir",
  use_kprog: "Usar KPROG",
  kprog: "KPROG",
  table: {
    card: "CREDENCIALES",
    format: "FORMATO",
    name: "NOMBRE PARA MOSTRAR",
    state: "ESTADO",
    activation_date: "FECHA DE ACTIVACION",
    assigned: "ASIGNADO",
    stolen: "ROBADO",
    lost: "PERDIDO",
    user_name: "NOMBRE",
    department: "DEPARTAMENTO",
    reader: "LECTOR",
    site: "SITIO",
  },
  form: {
    individual: "Individual",
    group: "Grupo",
    display_name: "Nombre para mostrar",
    facility_code: "Código de instalación",
    card_code: "Código de tarjeta",
    activation_date: "Fecha de activacion",
    expiry_date: "Fecha de caducidad",
    card_format: "Formato de credencial",
    card_number_hex: "Número de credencial hexadecimal",
    all: "Todo",
    assigned: "Asignado",
    unassigned: "Sin asignar",
    used_for_access: "Usado para el acceso",
    stolen: "Robado",
    lost: "Perdido",
    status: "Estado",
    active: "Activo",
    programming_card: "Credencial de Programación",
  },
  error: {
    invalid_email: "Formato de correo inválido",
    mobilePassLicExced:
      "Lo sentimos, pero actualmente no hay licencias disponibles para Mobile Pass.",
  },
  pin_option: {
    not_used: "No utilizado",
    start_lockdown: "Iniciar bloqueo",
    stop_lockdLock: "Detener el bloqueo",
    lockdown_grant: "El bloqueo otorga acceso",
    area_secured: "Mantener la puerta segura (mantener el bloqueo)",
  },
};

export default cards;
