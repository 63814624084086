import user from "../../interfaces/user.interface";
import actionArray from "./actionArray";
import getPermission from "./getPermission";

// Returns an array of the actions of a permission
const getActionArray = (
  permissions: user["permissions"],
  permissionArray: string[]
) => {
  const permission: any = getPermission(permissions, permissionArray);
  return permission ? actionArray(permission) : [];
};

export default getActionArray;
