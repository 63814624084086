const users = {
  header: "Users List",
  users: "Users",
  credentials: "Credentials",
  view_roles: "View Roles Details",
  edit_user: "Edit User",
  user_details: "User Details",
  add_user: "Add User",
  search_user: "Search User",
  delete_user_message:
    "Are you sure you want to delete {{user}}? <br> If the user has associated credentials, these will also be unassigned.",
  delete_morethan_one_site:
    "NOTE: This user is associated with more than one site, it will be deleted from all of them.",
  selected_users: "the selected users",
  confirm_modal_cancel: "No, cancel",
  confirm_modal_confirm: "Yes, delete",
  remove_login: "Remove Login",
  create_login: "Create Login",
  all_doors: "All doors",
  select_doors: "Select doors",
  remove_confirmation_msg: "Are you sure you want to remove the user login?",
  activate_confirm_msg: "Are you sure you want to {{action}} {{user}}",
  role_confirm_msg: "Are you sure you want to set the role {{role}}?",
  access_levels: "Access levels",
  set_keypad: "Set Keypad Code",
  has_keypad: "Has Keypad",
  no_keypad: "No Keypad",
  remove_keypad: "Remove Keypad Code",
  remove_keypad_msg:
    "Are you sure you want to remove the keypad code assigned to {{user}}",
  pending_changes:
    "There are pending changes to save. Are you sure you want to leave the page?",
  no_custom_fields: "There are no custom fields configured for this site.",
  remove_last_site_note:
    "NOTE: Removing access to this site will also remove the user from the entire account.",
  remove_last_site_with_access_note:
    "NOTE: You do not have access to the remaining sites of this user. Removing access to this site will also remove the user from your user list.",
  change_site_status: "Are you sure you want to change this user's access to {{site}} to {{status}}?",
  site_access: "Site Access",
  table: {
    first_name: "FIRST NAME",
    last_name: "LAST NAME",
    email: "EMAIL",
    user: "USER",
    role: "ROLE",
    phone: "PHONE",
    username: "USERNAME",
    last_login: "LAST LOGIN",
    organization_name: "ACCOUNT",
    site_name: "NAME",
    controller_type_name: "CONTROLLER TYPE",
    serial_number: "SERIAL NUMBER",
    ip: "IP ADDRESS",
    web_port: "PORT",
    status: "STATUS",
    firmware_version: "FIRMWARE VERSION",
    company: "COMPANY",
    name: "NAME",
    website: " WEBSITE",
    country: "COUNTRY",
    timezone: "TIMEZONE",
    address: "ADDRESS",
    access_level: "ACCESS LEVEL",
    activation_date: "ACTIVATION DATE",
    expiry_date: "EXPIRY DATE",
    act_exp_date: "ACTIVATION / EXPIRATION DATE",
    keypad_code: "KEYPAD CODE",
    organization_company_name: "ORGANIZATION",
    dealer_company_name: "DEALER",
  },
  form: {
    username: "Username",
    email: "Email",
    phone_number: "Phone Number",
    date_created: "Date Created",
    date_updated: "Date Updated",
    first_name: "First Name",
    last_name: "Last Name",
    new_password: "New Password",
    role: "Role",
    select_role: "Select Role",
  },
  profile: {
    username: "Username",
    full_name: "Full Name",
    last_login: "Last Login",
    actions: "Actions",
    location: "Locations",
    device: "Device",
    ip_address: "IP Address",
    date_time: "Date/Time",
    edit_profile: "Edit Profile",
    profile: "Profile Details",
    cancel: "Cancel",
    update: "Update",
    login: "Login",
    activities: "Activities",
    refresh: "Refresh",
    change_email: "Change Email",
    send_code: "Send Code",
    new_email: "New email",
    confirm_email: "Confirm email",
    change_email_description:
      "Please click send code to receive a confirmation code",
    code: "Code",
    code_expiration: "The code expires in 10 minutes",
    send_new_code: "Send new code",
    change: "Change",
    user_updated: "User Profile has been updated",
    allowed_filetypes: "Allowed file types: png, jpg, jpeg.",
    change_avatar: "Change avatar",
    remove_avatar: "Remove avatar",
    avatar_modal_description: "Are you sure you want to remove your avatar?",
  },
  add: {
    user_information: "User information",
    security: "Security",
    login: "Login",
    first_name: "First Name",
    last_name: "Last Name",
    department: "Department",
    country: "Country",
    city: "City",
    state: "State",
    zipcode: "Zipcode",
    address: "Address",
    phone: "Phone",
    mobile: "Mobile",
    email: "Email",
    create_new_department: "Create new department",
    card: "Credential",
    assign_card: "Assign credential",
    successfully_created: "successfully created",
    could_not_create_department: "Could not create department",
    none: "None",
    create_login: "Create Login",
    site: "Site",
  },
  security: {
    allow_extend_time: "Allow Extend Time",
    override_anti_passback: "Override Anti-Passback",
    can_arm: "Can Arm",
    master_attribute_card_enrollment_mode:
      "Master Attribute - Credential Enrollment Mode",
    overrides_interlock: "Overrides Interlock",
    can_disarm: "Can Disarm",
    activation_date: "Activation Date",
    expiration_date: "Expiration Date",
    keypad_code: "Keypad Code",
    confirm_code: "Confirm Code",
    access_level: "Access Level",
    site: "Site",
    all: "All",
    no_access_levels: "No Access Levels",
    door: "Door",
    schedule: "Schedule",
    options: "Options",
  },
  login: {
    create_login: "Create login",
    update_login: "Update login",
    username: "Username",
    last_login: "Last login",
    status: "Status",
    access_to_all_sites: "Access to all sites",
    select_sites: "Select sites",
    success: "User has been created",
    error: "Could not create user",
  },
  card: {
    assign_a_card: "Assign a credential",
    edit_card: "Edit credential",
    credential: "Credential",
    due_date: "Due date",
    no_due_date: "No due date",
    assign: "Assign",
    unassign: "Unassign",
    activation_date: "Activation date",
    expiry_date: "Expiry date",
    card_status: "Status",
    actions: "Actions",
    active: "Active",
    inactive: "Inactive",
    lost: "Lost",
    stolen: "Stolen",
    unassign_confirmation: "Are you sure you want to unassign this credential",
    to: "to",
    format: "Format",
    card_number: "Credential number",
    assign_card_confirm:
      "Do you want to assign Credential {{number}} to {{username}}?",
    confirm_btn: "Confirm",
    cancel_btn: "Cancel",
    display_name: "Display name",
  },
  delete: {
    success: "The user {{name}} has been deleted successfully",
    error: "Could not delete",
  },
  error: {
    invalid_email: "Invalid email format",
    invalid_date: "Invalid date format",
    email_no_match: "Email does not match",
    update_failed: "Update Failed",
  },
  events: {
    tabTitle: "Events",
  },
  keypad: {
    error: "Error setting keypad code",
    success: "Keypad code saved sucessfully",
    remove_confirm: "Are you sure you want to remove the keypad code?",
    remove_success: "Keypad code removed successfully",
    remove_error: "Error removing keypad code",
    keypad_length: "{{length}} characters required",
    keypad_var_length:
      "Minimum {{minimum}} / Maximum {{maximum}} characters long",
    code_mismatch: "The keypad code must match",
    custom: "Custom keypad code",
    generate_new: "Generate a new keypad code",
    ask: "Do you want to set the keypad code for the site {{site}}?",
  },
};

export default users;
