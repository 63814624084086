const holidays = {
  holidays: "Holidays",
  search_holiday: "Search Holiday",
  add_holiday: "Add Holiday",
  update_holiday: "Update Holiday",
  modal_general: "General",
  modal_events: "Events",
  form: {
    name: "Name",
    ocurrence: "Ocurrence",
    month: "Month",
    day: "Day",
    occurss: "Occurss",
    year: "Year",
    duration_days: "Duration Days",
    hours: "Hours",
    minutes: "Minutes",
  },
  table: {
    holiday: "HOLIDAY",
    state: "STATE",
    date: "DATE",
    duration: "DURATION",
    is_yearly: "IS YEARLY",
    is_relative: "IS RELATIVE DATE",
    relative_week_day: "RELATIVE WEEK DAY",
  },
};

export default holidays;
