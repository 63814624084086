const acceslevel = {
  accesslevel: "Niveau d'accès",
  search_accesslevel: "Niveau d'accès à la recherche",
  add_accesslevel: "Ajouter un niveau d'accès",
  update_accesslevel: "Mettre à jour le niveau d'accès",
  accesslevel_manager: "Gérer",
  doors: "Des portes",
  users: "Utilisateurs",
  schedules: "Des horaires",
  can_not_delete: "Le niveau d'accès est protégé. Il ne peut pas être supprimé",
  extend_time: "Prolonger le temps",
  override_inter: "Annuler les verrouillages",
  form: {
    name: "Nom",
    ocurrence: "Occurrence",
    month: "Mois",
    day: "Jour",
    occurss: "Se produire",
    year: "An",
    duration_days: "Durée Jours",
    hours: "Les heures",
    minutes: "Minutes",
    status: "Statut",
    active: "Actif",
    inactive: "Inactif",
  },
  table: {
    state: "ETAT",
    accesslevel: `NIVEAU D'ACCÈS`,
    door: "DOOR",
    schedules: "PROGRAMME",
  },
  manager: {
    search_users: "Rechercher des utilisateurs",
    user_count: "Nombre d'utilisateurs",
    back_to_list: "Retour à la liste",
    add_users: "Assigner des utilisateurs",
    users_remove_success: "Utilisateurs désassignés avec succès",
    users_remove_error: "Erreur lors de la désassignation des utilisateurs",
    users_add_success: "Utilisateurs assignés avec succès",
    users_add_error: "Erreur lors de l'assignation des utilisateurs",
    remove_selected_btn: "Désassigner ({{count}}) sélectionnés",
    remove_selected_msg:
      "Êtes-vous sûr de vouloir désassigner les utilisateurs suivants de {{accessLevelLabel}} ?",
    add_selected_btn: "Assigner ({{count}}) sélectionnés",
    add_selected_msg:
      "Êtes-vous sûr de vouloir assigner les utilisateurs suivants à {{accessLevelLabel}} ?",
    replace: "Remplacer",
    replace_selected_btn: "Remplacer ({{count}}) sélectionnés",
    replace_success: "Remplacement du niveau d'accès réussi",
    replace_error: "Erreur lors du remplacement du niveau d'accès",
    replace_alevel_msg:
      "Êtes-vous sûr de vouloir remplacer les utilisateurs suivants de {{accessLevelLabel}} par {{newAccessLevelLabel}} ?",
    count_users_selected: "{{count}} utilisateurs sélectionnés",
    users_with_errors:
      "Le niveau d'accès ne peut pas être assigné aux utilisateurs suivants :",
    alert: 'Alerte',
  },
};

export default acceslevel;
