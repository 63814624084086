const firmware = {
  header: "Micrologiciel",
  add_firmware: "Ajouter un micrologiciel",
  edit_firmware: "Modifier le micrologiciel",
  product_code: "Code produit",
  version: "Version",
  mandatory: "Obligatoire",
  launch: "Lancement",
  launched: "Lancé",
  not_launched: "Non lancé",
  notes: "Notes de publication",
  add_success_msg: "Micrologiciel ajouté avec succès",
  update_success_msg: "Micrologiciel mis à jour avec succès",
  delete_msg:
    "Êtes-vous sûr de vouloir supprimer le micrologiciel sélectionné ? {{firmware}}",
  firmware_deleted: "Micrologiciel supprimé",
  update_all: "Mettre à jour tous les contrôleurs",
  update_firmware: "Mettre à jour le micrologiciel",
  upload_firmware: "Télécharger le micrologiciel",
  install_firmware: "Installer le micrologiciel",
  select_firmware_msg:
    "Sélectionnez le micrologiciel à installer et appuyez sur Continuer",
  confirm_update_msg:
    "Êtes-vous sûr de vouloir installer la version {{version}} du micrologiciel sur le contrôleur {{controller}} ?",
  confirm_update_r_msg:
    "Êtes-vous sûr de vouloir installer la version {{version}} du micrologiciel sur le lecteur {{reader}} ?",
  confirm_updateR_msg:
    "Êtes-vous sûr de vouloir mettre à jour le micrologiciel du lecteur {{reader}}",
  firmware_error_title:
    "Erreur du Micrologiciel",
    table: {
    date: "Date",
    controller: "Contrôleur",
    reader: "Lecteur",
    release: "Note de publication",
    product_code: "Code produit",
    version: "Version",
    status: "Statut",
    launched: "Lancé",
    mandatory: "Obligatoire",
  },
};

export default firmware;
