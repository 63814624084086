/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC, useEffect, useState } from "react";
import {
  Routes,
  Route,
  BrowserRouter,
  Navigate,
  useRoutes,
  useLocation,
} from "react-router-dom";
import { PrivateRoutes } from "./PrivateRoutes";
import App from "../App";
import Users from "../views/Users/Users";
// Store
import { useSelector, useDispatch } from "../store";
import { removeUser, setUser } from "../store/actions/user";
// Interfaces
import user from "../interfaces/user.interface";
// Routes
import SplashScreen from "../components/SplashScreen/SplashScreen";
import LoginScreen from "../views/LoginScreen/LoginScreen";
import { PublicRoutes } from "./PublicRoutes";
// import KProg from "../views/KProg/KProg";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

const AppRoutes: FC = () => {
  const dispatch = useDispatch();

  const location = useLocation();

  const user: user = useSelector((state) => state.user.user);

  const [loading, setLoading] = useState(true);

  const localStorageUser = JSON.parse(localStorage.getItem("USER") as string);

  // useEffect for setting user's LocalStorage data in Redux store
  useEffect(() => {
    if (localStorageUser && !user) {
      dispatch(setUser(localStorageUser?.data));
      location.pathname.includes("activate_account") && dispatch(removeUser());
    }
    setLoading(false);
  }, []);

  return (
    <>
      {/* <KProg /> */}
      {loading ? (
        <SplashScreen />
      ) : (
        <Routes>
          {user ? (
            <Route path="/*" element={<PrivateRoutes />} />
          ) : (
            <>
              <Route path="/*" element={<PublicRoutes />} />
            </>
          )}
        </Routes>
      )}
    </>
  );
};

export default AppRoutes;
