const floorplan = {
    floorplan: "Plan d'étage",
    add_floorplan: "Ajouter un plan d'étage",
    upload_floorplan: "Télécharger le plan d'étage",
    remove_floorplan: "Supprimer le plan d'étage",
    search_floorplan: "Rechercher le plan d'étage",
    add_door_input: "Ajouter des portes / entrées",
    add_selected: "Ajouter tous les éléments sélectionnés",
    live_view: "Vue en direct",
    delete_image_msg: "Êtes-vous sûr de vouloir supprimer l'image du plan d'étage ?",
    last_activity: "Dernière Activité",
    custom: "Personnalisé",
    form: {
      available_allusers: "Disponible pour tous les utilisateurs",
      ask_password_every: "Demander le mot de passe à chaque fois",
      small_icons: "Petites icônes",
      doors: "Portes",
      inputs: "Entrées",
    },
    modal: {
      title: "Temps Personnalisé",
      minutes: "Minutes",
      hours: "Heures",
      description_password: "Pour continuer, entrez votre mot de passe.",
      password: "Mot de Passe",
      failed_attempts: "Intentions Erronées"
    }
  };
  
  export default floorplan;
  