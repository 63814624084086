import tableActions from "../../interfaces/tableActions.interface";

/**
 * Filter the array of action by active tag and if it does mot have a tag param
 * @param permissions
 * @param customActions
 * @returns filtered array by missign tag param or action with active tag
 */
const disableOptionByValue = (customActions: tableActions[], row: any) =>
  customActions
    .filter((action) =>
      !action.onDisplay
        ? action
        : row[action.onDisplay[0]] === action.onDisplay[1]
    )
    .filter((action) =>
      !action.onHide ? action : row[action.onHide[0]] !== action.onHide[1]
    );

export default disableOptionByValue;
