/**
 * Check if an array of permission has a tag and the status
 * @param actions {Array<any>}
 * @param tag {string}
 * @returns
 */
const getTag = (actions: any[], tags: string[]): boolean => {
  const action = actions.find((ele) =>
    tags
      .map((tag) => tag.trim().toLocaleLowerCase())
      .includes(ele.tag.trim().toLocaleLowerCase())
  );
  return action ? action.active : false;
};

export default getTag;
