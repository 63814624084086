import { lazy, FC, Suspense, ReactNode } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
// Store
import { useSelector } from "../store";
// Interfaces
import user from "../interfaces/user.interface";
// Material
import SplashScreen from "../components/SplashScreen/SplashScreen";
// Project imports
import MasterLayout from "../layout/MasterLayout/MasterLayout";
import routes from "./routes";
import ViewEmployee from "../views/DealerInfo/ViewDealer/ViewEmployee/ViewEmployee";

const defaultRoute = (user: user) => {
  switch (user.permissions[0].name) {
    default:
      return routes.home;
  }
};

const PrivateRoutes = () => {
  const user: user = useSelector((state) => state.user.user);

  const HomeView = lazy(() => import("../views/Home/Home"));
  const UsersView = lazy(() => import("../views/Users/Users"));
  const UsersOrgView = lazy(
    () => import("../views/OrganizationAdmin/Users/ViewUser/ViewUser")
  );
  const UsersByRole = lazy(
    () => import("../views/RolesList/UsersByRole/UsersByRole")
  );
  const RolesListView = lazy(() => import("../views/RolesList/RolesList"));
  // Dealers
  const DealersView = lazy(() => import("../views/Dealers/Dealers"));
  const CreateDealersView = lazy(
    () => import("../views/Dealers/AddDealerModal/AddDealerModal")
  );
  const DealersInfoView = lazy(
    () => import("../views/Dealers/ViewDealer/ViewDealer")
  );
  const DealersActivateView = lazy(
    () => import("../views/Dealers/Activate/Activate")
  );
  const ProfileDealerInfoView = lazy(
    () => import("../views/DealerInfo/ViewDealer/ViewDealer")
  );
  const DealerRequests = lazy(
    () => import("../views/DealerRequest/DealerRequest")
  );
  const ViewDealerRequest = lazy(
    () => import("../views/DealerRequest/ViewDealerRequest/ViewDealerRequest")
  );
  // Resources
  const ResourcesView = lazy(() => import("../views/Resources/Resources"));
  const CreateResourceView = lazy(
    () => import("../views/Resources/CreateResource/CreateResource")
  );
  const PreviewResourceView = lazy(
    () => import("../views/Resources/PreviewResource/PreviewResource")
  );
  const EditResourceView = lazy(
    () => import("../views/Resources/EditResource/EditResource")
  );

  // Organization
  const OrganizationsView = lazy(
    () => import("../views/Organizations/Organizations")
  );
  const OrganizationsInfoView = lazy(
    () => import("../views/Organizations/ViewOrganization/ViewOrganization")
  );
  const OrganizationsTabsView = lazy(
    () => import("../views/Organizations/CreateTabs/CreateTabs")
  );
  //
  const PermissionsView = lazy(
    () => import("../views/PermissionsList/PermissionsList")
  );
  const MenusView = lazy(() => import("../views/MenuList/MenuList"));
  const RolesByView = lazy(() => import("../views/RolesList/RolesBy/RolesBy"));
  const TestingtoolView = lazy(
    () => import("../views/TestingTool/TestingTool")
  );
  // Admin user
  const OrganizationAdminView = lazy(
    () => import("../views/OrganizationAdmin/OrganizationAdmin")
  );

  // Settings
  const ProfileView = lazy(() => import("../views/Profile/Profile"));
  const ChangePasswordView = lazy(
    () => import("../views/ChangePassword/ChangePassword")
  );

  // Tasks
  const TasksView = lazy(() => import("../views/ControllerTasks/Tasks"));

  // Pricing
  const PricingView = lazy(() => import("../views/Pricing/Pricing"));

  // Firmware
  const FirmwareView = lazy(() => import("../views/Firmware/Firmware"));

  // Add floor plan
  const AddFloorplanView = lazy(
    () =>
      import("../views/OrganizationAdmin/FloorPlan/AddFloorPlan/AddFloorPlan")
  );

  const canAcces = (security: string): boolean => {
    return user.permissions.some((ele) => ele.name === security);
  };
  const renderPaths = (paths: string[], ele: ReactNode) =>
    paths.map((path) => (
      <Route
        key={path}
        path={path}
        element={<Suspense fallback={<SplashScreen />}>{ele}</Suspense>}
      />
    ));

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Lazy Modules */}
        {/* Security */}
        {/* {canAcces("role") && ( */}
        <>
          <Route
            path={routes.role_list}
            element={
              <Suspense fallback={<SplashScreen />}>
                <RolesListView />
              </Suspense>
            }
          />
          <Route
            path={routes.role_view}
            element={
              <Suspense fallback={<SplashScreen />}>
                <UsersByRole />
              </Suspense>
            }
          />
        </>
        {/* )}
        {canAcces("user") && ( */}
        <>
          <Route
            path={routes.user_list}
            element={
              <Suspense fallback={<SplashScreen />}>
                <UsersView />
              </Suspense>
            }
          />
        </>
        {/* )} */}
        {/* Dealer */}
        {/* {canAcces("dealer") && canAcces("system") && ( */}
        <>
          <Route
            path={routes.dealer_list}
            element={
              <Suspense fallback={<SplashScreen />}>
                <DealersView />
              </Suspense>
            }
          />
          <Route
            path={routes.dealer_create}
            element={
              <Suspense fallback={<SplashScreen />}>
                <CreateDealersView />
              </Suspense>
            }
          />
          <Route
            path={routes.dealer_activate}
            element={
              <Suspense fallback={<SplashScreen />}>
                <DealersActivateView />
              </Suspense>
            }
          />
          <Route
            path={routes.dealer_view}
            element={
              <Suspense fallback={<SplashScreen />}>
                <DealersInfoView />
              </Suspense>
            }
          />
          <Route
            path={routes.dealer_request_list}
            element={
              <Suspense fallback={<SplashScreen />}>
                <DealerRequests />
              </Suspense>
            }
          />
          <Route
            path={routes.dealer_request_view}
            element={
              <Suspense fallback={<SplashScreen />}>
                <ViewDealerRequest />
              </Suspense>
            }
          />
        </>
        {/* )} */}
        {/* Permissions */}
        {/* {canAcces("permission") && ( */}
        <>
          <Route
            path={routes.permission_list}
            element={
              <Suspense fallback={<SplashScreen />}>
                <PermissionsView />
              </Suspense>
            }
          />
          <Route
            path={routes.role_permission}
            element={
              <Suspense fallback={<SplashScreen />}>
                <RolesByView />
              </Suspense>
            }
          />
        </>
        {/* )} */}
        {/* Menus */}
        {/* {canAcces("menu") && ( */}
        <>
          <Route
            path={routes.menu_list}
            element={
              <Suspense fallback={<SplashScreen />}>
                <MenusView />
              </Suspense>
            }
          />
          <Route
            path={routes.role_menu}
            element={
              <Suspense fallback={<SplashScreen />}>
                <RolesByView />
              </Suspense>
            }
          />
        </>
        {/* )} */}
        {/* Dealer
        {canAcces("resources") && (
          <>
            <Route
              path={routes.resources_list}
              element={
                <Suspense fallback={<SplashScreen />}>
                  <DealersView />
                </Suspense>
              }
            />
            <Route
              path={routes.resources_create}
              element={
                <Suspense fallback={<SplashScreen />}>
                  <CreateDealersView />
                </Suspense>
              }
            />
            <Route
              path={routes.resources_edit}
              element={
                <Suspense fallback={<SplashScreen />}>
                  <DealersActivateView />
                </Suspense>
              }
            />
            <Route
              path={routes.dealer_view}
              element={
                <Suspense fallback={<SplashScreen />}>
                  <DealersInfoView />
                </Suspense>
              }
            />
          </>
        )} */}
        {/* Controller Tasks */}
        {
          <>
            <Route
              path={routes.tasks_list}
              element={
                <Suspense fallback={<SplashScreen />}>
                  <TasksView />
                </Suspense>
              }
            />
          </>
        }
        {/* {
          <>
            <Route
              path={routes.user_org_view}
              element={
                <Suspense fallback={<SplashScreen />}>
                  <UsersOrgView />
                </Suspense>
              }
            />
          </>
        } */}
        {/* Organizations */}
        <>
          {renderPaths(
            [
              routes.sites_list,
              routes.site_dashboard,
              routes.site_notifications,
              routes.site_info,
              routes.controller_list,
              routes.door_list,
              routes.user_org_list,
              routes.user_org_view,
              routes.holiday_list,
              routes.schedule_list,
              routes.access_level,
              routes.access_level_manager,
              routes.card_list,
              routes.event_list,
              routes.organization_profile,
              routes.lockdown,
              routes.input_list,
              routes.output_list,
              routes.report_users,
              routes.report_cards,
              routes.report_events,
              routes.organization_info,
              routes.firmware_manager,
              routes.global_settings,
              routes.floor_plan,
              routes.floor_plan_view,
              routes.add_floor_plan,
              routes.interlocks_list,
            ],
            <OrganizationAdminView />
          )}
          <Route
            path={routes.organization_list}
            element={
              <Suspense fallback={<SplashScreen />}>
                <OrganizationsView />
              </Suspense>
            }
          />
          <Route
            path={routes.organization_create}
            element={
              <Suspense fallback={<SplashScreen />}>
                <OrganizationsTabsView />
              </Suspense>
            }
          />
          <Route
            path={routes.organization_view}
            element={
              <Suspense fallback={<SplashScreen />}>
                <OrganizationsInfoView />
              </Suspense>
            }
          />
        </>
        {/* Testing tool */}
        <Route
          path={routes.sites_list}
          element={
            <Suspense fallback={<SplashScreen />}>
              <OrganizationAdminView />
            </Suspense>
          }
        />
        {/* Testing tool */}
        <Route
          path={routes.testing_tool}
          element={
            <Suspense fallback={<SplashScreen />}>
              <TestingtoolView />
            </Suspense>
          }
        />
        {/* Profile */}
        <Route
          path={routes.profile}
          element={
            <Suspense fallback={<SplashScreen />}>
              <ProfileView />
            </Suspense>
          }
        />
        {/* DealerInfo */}
        <Route
          path={routes.dealer_info_view}
          element={
            <Suspense fallback={<SplashScreen />}>
              <ProfileDealerInfoView />
            </Suspense>
          }
        />
        <Route
          path={routes.employee_org_view}
          element={
            <Suspense fallback={<SplashScreen />}>
              <ViewEmployee />
            </Suspense>
          }
        />

        {/* Resources */}
        <Route
          path={routes.resources_list}
          element={
            <Suspense fallback={<SplashScreen />}>
              <ResourcesView />
            </Suspense>
          }
        />
        <Route
          path={routes.resources_create}
          element={
            <Suspense fallback={<SplashScreen />}>
              <CreateResourceView />
            </Suspense>
          }
        />
        <Route
          path={routes.resources_view}
          element={
            <Suspense fallback={<SplashScreen />}>
              <PreviewResourceView />
            </Suspense>
          }
        />
        <Route
          path={routes.resources_edit}
          element={
            <Suspense fallback={<SplashScreen />}>
              <EditResourceView />
            </Suspense>
          }
        />
        {/* Home */}
        <Route
          path={routes.home}
          element={
            <Suspense fallback={<SplashScreen />}>
              {user.profile_type === "organization" ? (
                <OrganizationAdminView />
              ) : (
                <HomeView />
              )}
            </Suspense>
          }
        />
        {/* change Password */}
        <Route
          path={routes.change_password}
          element={
            <Suspense fallback={<SplashScreen />}>
              <ChangePasswordView />
            </Suspense>
          }
        />
        {/* Admin */}
        <Route
          path={routes.pricing}
          element={
            <Suspense fallback={<SplashScreen />}>
              <PricingView />
            </Suspense>
          }
        />
        <Route
          path={routes.firmware_list}
          element={
            <Suspense fallback={<SplashScreen />}>
              <FirmwareView />
            </Suspense>
          }
        />
        {/* <Route
          path={routes.add_floor_plan}
          element={
            <Suspense fallback={<SplashScreen />}>
              <AddFloorplanView />
            </Suspense>
          }
        /> */}
        {/* Page Not Found */}
        <Route path="*" element={<Navigate to={defaultRoute(user)} />} />
      </Route>
    </Routes>
  );
};

export { PrivateRoutes };
