import React from "react";
import user from "../../../interfaces/user.interface";
import routes from "../../../routes/routes";
import { useDispatch, useSelector } from "../../../store";
import Avatar from "../../../components/Avatar/Avatar";
import placeholder from "../../../assets/images/media/avatars/logo_placeholder.png";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { setSelectedSite } from "../../../store/actions/selected-site";
import { site } from "../../../views/OrganizationAdmin/Sites/interfaces/site.interface";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/system";

const categoryOptions = [  
  {
    routes: [
      routes.report_cards,
      routes.report_events,
      routes.report_users,
    ],
    view: "report",
    label: "Reports",
  },
  {
    routes: [
      routes.controller_list,
      routes.output_list,
      routes.input_list,
      routes.firmware_manager,
    ],
    view: "hardware",
    label: "HARDWARE",
  },
  {
    routes: [
      routes.holiday_list,
      routes.schedule_list,
      routes.access_level,
      routes.access_level_manager,
      routes.global_settings,
      routes.floor_plan,
      routes.interlocks_list,
      routes.lockdown,
    ],
    view: "setting",
    label: "SETTINGS",
  },
];

const navigationOptions = [
  // { routeName: "ACCOUNT_INFO", url: "/organization_info" },
  { routeName: "SITE_OPTION", url: "/site/list" },
  // { routeName: "SITE_INFO", url: "/site/dashboard" },
  { routeName: "DOOR_OPTION", url: "/door" },
  { routeName: "USERORG_OPTION", url: "/user_org" },
  { routeName: "CARD_OPTION", url: "/card/list" },
  { routeName: "ORGUSERS_REPORT", url: "/report/users" },
  { routeName: "ORGCARDS_REPORT", url: "/report/cards" },
  { routeName: "Events", url: "/report/events" },
  { routeName: "HOLIDAY_OPTION", url: "/holiday" },
  { routeName: "SCHEDULE_OPTION", url: "/schedule" },
  { routeName: "ACCESSLEVEL_OPTION", url: "/access_level" },
  { routeName: "GLOBALSETTINGS_OPTION", url: "/global_settings" },
  { routeName: "LOCKDOWN_OPTION", url: "/lockdown" },
  { routeName: "FLOOR_PLAN_OPTION", url: "/floor_plan" },
  { routeName: "INTERLOCK_OPTION", url: "/interlock" },
  { routeName: "CONTROLLER_OPTION", url: "/controller" },
  { routeName: "Input", url: "/input" },
  { routeName: "Output", url: "/output" },
  { routeName: "Firmware Manager", url: "/firmware_manager" },
  { routeName: "EVENT_OPTION", url: "/event/list" },
  { routeName: "NOTIFICATIONS", url: "/site/notifications" }
];

const SelectionNav = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const { pathname, search } = useLocation();
  const user: user = useSelector((store) => store.user.user);
  const selectedSite: site = useSelector((store) => store.selectedSite.site);
  const isEmpty = (obj: object | undefined) => obj && typeof obj === 'object' && Object.keys(obj).length === 0;

  const isFloorPlan = () =>
    pathname.includes(routes.floor_plan_view.split(":")[0]);

  const arrowElement = () => (
    <Box alignSelf="center">
      <ArrowForwardIosIcon fontSize="small" sx={{ mb: -1, color: theme.palette.text.primary }} />
    </Box>
  );

  const buttonElement = (route: string, text: string, bold:boolean = false ) => (
    <Box mt={0.8}>
      <IconButton disabled={isFloorPlan()} onClick={() => !!route && navigate(route)}>
        <Typography fontWeight={bold ? "bold" : ""}>{text}</Typography>
      </IconButton>
    </Box>
  );

  const goTo = () => {
    switch (user.profile_type) {
      case "organization":
        return user.selectedOrg?.multi_site && selectedSite?.site
          ? routes.site_dashboard
          : routes.home;

      default:
        return user.selectedOrg?.multi_site && selectedSite?.site
          ? routes.site_dashboard
          : routes.organization_info;
    }
  };

  return (
    <Stack direction={"row"} gap={1} alignItems="center">
      {user.selectedDealer && (
        <>
          {!isFloorPlan() && (
          <Avatar
            className="transparent-avatar"
            variant="rounded"
            sx={{ width: 40, height: 40, mt: 0.5 }}
            src={String(user.selectedDealer?.image)}
          >
            <img src={placeholder} />
          </Avatar>
          )}
          {buttonElement(routes.home, user.selectedDealer?.name)}
        </>
      )}
      {user.selectedOrg && (
        <>
          {user.selectedDealer && arrowElement()}
          {buttonElement(routes.organization_info, user.selectedOrg.name)}
        </>
      )}
      {user.selectedOrg?.multi_site && (
        <>
          {user.selectedOrg && selectedSite?.site && arrowElement()}
          {selectedSite?.site &&
            buttonElement(routes.site_dashboard, selectedSite.name)}
        </>
      )}
      {categoryOptions.map(({ routes, view, label }) => (
        (routes.some(route => pathname.includes(route)) || search === `?view=${view}`) && (
          <>
            {arrowElement()}
            {buttonElement(`${goTo()}?view=${isEmpty(selectedSite) && view != "report" ? "" : view}`, t(label), search === `?view=${view}` ? true : false)}
          </>
        )
      ))}
      {navigationOptions.map(({ routeName, url }) => (
        pathname.includes(url) && (
          <>
            {arrowElement()}
            {buttonElement("", t(`${routeName}`), true)}
          </>
        )
      ))}
      {/* {(reports.includes(pathname) || search === "?view=report") && (
        <>
          {arrowElement()}
          {buttonElement(`${goTo()}?view=report`, t("Reports"), true)}
        </>
      )}
      {(hardware.includes(pathname) || search === "?view=hardware") && (
        <>
          {arrowElement()}
          {buttonElement(`${goTo()}?view=hardware`, t("HARDWARE"), true)}
        </>
      )}
      {(settings.includes(pathname) || search === "?view=setting") && (
        <>
          {arrowElement()}
          {buttonElement(`${goTo()}?view=setting`, t("SETTINGS"), true)}
        </>
      )} */}
    </Stack>
  );
};

export default SelectionNav;
