// MUI
import {
  Box,
  Select as MaterialUISelect,
  MenuItem,
  Typography,
} from "@mui/material";
import InfoIconLabel from "../InfoIconLabel/InfoIconLabel";
// Components
import RequiredFieldIndicator from "../RequiredFieldIndicator/RequiredFieldIndicator";
import { useTheme } from "@mui/system";
import { idFormat } from "../../utils/idFormat";

interface SelectProps {
  name?: string;
  placeholder?: string;
  value: string | number | boolean | any;
  onChange: any;
  options: Array<any>;
  optionValue?: string | any;
  optionName: string;
  disabled?: boolean;
  label?: string;
  required?: boolean;
  info?: string;
  marginBottom?: string;
  height?: string;
  padding?: string;
  paddingRight?: string;
  paddingLeft?: string;
  sx?: any;
  keepLabelEnabled?: boolean;
  lckdwnActivated?: boolean;
  id?:string;
  setShowCustomModal?: (open: boolean) => void;
  customValue?:string | null;
}

const Select = ({
  name,
  placeholder,
  value = "",
  onChange,
  options,
  optionValue,
  optionName,
  disabled,
  label,
  required,
  info,
  marginBottom,
  height,
  padding,
  paddingRight,
  paddingLeft,
  sx,
  keepLabelEnabled,
  lckdwnActivated,
  id,
  customValue,
  setShowCustomModal,
}: SelectProps) => {
  const theme = useTheme();

  return (
    <Box
      width="100%"
      sx={{ flex: 1, borderRadius: 0 }}
    >
      {label && (
        <Box minHeight={20} marginTop={0.5}>
          <Typography
            fontWeight="bold"
            sx={{
              marginLeft: "0.25em",
              color: disabled && !keepLabelEnabled ? "lightgray" : null,
              fontSize: "0.75em",
            }}
          >
            {label}
            {required && <RequiredFieldIndicator disabled={disabled} />}
            {info && <InfoIconLabel info={info} />}
          </Typography>
        </Box>
      )}
      <MaterialUISelect
        name={name}
        variant="outlined"
        disabled={disabled}
        value={value}
        id={id ? id : `select-${label ? idFormat(label) : "default"}`}
        sx={{
          borderRadius: "0em",
          width: "100%",
          maxHeight: "2.5em",
          overflow: "hidden",
          marginBottom: marginBottom ? marginBottom : 2,
          backgroundColor: disabled ? theme.palette.input.disabled : theme.palette.input.background,
          '& .MuiSvgIcon-root': {
            color: theme.palette.button.icon,
          },      
          ".MuiOutlinedInput-notchedOutline": {
            borderWidth: "0px",
            // backgroundColor: disabled ? "#00000005" : "#00000008",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderWidth: "0px",
            backgroundColor: disabled ? "#00000005" : "#00000008",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderWidth: "0px",
            backgroundColor: disabled ? "#00000005" : "#00000008",
          },
          ".css-5c13vx-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select":
            {
              padding: { padding },
              paddingLeft: { paddingLeft },
              paddingRight: { paddingRight },
            },
          ...sx,
        }}
        className={lckdwnActivated ? "blinkcard-yellow" : ""}
        onChange={onChange}
        displayEmpty
        MenuProps={{
          style: { borderRadius: "0em" },
          PaperProps: { sx: { maxHeight: 220 } },
        }}
      >
        {placeholder && (
          <MenuItem
            sx={{ borderRadius: "0em", margin: "0.25em" }}
            disabled
            autoFocus
            value=""
          >
            <span style={{ color: "gray" }}>{placeholder}</span>
          </MenuItem>
        )}
        {options.map((option: any, index) => {
          return (
            <MenuItem
              id={`select-option-${index}`}
              key={index}
              sx={{
                borderRadius: "0em",
                margin: "0.25em",
                minHeight: "2em !important",
              }}
              value={optionValue ? option[optionValue] : option}
            >
              {optionName ? option[optionName] : option}
            </MenuItem>
          );
        })}
        {setShowCustomModal && customValue && (
          <MenuItem
            onClick={() => setShowCustomModal(true)}
            value={customValue}>
              {customValue == "Custom" ? "Custom" : `Custom - ${customValue}`}
          </MenuItem>
        )}
      </MaterialUISelect>
    </Box>
  );
};

export default Select;
