import React, { ReactNode } from "react";
import FormRowStyled from "./FormRow.styled";

interface props {
  children: ReactNode;
  gap?: string;
}

const FormRow = ({ children, gap }: props) => {
  return <FormRowStyled gap={gap}>{children}</FormRowStyled>;
};

export default FormRow;
