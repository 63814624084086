import React, { useEffect, useState } from "react";
import { site } from "../../../views/OrganizationAdmin/Sites/interfaces/site.interface";
import { useSelector } from "../../../store";
import { Box, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import {DateTime} from 'luxon';

const Clock = () => {
  const [timezoneTimer, setTimezoneTimer] = useState(DateTime.local());

  const selectedSite: site = useSelector((store) => store.selectedSite.site);

  useEffect(() => {
    if (Object.keys(selectedSite ?? {}).length > 0){
    setTimezoneTimer(DateTime.local().setZone(selectedSite.timezone));

    const intervalId = setInterval(() => {
      // Update the time by adding 1 second
      setTimezoneTimer((prevTime:any) => prevTime.plus({ seconds: 1 }));
    }, 1000);
    
    return () => clearInterval(intervalId);
  }
  }, [selectedSite]);

  const formattedTime = timezoneTimer.toFormat('h:mm:ss a');
  const formattedDate = timezoneTimer.toFormat('MM/dd/yyyy'); 

  return (
    <Box sx={{ display: "flex", alignItems: "center", padding: 0 }}>
      {selectedSite?.timezone && (
      <Typography variant="subtitle2" textAlign="right">
        <span style={{ fontSize: "small" }}>
          {formattedDate}
        </span>
        <br />
          <span style={{ fontSize: "small" }}>{formattedTime}</span>
      </Typography>
      )}
    </Box>
  );
};

export default Clock;
