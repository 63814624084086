import { ReactNode, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
// MUI
import { Box, Paper, Stack, Typography } from "@mui/material";
// Components
import Button from "../../components/Button/Button";
import FormLabel from "../../components/FormLabel/FormLabel";
import FormField from "../../components/FormField/FormField";
import Select from "../../components/Select/Select";
import Snack from "../../components/Snack/Snack";
import placeholder from "./../../assets/images/media/avatars/logo_placeholder.png";
// API
import {
  discoveredOption,
  getCountries,
  getEmailByCode,
  getLanguages,
  getRegion,
  getStates,
  postDealerRequest,
  validateZipcode,
} from "../../api/api";
// Utils
import validateEmail from "../../utils/email-validation";
import Avatar from "../../components/Avatar/Avatar";
// Assets
import logo from "../../assets/images/media/logos/CDVI_CLOUD4.png";
// import { imageUpload } from "../../../utils/imgBase64";
import { useNavigate, useParams } from "react-router-dom";
import DealerRequestReceived from "./DealerRequestReceived";
import { blue } from "@mui/material/colors";

const AddDealerRequest = () => {
  const viewMode = window.location.pathname.includes("view");
  const { t } = useTranslation();
  const { _dealer } = useParams();
  const navigate = useNavigate();

  const [confirmation, setConfirmation] = useState(false);
  const [validZipcode, setValidZipcode] = useState<any>(true);
  const [loading, setLoading] = useState(false);
  const [disabledSave, setDisabledSave] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [dealer, setDealer] = useState({
    logo: "",
    company: "",
    firstName: "",
    lastName: "",
    email: "",
    website: "",
    region: "",
    country: "",
    city: "",
    state: "",
    state_id: 0,
    zipcode: "",
    address: "",
    phone: "",
    mobile: "",
    fax: "",
    language: "EN",
    ein: null,
    discovered_us_by: null,
    discovered_by_description: "",
  });
  const [regionOptions, setRegionOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [discoveredOptions, setDiscoveredOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);

  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  // useEffect for fetching regions and countries
  useEffect(() => {
    fetchCountries();
    fetchRegions();
    fetchLanguages();
    fetchDiscoveredOption();
    fetchEmailByCode();
  }, []);

  // useEffect for enabling/disabling save button
  useEffect(() => {
    if (
      !dealer.company ||
      !dealer.firstName ||
      !dealer.lastName ||
      !dealer.country ||
      !dealer.region ||
      !dealer.city ||
      !dealer.state_id ||
      !dealer.zipcode ||
      !validZipcode ||
      !dealer.address ||
      !dealer.phone ||
      !dealer.language ||
      (dealer.email && !validEmail)
    ) {
      setDisabledSave(true);
    } else {
      setDisabledSave(false);
    }
  }, [dealer, validEmail, validZipcode]);

  // useEffect for validating email
  useEffect(() => {
    if (dealer.email) {
      setValidEmail(validateEmail(dealer.email));
    } else {
      setValidEmail(true);
    }
  }, [dealer.email]);

  // useEffect for validating zipcode
  useEffect(() => {
    if (dealer.zipcode && dealer.country && dealer.zipcode.length > 3) {
      const fetchZipcode = async () => {
        const valid = await handleZipcode();
        setValidZipcode(valid);
      };
      try {
        fetchZipcode();
      } catch (error) {
        setValidZipcode(false);
      }
    } else {
      if (dealer.zipcode.length == 0) setValidZipcode(true);
      else setValidZipcode(false);
    }
  }, [dealer.zipcode, dealer.country, dealer.state_id]);

  const fetchCountries = async () => {
    try {
      const response = await getCountries();
      if (response.status === 200) {
        setCountryOptions(response.data || []);
      }
    } catch (error: any) {
      setAlert({
        open: true,
        message: error.response.data?.message || error.message,
        severity: "error",
      });
    }
  };

  const fetchRegions = async () => {
    try {
      const { status, regions } = await getRegion();
      status === 200 && setRegionOptions(regions || []);
      setDealer((d) => {
        return {
          ...d,
          region: regions[0].region,
        };
      });
    } catch (error: any) {
      setAlert({
        open: true,
        message: error.response.data?.message || error.message,
        severity: "error",
      });
    }
  };

  const fetchLanguages = async () => {
    try {
      const { status, languages } = await getLanguages();
      status === 200 && setLanguageOptions(languages || []);
    } catch (error: any) {
      setAlert({
        open: true,
        message: error.response.data?.message || error.message,
        severity: "error",
      });
    }
  };

  const fetchDiscoveredOption = async () => {
    try {
      const { status, options } = await discoveredOption();
      options.unshift({
        option_discovered: null,
        description: "",
      });
      status === 200 && setDiscoveredOptions(options || []);
    } catch (error: any) {
      setAlert({
        open: true,
        message: error.response.data?.message || error.message,
        severity: "error",
      });
    }
  };

  const fetchStates = async (country: string) => {
    try {
      const { status, states } = await getStates(country);
      status === 200 && setStateOptions(states || []);
    } catch (error: any) {
      setAlert({
        open: true,
        message: error.response.data?.message || error.message,
        severity: "error",
      });
    }
  };

  const fetchEmailByCode = async () => {
    try {
      // Get query `code`
      const code = new URLSearchParams(window.location.search).get("code");
      if (!code) {
        throw new Error("No email code provided");
      }

      const { email } = await getEmailByCode(code);
      setDealer((d) => {
        return { ...d, email };
      });
    } catch (error: any) {
      setAlert({
        open: true,
        message: error.response?.data?.message || error.message,
        severity: "error",
      });

      await new Promise((resolve) => setTimeout(resolve, 3000));

      navigate("/login");
    }
  };

  // Submit/Save handler
  const handleSubmit = async () => {
    try {
      let currentState: any = stateOptions.find(
        (ele: any) => ele.id == dealer.state_id
      );
      const payload = { ...dealer, state: currentState.name };
      if (!payload.ein) {
        payload.ein = null;
      }
      setLoading(true);
      await postDealerRequest(payload);
      setLoading(false);
      setConfirmation(true);
    } catch (error: any) {
      setLoading(false);
      setAlert({
        open: true,
        message: error.response.data?.message || error.message,
        severity: "error",
      });
    }
  };

  //J6J3S9
  // Validate Zipcode
  const handleZipcode = async () => {
    try {
      const { status, valid } = await validateZipcode(
        dealer.zipcode,
        dealer.country,
        dealer.state_id
      );
      status === 200 && setValidZipcode(valid);
      return valid;
    } catch (error: any) {
      setValidZipcode(false);
      // setAlert({
      //   open: true,
      //   message: error.response.data?.message || error.message,
      //   severity: "error",
      // });
      return false;
    }
  };

  // Modal bottom actions
  const AddDealerModalActions = () => {
    return (
      <FormRow>
        <FormLabel sx={{ flex: { sm: "0.2" } }}></FormLabel>
        {/* <Button
          disabled={loading}
          color="primary"
          onClick={() => navigate("/login")}
        >
          {t("dealers.cancel")}
        </Button> */}
        <Box flex={1}>
          <Button
            bigBtn
            width="100%"
            loading={loading}
            color="darkBlue"
            disabled={loading || disabledSave || dealer.email === ""}
            onClick={handleSubmit}
          >
            {t("dealers.submit")}
          </Button>
        </Box>
      </FormRow>
    );
  };

  const uploadFile = () => {
    // imageUpload(setSrc);
  };

  return (
    <>
      {confirmation ? (
        <DealerRequestReceived company={dealer.company} />
      ) : (
        <Paper sx={{ padding: viewMode ? 0 : 4 }}>
          <Stack direction="row" justifyContent="center">
            <img
              src={logo}
              width={200}
              alt="CDVI Logo"
              style={{ marginLeft: -80 }}
            />
          </Stack>
          {/* Login title */}
          <Typography
            component="h3"
            variant="h3"
            fontWeight={1000}
            fontSize={32}
            color={blue[900]}
            textAlign="center"
            mb={2}
          >
            {t("dealers.dealer_registration")}
          </Typography>
          <FormRow>
            <FormLabel sx={{ flex: { sm: "0.2" } }}>{"Logo"}</FormLabel>
            <Box width="100%" marginBottom={1} sx={{ flex: 1 }}>
              <Avatar
                editIcon={!viewMode}
                deleteIcon={dealer.logo ? true : false}
                variant="rounded"
                src={dealer?.logo || placeholder}
                setSrc={(src: string) =>
                  setDealer({
                    ...dealer,
                    logo: src,
                  })
                }
                onDeleteClick={() => setDealer({ ...dealer, logo: "" })}
              ></Avatar>
            </Box>
          </FormRow>
          {/* Company */}
          <FormRow>
            <FormLabel required sx={{ flex: { sm: "0.2" } }}>
              {t("dealers.form.company")}
            </FormLabel>
            <FormField
              sx={{ backgroundColor: "#F2F2F2" }}
              maxLength={250}
              value={dealer.company}
              onChange={(event: any) =>
                setDealer({ ...dealer, company: event.target.value })
              }
            />
          </FormRow>
          {/* FEI */}
          {/* <FormRow>
            <FormLabel sx={{ flex: { sm: "0.2" } }}>FEI/EIN</FormLabel>
            <FormField
              maxLength={50}
              value={dealer.ein || ""}
              onChange={(event: any) =>
                setDealer({ ...dealer, ein: event.target.value })
              }
            />
          </FormRow> */}
          {/* First name */}
          <FormRow>
            <FormLabel required sx={{ flex: { sm: "0.2" } }}>
              {t("dealers.form.first_name")}
            </FormLabel>
            <FormField
              sx={{ backgroundColor: "#F2F2F2" }}
              value={dealer.firstName}
              onChange={(event: any) =>
                setDealer({ ...dealer, firstName: event.target.value })
              }
            />
          </FormRow>
          {/* Last name */}
          <FormRow>
            <FormLabel required sx={{ flex: { sm: "0.2" } }}>
              {t("dealers.form.last_name")}
            </FormLabel>
            <FormField
              sx={{ backgroundColor: "#F2F2F2" }}
              value={dealer.lastName}
              onChange={(event: any) =>
                setDealer({ ...dealer, lastName: event.target.value })
              }
            />
          </FormRow>
          {/* Email */}
          <FormRow>
            <FormLabel required sx={{ flex: { sm: "0.2" } }}>
              {t("dealers.form.email")}
            </FormLabel>
            <FormField
              sx={{ backgroundColor: "#F2F2F2" }}
              maxLength={100}
              error={!validEmail}
              errorText="Invalid email"
              // disabled
              value={dealer.email}
            />
          </FormRow>
          {/* Website */}
          <FormRow>
            <FormLabel sx={{ flex: { sm: "0.2" } }}>{"Website"}</FormLabel>
            <FormField
              sx={{ backgroundColor: "#F2F2F2" }}
              maxLength={250}
              value={dealer.website}
              onChange={(event: any) =>
                setDealer({ ...dealer, website: event.target.value })
              }
            />
          </FormRow>
          {/* Region */}
          {/* <FormRow>
            <FormLabel required sx={{ flex: { sm: "0.2" }, marginBottom: 2 }}>
              Region
            </FormLabel>
            <Select
              value={dealer.region}
              onChange={(event: any) =>
                setDealer({ ...dealer, region: event.target.value })
              }
              options={regionOptions}
              optionName="name"
              optionValue="region"
              marginBottom="2em"
            />
          </FormRow> */}
          {/* Country */}
          <FormRow>
            <FormLabel required sx={{ flex: { sm: "0.2" }, marginBottom: 2 }}>
              {t("dealers.form.country")}
            </FormLabel>
            <Select
              value={dealer.country}
              onChange={(event: any) => {
                setDealer({ ...dealer, country: event.target.value });
                fetchStates(event.target.value);
              }}
              options={countryOptions}
              optionName="name"
              optionValue="country"
              marginBottom="2em"
            />
          </FormRow>
          {/* Address */}
          <FormRow>
            <FormLabel required sx={{ flex: { sm: "0.2" } }}>
              {t("dealers.form.address")}
            </FormLabel>
            <FormField
              sx={{ backgroundColor: "#F2F2F2" }}
              maxLength={250}
              value={dealer.address}
              onChange={(event: any) =>
                setDealer({ ...dealer, address: event.target.value })
              }
            />
          </FormRow>
          {/* City / State / Zipcode */}
          <FormRow>
            <FormLabel sx={{ flex: { sm: "0.64" } }}></FormLabel>
            <FormField
              sx={{ backgroundColor: "#F2F2F2" }}
              required
              label={t("dealers.form.city")}
              value={dealer.city}
              onChange={(event: any) =>
                setDealer({ ...dealer, city: event.target.value })
              }
            />
            <Box
              sx={{ width: "25%", display: "flex", flexDirection: "column" }}
            >
              <FormLabel required sx={{ flex: { sm: "0.2" }, marginY: 0.3 }}>
                {Number(dealer?.country) === 2
                  ? t("dealers.form.province_territory")
                  : t("dealers.form.state")}
              </FormLabel>
              <Select
                value={dealer.state_id}
                onChange={(event: any) =>
                  setDealer({ ...dealer, state_id: event.target.value })
                }
                options={stateOptions}
                optionName="name"
                optionValue="id"
              />
            </Box>
            {/* <Box sx={{width: '25%', marginBottom:2, display:'flex', flexDirection: 'column'}}>
            <FormLabel required sx={{ flex: { sm: "0.2" }, marginY: 0.3 }}>
              {t("dealers.form.state")}
            </FormLabel>
            <MaterialUISelect
              value={dealer.state}
              variant="outlined"
              sx={{
                borderRadius: "0.5em",
                width: "100%",
                maxHeight: "2.5em",
                overflow: "hidden",
                backgroundColor: "#00000005",
                ".MuiOutlinedInput-notchedOutline": {
                  borderWidth: "0px",
                  backgroundColor: "#00000005",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderWidth: "0px",
                  backgroundColor: "#00000005",
                },
              }}
              MenuProps={{
                style: { borderRadius: "0.5em"},
                PaperProps: { sx: { maxHeight: 220 }}
              }}
              onChange={(event: any) => {
                setDealer({ ...dealer, state: event.target.value})
              }}
            >         
             {stateOptions.map((state: any) => (
                  <MenuItem
                  sx={{ borderRadius: "0.5em", margin: "0.25em" }}
                  key={state.id}
                  value={state}
                >
                  {state.name}
                </MenuItem>
              ))}
            </MaterialUISelect>
            </Box> */}
            <FormField
              sx={{ backgroundColor: "#F2F2F2" }}
              error={!validZipcode}
              required
              label={
                Number(dealer?.country) === 2
                  ? t("dealers.form.postal_code")
                  : t("dealers.form.zip_code")
              }
              errorText={t("dealers.error.invalid_zip")}
              maxLength={10}
              value={dealer.zipcode}
              onChange={(event: any) => {
                setDealer({ ...dealer, zipcode: event.target.value });
              }}
            />
          </FormRow>
          {/* Phone / Mobile / Fax */}
          <FormRow>
            <FormLabel sx={{ flex: { sm: "0.64" } }}>Phone</FormLabel>
            <FormField
              sx={{ backgroundColor: "#F2F2F2" }}
              required
              maxLength={20}
              label={t("dealers.form.phone")}
              value={dealer.phone}
              onChange={(event: any) =>
                setDealer({ ...dealer, phone: event.target.value })
              }
            />
            <FormField
              sx={{ backgroundColor: "#F2F2F2" }}
              maxLength={20}
              label={t("dealers.form.mobile")}
              value={dealer.mobile}
              onChange={(event: any) =>
                setDealer({ ...dealer, mobile: event.target.value })
              }
            />
            <FormField
              sx={{ backgroundColor: "#F2F2F2" }}
              maxLength={20}
              label="Fax"
              value={dealer.fax}
              onChange={(event: any) =>
                setDealer({ ...dealer, fax: event.target.value })
              }
            />
          </FormRow>
          {/* Language */}
          <FormRow>
            <FormLabel required sx={{ flex: { sm: "0.2" }, marginBottom: 2 }}>
              {t("dealers.form.language")}
            </FormLabel>
            <Select
              value={dealer.language}
              onChange={(event: any) =>
                setDealer({ ...dealer, language: event.target.value })
              }
              options={languageOptions}
              optionName="name"
              optionValue="language"
              marginBottom="2em"
            />
          </FormRow>
          {/* Discovered */}
          <FormRow>
            <FormLabel sx={{ flex: { sm: "0.2" }, marginBottom: 2 }}>
              {t("dealers.how_you_discover")}
            </FormLabel>
            <Select
              value={dealer.discovered_us_by}
              onChange={(event: any) => {
                const value = event.target.value;
                const obj: any = discoveredOptions?.find(
                  (e: any) => e.option_discovered === value
                );
                setDealer({
                  ...dealer,
                  discovered_us_by: value,
                  discovered_by_description:
                    value === 7 ? "" : obj?.description || "",
                });
              }}
              options={discoveredOptions}
              optionName="description"
              optionValue="option_discovered"
              marginBottom="2em"
            />
          </FormRow>
          {/* Other */}
          {dealer.discovered_us_by === 7 && (
            <FormRow>
              <FormLabel sx={{ flex: { sm: "0.2" }, marginBottom: 2 }}>
                {t("general.comments")}
              </FormLabel>
              <FormField
                sx={{ backgroundColor: "#F2F2F2" }}
                maxLength={20}
                value={dealer.discovered_by_description}
                onChange={(event: any) =>
                  setDealer({
                    ...dealer,
                    discovered_by_description: event.target.value,
                  })
                }
              />
            </FormRow>
          )}
          {AddDealerModalActions()}
        </Paper>
      )}
      {/* </Modal> */}
      <Snack
        open={alert.open}
        onClose={() => setAlert({ ...alert, open: false })}
        message={alert.message}
        severity={alert.severity}
      />
    </>
  );
};

// FormRow support component
const FormRow = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: { xs: "column", sm: "row" },
        alignItems: "center",
        justifyContent: "center",
        gap: "1em",
        width: "100%",
      }}
    >
      {children}
    </Box>
  );
};

export default AddDealerRequest;
