import routes from '../routes/routes';
import permissions from './permissions';
import user from '../interfaces/user.interface';

const actions = [1, 2, 3, 4, 5, 6, 7, 8];

const getPermissions = () => {
  const url: string = window.location.pathname;
  if (url.includes('roles/view')) {
    return permissions.user;
  } else {
    switch (url) {
      case routes.user_list:
        return permissions.user;

      case routes.dealer_list:
        return permissions.dealer;

      case routes.menu_list:
        return permissions.menu;

      case routes.permission_list:
        return permissions.permission;
    }
  }
};

const setActionTable = (user: user, action: string): boolean => {
  const perm = user.permissions.find(
    (ele) => ele.name === getPermissions()?.name
  ) as any;

  if (perm) {
    for (const i of actions) {
      if (perm[`action${i}`].tag === action) {
        return true;
      }
    }
  }

  return false;
};

export default setActionTable;
