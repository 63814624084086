import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

// Material UI
import { Box, IconButton, styled, Theme, Toolbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { CSSObject } from "@mui/material/styles";
import { useTheme } from "@mui/system";
// import MenuIcon from "@mui/icons-material/Menu";
// import { grey } from "@mui/material/colors";

// Project imports
import NavigationBar from "../NavigationBar/NavigationBar";
import cdviIcon from "../../assets/images/media/logos/CDVI_icono.png";
import cdviLogo from "../../assets/images/media/logos/CDVI_CLOUD_blanco.png";
import NavigationMenu from "../NavigationMenu/NavigationMenu";
import NavigationMenuMini from "../NavigationMenuMini/NavigationMenuMini";
import NavbarButton from "../../components/NavbarButton/NavbarButton";

// Store
import { useSelector } from "../../store";
import { getSecurityRoleMenu } from "../../api/api";
import user from "../../interfaces/user.interface";
import routes from "../../routes/routes";
import { site } from "../../views/OrganizationAdmin/Sites/interfaces/site.interface";

const drawerWidth = 270;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 10px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  background: "transparent",
  height: "64px",
  width: `calc(100% - ${96}px)`,
  marginRight: "8px",
  // marginLeft: "10px",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth + 20}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  paper: {
    // background: "#1e1e2d",
  },
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

let allMenu: any[] = [];

const initMenuIds = [
  "07bb22da-6487-11ee-8d30-d70ef3171ee2",
  "df071e8a-6788-11ed-b2ae-239e54be31e6",
  "84d0eb18-937e-11ed-b662-7b96c0b27e92",
  "7a55ebdc-90f6-11ed-b64b-dbd5cc76cefc",
  "562c41e2-095b-11ee-9aad-0f322f2ecac6",
];

const sitesIds = [
  "df071e8a-6788-11ed-b2ae-239e54be31e6",
  "35b582c6-9069-11ee-a5a9-8f8c8585330e",
];

const globalSettingsId = "c164265a-4f30-585b-a0ba-bb64f2514d57";
const settingsId = "874c34ec-92f1-11ee-a5b3-57a427112e7d";

const restrictedOrgRoutes = [
  routes.controller_list,
  routes.output_list,
  routes.input_list,
  routes.door_list,
  routes.holiday_list,
  routes.schedule_list,
  routes.access_level,
  routes.access_level_manager,
  routes.lockdown,
  routes.event_list,
  routes.interlocks_list,
];

// const secure;s

const MasterLayout = () => {
  const user: user = useSelector((store) => store.user.user);
  const navigate = useNavigate();
  const selectedSite: site = useSelector((store) => store.selectedSite.site);
  const location = useLocation();
  const theme = useTheme();
  const { pathname } = location;

  const [open, setOpen] = useState(true);
  const [menu, setMenu] = useState<any[]>([]);

  var mobileWidth = window.matchMedia("(max-width: 700px)");
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));

  mobileWidth.addEventListener("change", () => {
    handleMobile();
  });

  useEffect(() => {
    handleMobile();
  }, []);

  useEffect(() => {
    getMenuByRole();
  }, [user.role]);

  useEffect(() => {
    handleSetMenu();
    if (!selectedSite?.site && restrictedOrgRoutes.includes(pathname)) {
      navigate(routes.organization_info);
    }
  }, [user, selectedSite]);

  const handleMobile = () => {
    if (mobileWidth.matches) {
      setOpen(false);
    } else {
      setOpen(false);
    }
  };

  const handleDrawer = () => {
    setOpen(!open);
  };

  const getMenuByRole = async () => {
    const { status, menus } = await getSecurityRoleMenu(user.role);
    allMenu = [...menus];
    handleSetMenu();
  };

  const handleSetMenu = () => {
    let newMenu: any[] = [...allMenu];
    if (user.profile_type === "dealer" && !user.selectedOrg) {
      newMenu = allMenu
        .filter((m: any) => m.id !== "60d72a50-6788-11ed-b2ae-7bb4c153a654")
        .filter((m: any) =>
          pathname === routes.home
            ? m.id !== "a6e64690-1830-11ed-98fc-57de738641b7"
            : m
        );
    }

    if (newMenu.find((m) => m.id === "60d72a50-6788-11ed-b2ae-7bb4c153a654")) {
      newMenu = newMenu.map((m) => {
        return m.id !== "60d72a50-6788-11ed-b2ae-7bb4c153a654"
          ? m
          : user.selectedOrg?.multi_site
          ? selectedSite?.site
            ? { ...m }
            : (() => {
                let multisiteChildren: any = [];
                let settings: any = null;

                m.children.forEach((c: any) => {
                  if (c.id === settingsId) {
                    settings = c;
                  } else {
                    (initMenuIds.includes(c.id) ? multisiteChildren : []).push(
                      c
                    );
                  }
                });

                if (settings) {
                  const globalSettings = settings.children.find(
                    (c: any) => c.id === globalSettingsId
                  );

                  if (globalSettings) {
                    multisiteChildren.push(globalSettings);
                  }
                }

                return {
                  ...m,
                  children: multisiteChildren,
                };
              })()
          : {
              ...m,
              children: m.children.filter((c: any) => !sitesIds.includes(c.id)),
            };
      });
    }
    setMenu(newMenu);
  };

  const displayNavbar = () =>
    !pathname.includes(routes.floor_plan_view.split(":")[0]);

  return (
    <Box
      sx={{ display: "flex" }}
      style={{
        minHeight: "100vh",
        backgroundColor: theme.palette.background.default,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right top",
      }}
    >
      {displayNavbar() && (
        <NavbarButton open={open} handleDrawer={handleDrawer} />
      )}
      {/* <IconButton
        sx={{
          background: "white",
          position: "absolute",
          left: 285,
          top: 15,
          zIndex: 9000,
        }}
        onClick={handleDrawer}
      >
        {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
      </IconButton> */}
      {/* AppBar */}
      <AppBar
        position="fixed"
        open={open}
        sx={{
          boxShadow: "none",
          borderBottom: "1px solid #ffffff26",
          background: theme.palette.secondary.gradient,
          width: matches ? displayNavbar() ? `calc(100% - ${96})` : `calc(100% - ${16}px)` : "100%",
        }}
      >
        <Toolbar>
          {/* <IconButton
            aria-label="open drawer"
            onClick={handleDrawer}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon sx={{ color: grey[100] }} />
          </IconButton> */}
          <NavigationBar setMenu={setMenu} open={open} />
        </Toolbar>
      </AppBar>

      {/* Drawer */}
      {(matches || (!matches && open)) && displayNavbar() && (
        <Drawer
          sx={{
            "& .MuiPaper-root": {
              background: theme.palette.primary.gradient,
              borderRight: 0,
              // Add other custom styles as needed
              "& .selected": {
                backgroundColor: theme.palette.button.dark,
                borderRadius: 0,
                fontSize: 15,
                "& img": {
                  filter: "grayscale(1) invert(85%) brightness(1000%) !important",
                },
                "& .MuiListItemText-root": {
                  "& span": {
                    fontWeight: "bold",
                    textTransform: "uppercase",
                  },
                },
              },
              "& .MuiButtonBase-root": {
                borderRadius: 0,
              },
            },
          }}
          variant="permanent"
          open={open}
          className="mainDrawer"
        >
          <DrawerHeader sx={{ height: "72px", borderBottom: "1px dashed" }}>
            {!open ? (
              <img src={cdviIcon} height={38} />
            ) : (
              <img src={cdviLogo} height={60} />
            )}
          </DrawerHeader>

          {open ? (
            <NavigationMenu routes={menu} />
          ) : (
            <NavigationMenuMini routes={menu} />
          )}
        </Drawer>
      )}

      {/* Container */}
      <Box component="main" sx={{ flexGrow: 1 }} padding={{ xs: 0, sm: displayNavbar() ? 1.5 : 1 }}>
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  );
};

export default MasterLayout;
