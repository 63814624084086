const permissions = {
  ALL_ACCESS: "All rights",
  NO_ACCESS: "No access",
  ADD_NEW: "Add New",
  delete: "Delete",
  EXECUTE: "Execute",
  update_permission: "Update permission",
  update: "Update",
  create_permission: "Create permission",
  create: "Create",
  header: "Permission List",
  add_permission: "Add Permission",
  search_permission: "Search Permission",
  action: "Action",
  enable: "Enable",
  confirm_update_message:
    "Making any changes may break the operation of the application, Are you sure you want to procced",
  confirm_modal_cancel: "Cancel",
  confirm_modal_confirm: "Update",
  more: "More...",
  table: {
    name: "NAME",
    description: "DESCRIPTION",
    active: "ACTIVE",
  },
  form: {
    description: "Description",
    label: "Label",
    sectag: "Tag",
  },
  VIEW: "View",
  View: "View",
  ADD: "Add",
  Add: "Add",
  EDIT: "Edit",
  Edit: "Edit",
  DELETE: "Delete",
  Delete: "Delete",
  UPDATE: "Update",
  Update: "Update",
  SUBSCRIPTION: "Subscription",
  Subscription: "Subscription",
  SUBSCRIPTIONMANAGEMENT: "Subscription management",
  Subscriptionmanagement: "Subscription management",
  USERMANAGEMENT: "User management",
  Usermanagement: "User management",
  EMPLOYEEMANAGEMENT: "Employee management",
  Employeemanagement: "Employee management",
  LOCKDOWN: "Lockdown",
  Lockdown: "Lockdown",
  ACTIVATION: "Activation",
  Activation: "Activation",
  REPLACE: "Replace",
  Replace: "Replace",
  REMOVE: "Remove",
  Remove: "Remove",
  REFRESH: "Refresh",
  Refresh: "Refresh",
  RESTORE: "Restore",
  Restore: "Restore",
  RESTORETAMPER: "Restore tamper",
  Restoretamper: "Restore tamper",
  EDITBASIC: "Edit basic",
  Editbasic: "Edit basic",
  EDITADVANCED: "Edit advanced",
  Editadvanced: "Edit advanced",
  LOCK: "Lock",
  Lock: "Lock",
  ACCESS: "Access",
  Access: "Access",
  LOCKDOWNACCESS: "Lockdown access",
  Lockdownaccess: "Lockdown access",
  START: "Start",
  Start: "Start",
  STOP: "Stop",
  Stop: "Stop",
  NOTIFICATION: "Notification",
  Notification: "Notification",
  LOGIN: "Login",
  Login: "Login",
  CARD: "Card",
  Card: "Card",
  PROFILE: "Profile",
  Profile: "Profile",
  MANAGEMENT: "Management",
  Management: "Management",
  EMPLOYEE: "Employee",
  Employee: "Employee",
  Save: "Save",
  SAVE: "Save",
  Manager: "Manager",
  MANAGER: "Manager",
};

export default permissions;
