const tasks = {
    general: {
        search: 'Rechercher par UUID',
        clear: 'Effacer',
        searchBtn: 'Rechercher',
        serialNmbr: 'Numéro de série',
        any: 'Tout',
    },
    selectors: {
        selector3: {
            notAvailable: 'Non disponible',
            pending: 'En attente',
            executing: 'Exécution',
            finished: 'Terminé',
        },
        selector4: {
            any: 'Tout',
            today: 'Aujourd\'hui',
            yesterday: 'Hier',
            last7Days: 'Les 7 derniers jours',
            custom: 'Personnalisé',
        },
    },
    table: {
        siteName: 'Nom du site',
        date: 'Date',
        status: 'Statut',
        id: 'ID',
        id_detail: 'ID Détail',
        serial_number: 'Numéro de série',
        commandtype: 'Type de commande',
        entitytype: 'Type d\'entité',
        success: 'Succès',
        success_label: 'Succès',
        started_at: 'Commencé à',
        finished_at: 'Terminé à',
    },
    modal: {
        title: 'Tâche du contrôleur-Détail',
        header: 'En-tête',
        details: 'Détails',
        notAvailable: 'Non disponible',
        pending: 'En attente',
        executing: 'Exécution',
        finished: 'Terminé',
    }
}

export default tasks;