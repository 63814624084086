const general = {
  sites: "Des sites",
  site: "Site",
  door: "Porte",
  add_site: "Ajouter un site",
  edit_site: "Modifier le site",
  edit_site_access: "Modifier l'accès au site",
  search_site: "Site de recherche",
  copy_organization: "Copier le compte",
  delete_site_message: "Etes-vous sûr que vous voulez supprimer {{site}}?",
  notifications: "Notificaciones",
  events: "Evénements",
  site_info: "Informations sur le site",
  total_sites_assigned: "Total des sites attribués",
  form: {
    name: "Nom",
    address: "Adresse",
    country: "Pays",
    city: "Ville",
    state: "État",
    zip_code: "Code postal",
    timezone: "Fuseau horaire",
    contact_to: "Contacter à",
    type_email: "Tapez e-mail",
    send_notification: "Envoyer une notification",
    lockdown: "Lockdown (démarré, arrêté)",
    alarm_forced: "Porte en alarme forcée",
    alarm_tampared: "Porte en alarme sabotée",
    tamper: "Altérer",
    battery_troubles: "Problèmes de batterie",
    power_troubles: "Problèmes d'alimentation",
  },
  table: {
    name: "NOM",
    address: "ADRESSE",
    country: "PAYS",
    city: "VILLE",
    state: "ETAT",
    zipcode: "CODE POSTAL",
    timezone: "FUSEAU HORAIRE",
  },
  modal: {
    title: "Abonnement à la mise à niveau d'impression",
    remove_title: "Supprimer Subscription",
    how_many_doors: "Combien de licences de porte souhaitez-vous attribuer?",
    how_many_doors_add: "Combien de portes souhaitez-vous ajouter?",
    previous: "Précédent",
    checkout: "Vérifier",
    change_card: "Changer de certificat",
    invoice_details: "Détails de la facture",
    current: "Actuel",
    next_cycle: "Cycle Suivant",
    request_processed: "Votre demande a été traitée",
    print_receipt: "Imprimer le reçu",
    download_receipt: "Télécharger le reçu",
    finish: "Finir",
    due_today: "Dû Aujourd'hui",
    confirmation_description:
      "Le paiement ne peut pas être modifié après avoir vérifié cette fenêtre, Etes-vous sûr de vouloir procéder au paiement?",
    total_amount: "Montant total",
    remove_confirmation_description:
      "Lorsque vous cliquez sur le bouton 'confirmer', le processus d'élimination des portes ou des laissez-passer mobiles se fera immédiatement.",
    remove_description:
      "Combien de portes ou de laissez-passer mobiles souhaitez-vous supprimer?",
    remove: "Retirer",
    confirmation: "Confirmation",
    delete_payment_description:
      "Voulez-vous vraiment supprimer cette certificat",
    default_payment_description:
      "Voulez-vous vraiment définir cette certificat par défaut",
    door_added: "porte a été ajoutée",
    available_door_license: "Licences de porte disponible",
    confirm_door_activate:
      "Êtes-vous sûr de vouloir attribuer {{doors}} licences de porte?",
    not_enough_doors: "Vous n'avez pas assez de licences de porte disponibles",
    click_buy:
      "Cliquez sur Acheter pour ajouter le reste des portes à votre plan",
    buy_door: "Acheter {{doors}} licences de porte",
    buy_more_licenses: "Acheter plus de licences",
  },
};

export default general;
