import * as actions from '../actions/hardware-inputs';
import { AnyAction } from "@reduxjs/toolkit";

let initialState = {
    inputs: []
}

export const hardwareInputReducer = (state = initialState as { inputs:any | undefined }, action:AnyAction) => {
    switch (action.type) {

        // Set reades list of controller
        case actions.SET_INPUTS:
            return {
                ...state,
                inputs: [...action.payload]
            }
        
        // Update reader list"
        case actions.UPDATE_INPUT:
            const updateInputs = state.inputs.map((input:any) => {
                return input.inputlogic === action.payload.inputlogic ? action.payload : input
            });

            return {
                ...state,
                inputs: updateInputs
            }

        default: 
            return state;
    }
}