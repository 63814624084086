const reports = {
  users: {
    table: {
      users: "Utilisateur",
      login: "Connexion",
      accessLevel: "Niveau d'accès",
      departament: "Département",
      activationDate: "Date d'activation",
      lastLogin: "Dernière connexion",
      status: "Statut",
    },
    search: "Utilisateur / Connexion",
    refreshButton: "Recherche",
    resetButton: "Réinitialiser",
    exportButton: "Exporter",
    dateFilter: {
      activation: "Date d'activation",
      lastLogin: "Dernière connexion",
      notLogged: "Non connecté depuis",
    },
    statusFilter: {
      all: "Tous les états",
      active: "Actif",
      inactive: "Inactif",
    },
    allAccesslevel: "Tous les niveaux d'accès",
    allDepartments: "Tous les départements",
  },
  cards: {
    table: {
      card: "Certificat",
      format: "Format",
      username: "Nom d'utilisateur",
      activationDate: "Date d'activation",
      lastUsed: "Dernière utilisation",
      status: "Statut",
      assigned: "Assigné",
      stolen: "Volé",
      lost: "Perdu",
      lockdown: "Verrouillage",
      usedForAccess: "Utilisé pour l'accès",
    },
    cardSearch: "Certificat",
    usernameSearch: "Utilisateur",
    cardStatusRadio: "Statut de la certificat",
    cardOptionsRadio: "Options de la certificat",
    allFormats: "Tous les formats",
    cardStatusFilter: {
      all: "Tous",
      active: "Actif",
      inactive: "Inactif",
    },
    cardAssignedFilter: {
      all: "Tous",
      yes: "Oui",
      no: "Non",
    },
    dateFilter: {
      activation: "Date d'activation",
      lastUsed: "Dernière utilisation",
      notUsed: "Non utilisé en",
    },
  },
  events: {
    table: {
      date_created: "Date créée",
      controller: "Manette",
      event_class: "Classe d'événement",
      object_type: "Type d'objet",
      event_type: "Type d'événement",
      description: "Description",
      access: "Accéder",
    },
    report: "Rapport",
    all_users: "Tous les utilisateurs",
    search: "Recherche générale",
    all: "Tous",
    access: "Accès",
    security: "Sécurité",
    alarms: "Alarmes",
    troubles: "Troubles",
    lockdown: "Confinement",
    others: "Autres",
    cloud: "Données d'activité",
    remove_report_msg: "Êtes-vous sûr de vouloir supprimer le rapport?",
    save_report: "Enregistrer le rapport",
    save_report_msg: "Remplissez le nom du rapport et enregistrez-le",
    name_report: "Nom - Rapport",
    event_class: "Classe d'événement",
    event_type: "Type d'événement",
    object: "Objet",
    object_type: "Type d'objet",
    print_all_description: "Imprimer toute la description",
    date: "Date",
    from: "Depuis",
    to: "Pour",
  },
};

export default reports;
