// MUI
import { styled, experimental_sx as sx } from '@mui/system';
import { Box } from '@mui/material';

interface props {
    gap?: string;
}

const FormRowStyled = styled(Box)((props: props) => (sx({
    display: "flex",
    flex: 1,
    flexDirection: { xs: "column", sm: "row" },
    flexWrap: 'wrap',
    alignItems: "center",
    justifyContent: "start",
    gap: props.gap || "0.5em",
    width: "100%",
})));


export default FormRowStyled;