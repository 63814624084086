const inputs = {
    table: {
        hardware: 'Hardware',
        displayName: 'Display name',
        serialNumber: 'Serial number',
        connectionMethod: 'Connection method',
        normallyOpen: 'Normally open',
        responseTime: 'Input response time',
        restoreTime: 'Input restore time',
        searchPlaceHolder: 'Hardware / display name / serial number',
        enabled: 'Enabled',
        status: 'Status',
        state: 'State',
    },
    modal: {
        title: 'Input properties',
        tabInformation: 'General Information',
        tabEvents: 'Events',
    },
    form: {
        options: 'Options',
        checkEnable: 'Enable',
        radioNormallyOpen: 'Normally open',
        radioNormallyClose: 'Normally close',
        inputResponseTime: 'Input response time (ms)',
        inputRestoreTime: 'Input restore time (ms)',
        checkEnableLockdown: 'Enable Lockdown',
        whenOpen: 'When Open',
        whenClose: 'When Close',
        startLockdown: 'Start lockdown',
        stopLockdown: 'Stop lockdown'
    },
    legend: {
        normal: 'Normal',
        trouble: 'Trouble',
        active: 'Active',
        unknown: 'Unknown',
    }
};

export default inputs;
