import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';

interface CountdownProps {
    timeLeft: number;
    color?: string;
    noText?: boolean;
  }

const Countdown: React.FC<CountdownProps> = ({color, timeLeft, noText }) => {



  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <Typography sx={{fontSize: "14px"}} variant="h4" color={color}>
      {noText ? formatTime(timeLeft) : `Time Left: ${formatTime(timeLeft)}`}
    </Typography>
  );
};

export default Countdown;
